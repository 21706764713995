import React, { useCallback, useEffect, useState } from "react";

import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import { API_ENDPOINTS, PincodeFields } from "../Utils/Constants";
import PincodeTopActions from "./PincodeTopActions";
import { PincodeValidations } from "./PincodeValidations";

const PincodeCreate = (props) => {
  const dataprovider = useDataProvider();
  const notify = useNotify();
  const [stateList, setStateList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [mutate] = useMutation();
  const refresh = useRefresh();
  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      try {
        const res = await dataprovider.getList(API_ENDPOINTS.StateList, {});
        // console.log(
        //   "🚀 ~ file: PincodeCreate.js ~ line 19 ~ getStates ~ res",
        //   res
        // );
        setStateList(res.data);
      } catch (error) {
        // console.log(
        //   "🚀 ~ file: PincodeCreate.js ~ line 30 ~ getStates ~ error",
        //   error
        // );
        notify("Error In Getting States", "error");
      }

      setLoading(false);
    };
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const saveRecord = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "create",
            resource: API_ENDPOINTS.Pincodes,
            payload: { data: values },
          },
          {
            returnPromise: true,
            onSuccess: ({ data }) => {
              if (data) {
                notify(
                  `Pincode [ ${data[PincodeFields.Pincode]} ] Created`,
                  "success"
                );
                refresh();
              }
            },
            onFailure: (err) => {
              if (!err.validations) {
                notify(`Error: ${err.message ? err.message : err}`, "error");
              }
            },
          }
        );
      } catch (error) {
        if (error && error.validations) {
          return error.validations;
        } else {
          //notify(error, "error");
          return Promise.reject(error);
        }
      }
    },
    [mutate, notify, refresh]
  );
  return (
    <Create {...props} title="Create Pincode" actions={<PincodeTopActions />}>
      <SimpleForm toolbar={<SaveToolBar />} save={saveRecord} redirect={false}>
        <TextInput
          source={PincodeFields.Pincode}
          variant="outlined"
          validate={PincodeValidations.pinCode}
        />
        <SelectInput
          source={PincodeFields.StateCode}
          variant="outlined"
          choices={stateList}
          optionText={PincodeFields.State}
          optionValue={PincodeFields.StateCode}
          disabled={isLoading}
          validate={PincodeValidations.pinState}
          label="State"
        />
        <TextInput
          source={PincodeFields.City}
          variant="outlined"
          validate={PincodeValidations.pinCity}
        />
      </SimpleForm>
    </Create>
  );
};

export default PincodeCreate;
