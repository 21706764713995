import { ImageUploadField } from "../Utils/Constants";
import updateData from "./updateData";
import createData from "./createData";
import getListData from "./getListData";
import getOneData from "./getOneData";
import getManyData from "./getManyData";
import updateManyData from "./updateManyData";
import deleteData from "./deleteData";
import { addImages } from "./commonFunctions";
import { changePassword } from "./changePassword";

const dataProviderBase = {
  getList: getListData,
  getOne: getOneData,
  getMany: getManyData,
  create: createData,
  update: updateData,
  updateMany: updateManyData,
  delete: deleteData,
};

//Override Base DataProvider To Add Images And Return Data With Images To BaseData Provider
const DataProvider = {
  ...dataProviderBase,
  create: async (resource, params) => {
    if (!params.data[ImageUploadField])
      return dataProviderBase.create(resource, params);

    const images = await addImages(params.data[ImageUploadField]);

    return dataProviderBase.create(resource, {
      ...params,
      data: {
        ...params.data,
        [ImageUploadField]: [...images],
      },
    });
  },
  update: async (resource, params) => {
    if (!params.data[ImageUploadField])
      return dataProviderBase.update(resource, params);

    const images = await addImages(params.data[ImageUploadField]);
    // console.log(
    //   "🚀 ~ file: index.js ~ line 43 ~ update: ~ images",
    //   images,
    // );
    return dataProviderBase.update(resource, {
      ...params,
      data: {
        ...params.data,
        [ImageUploadField]: [...images],
      },
    });
  },
  changePassword: changePassword,
};

export default DataProvider;
