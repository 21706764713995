//import { ImageUploadField } from "../Utils/Constants";

import { ErrorDescription, ImageUploadField } from "../Utils/Constants";

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    //console.log("🚀 ~ file: commonFunctions.js ~ line 10 ~ file", file);
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export const addImages = async (imgData) => {
  //console.log("🚀 ~ file: index.js ~ line 234 ~ imgData", imgData);
  //let images = [];
  // console.log(
  //   "🚀 ~ file: index.js ~ line 237 ~ Array.isArray(imgData)",
  //   Array.isArray(imgData)
  // );
  if (Array.isArray(imgData)) {
    console.log(
      "🚀 ~ file: commonFunctions.js ~ line 26 ~ addImages ~ imgData",
      imgData
    );
    //console.log("🚀 ~ file: index.js ~ line 238 ~ imgData", imgData);
    const img = imgData.filter((p) => p.rawFile instanceof File);
    const images = img.map((e) => convertFileToBase64(e));
    //images = images.map(e=>e.rawFile)
    return Promise.all(images);
  } else {
    if (imgData.rawFile) {
      const res = await convertFileToBase64(imgData);
      const images = [];
      images.push(res);
      return images;
      //images.push( imgData.rawFile);
    }
  }
  //return images;
};
export const addFormData = (paramData) => {
  const formData = new FormData();
  const arrayError = {};
  const keys = Object.keys(paramData);
  keys.forEach((key) => {
    console.log(
      "🚀 ~ file: commonFunctions.js ~ line 44 ~ keys.forEach ~ key",
      key,
      paramData[key]
    );

    if (paramData[key] !== null) {
      if (Array.isArray(paramData[key])) {
        const blankArray = new Array(paramData[key].length).fill(null);
        arrayError[key] = blankArray;
        paramData[key].forEach((element) => {
          if (element.rawFile instanceof File && key !== ImageUploadField) {
            // console.log(
            //   "🚀 ~ file: commonFunctions.js ~ line 60 ~ paramData[key].forEach ~ element",
            //   "its a file"
            // );
            formData.append(`${key}[]`, element.rawFile);
          } else {
            formData.append(`${key}[]`, element);
          }
        });
      } else formData.append(key, paramData[key]);
    }
  });
  return {
    formData,
    arrayError,
  };
};

export const arrayValidation = (key, arrayError, status) => {
  const isArrayValidation = RegExp("^[a-zA-z]+.([\\d)]+).[a-zA-Z]+$", "g").test(
    key
  );
  if (isArrayValidation) {
    const checkArrayValidation = key.split(".");
    if (
      checkArrayValidation &&
      Array.isArray(checkArrayValidation) &&
      checkArrayValidation.length >= 3
    ) {
      const getArrayErrors = [...arrayError[checkArrayValidation[0]]];
      if (
        getArrayErrors &&
        Array.isArray(getArrayErrors) &&
        getArrayErrors.length > checkArrayValidation[1]
      ) {
        getArrayErrors[checkArrayValidation[1]] = {
          ...getArrayErrors[checkArrayValidation[1]],
          [checkArrayValidation[2]]: status[ErrorDescription][key][0],
        };
      }
      arrayError[checkArrayValidation[0]] = [...getArrayErrors];
    }
    return true;
  }
  return false;
};

export const getArrayValidationErrors = (arrayError, errors) => {
  const arrayErrKeys = Object.keys(arrayError);
  arrayErrKeys.forEach((key) => {
    console.log(
      "🚀 ~ file: createData.js ~ line 89 ~ arrayErrKeys.forEach ~ key",
      arrayError[key]
    );
    if (arrayError[key].filter((e) => e !== null).length > 0)
      errors.validations[key] = [...arrayError[key]];
  });
};
