import {
  choices,
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  required,
} from "react-admin";
import { CouponFields, GetCouponTypes } from "../Utils/Constants";

//#region [ fromDate ]
const fromDate = (value, allValues) => {
  //   console.log(
  //     "🚀 ~ file: CouponEdit.js ~ line 48 ~ fromDateValidation ~ data",
  //     value,
  //     allValues
  //   );
  if (!value) {
    if (allValues && allValues[CouponFields.ToDate]) {
      return "Required Else Clear ToDate Also";
    }
  } else {
    if (allValues && allValues[CouponFields.ToDate]) {
      if (Date.parse(value) >= Date.parse(allValues[CouponFields.ToDate])) {
        return "Should be less then ToDate";
      }
    }
  }
  return undefined;
};
//#endregion [ fromDate ]

//#region [ toDate ]
const toDate = (value, allValues) => {
  //   console.log(
  //     "🚀 ~ file: CouponEdit.js ~ line 48 ~ toDateValidation ~ data",
  //     value,
  //     allValues
  //   );
  // if (!value) {
  //   if (allValues && allValues[CouponFields.FromDate]) {
  //     return "Required Else Clear FromDate Also";
  //   }
  // } else {
  if (value && allValues && allValues[CouponFields.FromDate]) {
    if (Date.parse(value) <= Date.parse(allValues[CouponFields.FromDate])) {
      return "Should be greater then FromDate";
    }
  }
  //}
  return undefined;
};
//#endregion [ toDate ]

//#region [ Validation Methods ]
const couponCompany = [required()];
const couponCode = [required(), minLength(3), maxLength(50)];
const couponDescrtiption = [required(), minLength(3), maxLength(1000)];
const couponPercentage = [required(), number(), minValue(1), maxValue(99)];
const couponType = [required(), choices(GetCouponTypes())];
const couponDays = [required(), number(), minValue(1)];
const couponFromDate = [fromDate];
const couponToDate = [toDate];
const couponTeamName = [required(), minLength(1), maxLength(1000)];
//#endregion [ Validation Methods ]
const couponLimit = [number(), minValue(1)];
export const CouponValidations = {
  couponCode,
  couponCompany,
  couponDays,
  couponDescrtiption,
  couponFromDate,
  couponLimit,
  couponPercentage,
  couponTeamName,
  couponToDate,
  couponType,
};
