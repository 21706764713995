import React, { useState } from "react";
import { useRef } from "react";
import { commonFunctions } from "../Utils/Constants";

const ImageResponsiveComponent = ({
  src,
  srcset,
  alt = "",
  maxSize = null,
  ...rest
}) => {
  const imgRef = useRef(null);
  const [sizes, setSizes] = useState("1px");
  return (
    <img
      ref={imgRef}
      src={src}
      srcSet={srcset}
      alt={alt}
      sizes={sizes}
      {...rest}
      onLoad={() => {
        if (imgRef.current) {
          maxSize
            ? setSizes(maxSize)
            : setSizes(commonFunctions.responsiveImage(imgRef.current));
        }
      }}
    />
  );
};

export default ImageResponsiveComponent;
