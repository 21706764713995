import React, { useState } from "react";
import { ArrayInput, SimpleFormIterator, useGetList } from "react-admin";
import {
  API_ENDPOINTS,
  CommonFields,
  ItemGroupFields,
} from "../Utils/Constants";
import { makeStyles } from "@material-ui/core/styles";
import VarriantIttreator from "./VarriantIttreator";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { variantsValidator } from "./ItemValidations";
import { useFieldArray } from "react-final-form-arrays";
//import { useFormState } from "react-final-form";
import { useEffect } from "react";
import { TabLabels } from "./TabsLabels";
const useStyles = makeStyles((theme) => ({
  line: {
    borderBottom: "none",
    alignItems: "start",
    marginBottom: theme.spacing(1),
  },
}));
const VariantsTab = ({ className, setErrors, ...props }) => {
  // console.log(
  //   "🚀 ~ file: VariantsTab.js ~ line 16 ~ VariantsTab ~ props",
  //   props
  // );
  //const { errors, submitErrors } = useFormState();
  // console.log(
  //   "🚀 ~ file: VariantsTab.js ~ line 24 ~ VariantsTab ~ errors",
  //   errors,
  //   "submiterrors - ",
  //   submitErrors
  // );
  const [sizes, setSizes] = useState([]);
  const [fills, setFills] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const { data: sizeData, loading: sizeLoading } = useGetList(
    API_ENDPOINTS.ItemSizes,
    null,
    { field: CommonFields.Name, order: "ASC" }
  );
  const { data: fillData, loading: fillLoading } = useGetList(
    API_ENDPOINTS.ItemFills,
    null,
    { field: CommonFields.Name, order: "ASC" }
  );
  const { data: fabricData, loading: fabricLoading } = useGetList(
    API_ENDPOINTS.SwatchesGroup,
    null,
    { field: CommonFields.Name, order: "ASC" }
  );

  useEffect(() => {
    if (sizeData) {
      setSizes(Object.values(sizeData));
    }
    if (fillData) {
      // console.log(
      //   "🚀 ~ file: VariantsTab.js ~ line 40 ~ useEffect ~ fillData",
      //   Object.values(fillData)
      // );
      setFills(Object.values(fillData));
    }
    if (fabricData) {
      setFabrics(Object.values(fabricData));
    }
  }, [fillData, sizeData, fabricData]);

  const {
    meta: { error, submitError },
  } = useFieldArray(ItemGroupFields.Variants);
  // console.log(
  //   "🚀 ~ file: VariantsTab.js ~ line 23 ~ VariantsTab ~ error",
  //   error,
  //   "submit error -",
  //   submitError
  // );
  useEffect(() => {
    if (error || submitError) {
      if (setErrors) {
        setErrors((prev) => ({ ...prev, [TabLabels.VariantsTabLabel]: true }));
      }
    } else {
      if (setErrors) {
        setErrors((prev) => ({ ...prev, [TabLabels.VariantsTabLabel]: false }));
      }
    }
  }, [error, submitError, setErrors]);
  const [variantExpanded, setVariantExpanded] = useState(false);
  const classes = useStyles();
  return (
    <ArrayInput
      source={ItemGroupFields.Variants}
      label=""
      {...props}
      validate={variantsValidator}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false }}
        classes={{ line: classes.line }}
        removeButton={
          <IconButton>
            <DeleteIcon />
          </IconButton>
        }
      >
        <VarriantIttreator
          expanded={variantExpanded}
          setExpanded={setVariantExpanded}
          errors={submitError ?? error}
          sizeData={sizes}
          sizeLoadin={sizeLoading}
          fillData={fills}
          fillLoading={fillLoading}
          fabricData={fabrics}
          fabricLoading={fabricLoading}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default VariantsTab;
