import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { FieldTitle, useInput, useTranslate } from "react-admin";
import { ChromePicker } from "react-color";
const ColorInput = (props) => {
  const translate = useTranslate();
  //console.log("🚀 ~ file: index.js ~ line 6 ~ ColorInput ~ props", props);
  const {
    input: { name, onChange, onFocus, onBlur, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  // console.log(
  //   "🚀 ~ file: index.js ~ line 13 ~ ColorInput ~ touched, error ",
  //   touched,
  //   error
  //   //translate(error)
  // );

  // eslint-disable-next-line no-unused-vars
  const { label, source, resource, basePath = null, ...restProps } = props;

  const [isOpen, setIsOpen] = useState(false);
  const gotFocus = (e, s) => {
    open();
    onFocus(e);
  };
  const gotBlured = (e) => {
    close();
    onBlur(e);
  };
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };
  const onColorChange = ({ hex }) => {
    onChange(hex);
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <Chip
          style={{
            position: "absolute",
            right: "10px",
            top: "20%",
            borderRadius: "10%",
            backgroundColor: rest.value,
          }}
        />
        <TextField
          {...rest}
          {...restProps}
          name={name}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          error={!!(touched && error)}
          helperText={touched && error ? translate(error) : ""}
          onFocus={gotFocus}
          onBlur={gotBlured}
          fullWidth
        />
      </div>
      {isOpen && (
        <div
          style={{ width: "100%", marginTop: "10px" }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <ChromePicker
            width="100%"
            color={rest.value}
            onChange={onColorChange}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  );
};

export default ColorInput;
