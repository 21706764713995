import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { ReportsFileds, ReportBy } from "../../Utils/Constants";
import {
  sumbByCollectionCategory,
  sumByCategory,
  sumByCollection,
  sumByProduct,
  sumByProductColleciton,
  sumByProductCollecitonCategory,
} from "../DashboardFunctions";
import SalesPieChart from "./SalesPieChart";
import { useSelectStyle } from "../../styles";
import SelectBox from "../../CommonComponents/SelectBox";
const useToggleButtonStyle = makeStyles({
  root: {
    color: "#707070",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
      backgroundColor: "#6C757D",
    },
    "&$selected": {
      color: "white",
      backgroundColor: "#6C757D",
      "&:hover": {
        color: "white",
        backgroundColor: "#6C757D",
      },
    },
  },
  selected: {},
  hover: {},
});
const SalesReport = ({
  categories,
  collections,
  yearData,
  monthData,
  weekData,
  dayData,
}) => {
  const toggleButtonStyle = useToggleButtonStyle();
  const selectStyle = useSelectStyle();
  const [activeCategory, setActiveCategory] = useState(ReportBy.All);
  const [activeCollection, setActiveCollection] = useState(ReportBy.All);
  const [currentTab, setCurrentTab] = useState(ReportsFileds.Sales);
  const [currentReport, setCurrentReport] = useState(ReportBy.Year);
  const [reportCategoryData, setReportCategoryData] = useState([]);
  const [reportCollectionData, setReportCollectionData] = useState([]);
  // useEffect(() => {
  //   if (!yearData || yearData.length <= 0) return;
  //   setYearSum(sumByCategory(yearData));
  // }, [yearData]);
  useEffect(() => {
    // console.log(
    //   "🚀 ~ file: index.js ~ line 57 ~ useEffect ~ currentReport",
    //   currentReport,
    //   monthData
    // );
    // if (activeCategory !== "All") {
    //   const productSum = sumByProduct(yearData, activeCategory);
    //   console.log(
    //     "🚀 ~ file: index.js ~ line 63 ~ useEffect ~ activeCategory",
    //     activeCategory,
    //     yearData,
    //     productSum
    //   );
    // }
    if (currentReport === ReportBy.Year && yearData) {
      setReportCategoryData(
        activeCategory === ReportBy.All
          ? sumByCategory(yearData)
          : sumByProduct(yearData, activeCategory)
      );
      setReportCollectionData(
        activeCollection === ReportBy.All
          ? activeCategory === ReportBy.All
            ? sumByCollection(yearData)
            : sumbByCollectionCategory(yearData, activeCategory)
          : activeCategory === ReportBy.All
          ? sumByProductColleciton(yearData, activeCollection)
          : sumByProductCollecitonCategory(
              yearData,
              activeCollection,
              activeCategory
            )
      );
      // console.log(
      //   "SumCollection ",
      //   activeCategory !== "All"
      //     ? sumbByCollectionCategory(yearData, activeCategory)
      //     : "Np Catergry Selected"
      // );
    }
    if (currentReport === ReportBy.Month && monthData) {
      // console.log(
      //   "🚀 ~ file: index.js ~ line 60 ~ useEffect ~ monthData",
      //   monthData
      // );
      setReportCategoryData(
        activeCategory === ReportBy.All
          ? sumByCategory(monthData)
          : sumByProduct(monthData, activeCategory)
      );
      setReportCollectionData(
        activeCollection === ReportBy.All
          ? activeCategory === ReportBy.All
            ? sumByCollection(monthData)
            : sumbByCollectionCategory(monthData, activeCategory)
          : activeCategory === ReportBy.All
          ? sumByProductColleciton(monthData, activeCollection)
          : sumByProductCollecitonCategory(
              monthData,
              activeCollection,
              activeCategory
            )
      );
    }
    if (currentReport === ReportBy.Week && weekData) {
      setReportCategoryData(
        activeCategory === ReportBy.All
          ? sumByCategory(weekData)
          : sumByProduct(weekData, activeCategory)
      );
      setReportCollectionData(
        activeCollection === ReportBy.All
          ? activeCategory === ReportBy.All
            ? sumByCollection(weekData)
            : sumbByCollectionCategory(weekData, activeCategory)
          : activeCategory === ReportBy.All
          ? sumByProductColleciton(weekData, activeCollection)
          : sumByProductCollecitonCategory(
              weekData,
              activeCollection,
              activeCategory
            )
      );
    }
    if (currentReport === ReportBy.Day && dayData) {
      setReportCategoryData(
        activeCategory === ReportBy.All
          ? sumByCategory(dayData)
          : sumByProduct(dayData, activeCategory)
      );
      setReportCollectionData(
        activeCollection === ReportBy.All
          ? activeCategory === ReportBy.All
            ? sumByCollection(dayData)
            : sumbByCollectionCategory(dayData)
          : activeCategory === ReportBy.All
          ? sumByProductColleciton(dayData, activeCollection)
          : sumByProductCollecitonCategory(
              dayData,
              activeCollection,
              activeCategory
            )
      );
    }
  }, [
    currentReport,
    yearData,
    monthData,
    weekData,
    dayData,
    activeCategory,
    activeCollection,
  ]);

  return (
    <Card style={{ marginTop: "10px" }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="center"
        // style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} sm={4} align="left">
          <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#707070", paddingLeft: "10px" }}
          >
            Sales Report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} align="center" style={{ padding: 20 }}>
          <ToggleButtonGroup
            exclusive
            value={currentTab}
            onChange={(_, value) => {
              setCurrentTab(value);
            }}
          >
            <ToggleButton
              value={ReportsFileds.Sales}
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Sales</Typography>
            </ToggleButton>
            <ToggleButton
              value={ReportsFileds.Revenue}
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Revenue</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={4} align="right" style={{ paddingRight: 20 }}>
          <ToggleButtonGroup
            exclusive
            value={currentReport}
            onChange={(_, value) => {
              setCurrentReport(value);
            }}
          >
            <ToggleButton
              value="Year"
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Year</Typography>
            </ToggleButton>
            <ToggleButton
              value="Month"
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Month</Typography>
            </ToggleButton>
            <ToggleButton
              value="Week"
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Week</Typography>
            </ToggleButton>
            <ToggleButton
              value="Day"
              classes={{
                root: toggleButtonStyle.root,
                selected: toggleButtonStyle.selected,
              }}
            >
              <Typography variant="body1">Day</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={6} align="center" style={{ paddingLeft: "5px" }}>
          <Box width="80%" border={1} p={5}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} align="center">
                <Typography
                  variant="body1"
                  style={{ color: "#a5a5a5", fontWeight: "bold" }}
                >
                  Categories
                </Typography>
              </Grid>
              <Grid item xs={6} align="center">
                <SelectBox
                  value={activeCategory}
                  onChange={(e) => {
                    e.stopPropagation();
                    setActiveCategory(e.target.value);
                  }}
                  classes={{ select: selectStyle.select }}
                >
                  <MenuItem value={ReportBy.All}>All</MenuItem>
                  {categories &&
                    categories.map((category) => (
                      <MenuItem
                        value={category[ReportsFileds.CategoryID]}
                        key={category[ReportsFileds.CategoryID]}
                      >
                        {category[ReportsFileds.CategoryDisplayName]}
                      </MenuItem>
                    ))}
                </SelectBox>
              </Grid>
              <Grid item xs={12}>
                <SalesPieChart
                  data={reportCategoryData}
                  dataKey={currentTab}
                  reportBy={activeCategory === "All" ? "Category" : "Product"}
                  //selectedCategory={activeCategory}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} align="center">
          <Box width="80%" border={1} p={5}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={6} align="center">
                <Typography
                  variant="body1"
                  style={{ color: "#a5a5a5", fontWeight: "bold" }}
                >
                  Collections
                </Typography>
              </Grid>
              <Grid item xs={6} align="center">
                <SelectBox
                  value={activeCollection}
                  onChange={(e) => {
                    e.stopPropagation();
                    setActiveCollection(e.target.value);
                  }}
                  classes={{ select: selectStyle.select }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {collections &&
                    collections.map((collection) => (
                      <MenuItem
                        value={collection[ReportsFileds.CollectionID]}
                        key={collection[ReportsFileds.CollectionID]}
                      >
                        {collection[ReportsFileds.CollectionDisplayName]}
                      </MenuItem>
                    ))}
                </SelectBox>
              </Grid>
              <Grid item xs={12}>
                <SalesPieChart
                  data={reportCollectionData}
                  dataKey={currentTab}
                  reportBy={activeCollection === "All" ? "Category" : "Product"}
                  isCategory={false}
                  //selectedCategory={activeCategory}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={6}
          container
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs={6} align="center">
            <Typography variant="body1">Categories</Typography>
          </Grid>
          <Grid item xs={6} align="center">
            <SelectBox
              value={activeCategory}
              onChange={(e) => {
                e.stopPropagation();
                setActiveCategory(e.target.value);
              }}
              classes={{ select: selectStyle.select }}
            >
              <MenuItem value="All">All</MenuItem>
              {categories &&
                categories.map((category) => (
                  <MenuItem
                    value={category[ReportsFileds.CategoryID]}
                    key={category[ReportsFileds.CategoryID]}
                  >
                    {category[ReportsFileds.CategoryDisplayName]}
                  </MenuItem>
                ))}
            </SelectBox>
          </Grid>
          <Grid item xs={12}>
            <SalesPieChart
              data={yearSum}
              dataKey={currentTab}
              //selectedCategory={activeCategory}
            />
          </Grid>
        </Grid> */}
      </Grid>
    </Card>
  );
};

export default SalesReport;
