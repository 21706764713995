import { regex, required } from "react-admin";
const matchPassword = (value, allvalues) => {
  if (allvalues && allvalues["password"]) {
    return value !== allvalues["password"]
      ? "Must match with password"
      : undefined;
  }
  return undefined;
};
export const passwordValidation = [
  required(),
  regex(
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,})$/,
    "Must be min 8 charactes must contain 1 uppercase,1 lowercase,1 digit,1 special character"
  ),
];
export const passwordConfirmValidation = [required(), matchPassword];
