import React, { useCallback } from "react";
import {
  Edit,
  SimpleForm,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import EditTopActions from "../CommonComponents/EditTopActions";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import TitleComponent from "../CommonComponents/TitleComponent";
import { API_ENDPOINTS, CategoryFields } from "../Utils/Constants";
import CategoryCommonInputs from "./CategoryCommonInputs";

const CategoryEdit = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [mutate] = useMutation();
  const saveRecord = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: API_ENDPOINTS.Categories,
            payload: { data: values, id: values.id },
          },
          {
            returnPromise: true,
            onSuccess: ({ data }) => {
              if (data) {
                notify(
                  `Category  [ ${data[CategoryFields.Name]} ] Updated`,
                  "success"
                );
              } else {
                notify(`Category Updated`, "success");
              }
              refresh();
            },
            onFailure: (err) => {
              if (!err.validations) {
                notify(`Error: ${err.message ? err.message : err}`, "error");
              }
            },
          }
        );
      } catch (error) {
        if (error && error.validations) {
          return error.validations;
        } else {
          //notify(error, "error");
          return Promise.reject(error);
        }
      }
    },
    [mutate, notify, refresh]
  );

  //const onSuccess = ({ data }) => {};
  return (
    <Edit
      {...props}
      title={<TitleComponent source={CategoryFields.Name} title="Edit - " />}
      actions={<EditTopActions />}
      mutationMode="pessimistic"
      // onFailure={(err) => {
      //   console.log(
      //     "🚀 ~ file: CategoryEdit.js ~ line 28 ~ CategoryEdit ~ err",
      //     err
      //   );
      // }}
    >
      <SimpleForm
        variant="outlined"
        save={saveRecord}
        redirect={false}
        toolbar={<SaveToolBar />}
      >
        <CategoryCommonInputs isEdit={true} />
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
