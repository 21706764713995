import { API_ENDPOINTS } from "../Utils/Constants";

const couponsMenus = {
  list: {
    label: "List",
    pathname: `/${API_ENDPOINTS.Coupons}`,
  },
  create: {
    label: "Create",
    pathname: `/${API_ENDPOINTS.Coupons}/create`,
  },
  config: {
    label: "Configuration",
    pathname: `/${API_ENDPOINTS.Companies}`,
  },
};

export default couponsMenus;
