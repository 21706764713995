import React from "react";
import { Datagrid, List, 
//  TextField 
} from "react-admin";
//import { makeStyles } from "@material-ui/core/styles";
//import AvatarField from "../CommonComponents/AvatarField";
//import { ArchitectFields } from "../Utils/Constants/ArchitectFields";
import ArchitectTitleComponent from "./ArchitectTitleComponent";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "nowrap",
//     alignItems: "center",
//   },
//   avatar: {
//     marginRight: theme.spacing(1),
//     marginTop: -theme.spacing(0.5),
//     marginBottom: -theme.spacing(0.5),
//   },
// }));

// const ArchitectTitle = ({ record = null }) => {
//   const classes = useStyles();
//   return record ? (
//     <div className={classes.root}>
//       <AvatarField
//         record={record}
//         src={ArchitectFields.image}
//         srcSet={ArchitectFields.srcSet}
//       />
//       {record[ArchitectFields.Title]}
//     </div>
//   ) : null;
// };
const ArchitectsList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false}>
      <Datagrid>
        <ArchitectTitleComponent />
      </Datagrid>
    </List>
  );
};

export default ArchitectsList;
