import { OrderStatusConstantsByName } from "./OrderStatusConst";

const validateShipping = (data = [], statusField, field, record = null) => {
  if (!record) {
    return data.every((row) => {
      const status = Number(row[statusField]);
      const trackingNo = row[field];
      if (
        OrderStatusConstantsByName.SHIPPED === status &&
        trackingNo.trim() === ""
      ) {
        return false;
      }
      return true;
    });
  } else {
    if (record[statusField]) {
      const status = Number(record[statusField]);
      if (OrderStatusConstantsByName.SHIPPED !== status) return true;
      if (!record[field]) return false;
      const trackingNo = record[field];
      console.log(
        "🚀 ~ file: CommonFunctions.js ~ line 19 ~ validateShipping ~ status",
        status,
        trackingNo
      );
      return trackingNo.trim() !== "";
    } else {
      return false;
    }
  }
};

const getColorArray = (num) => {
  var result = [];
  for (var i = 0; i < num; i += 1) {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var j = 0; j < 6; j += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    result.push(color);
  }
  return result;
};

const getWeeksStartAndEndInMonth = (month, year) => {
  let weeks = [],
    //firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month, 0),
    numDays = lastDate.getDate();

  let start = 1;
  let end = GetfirstWeekEnd(month, year).getDate();
  while (start <= numDays) {
    weeks.push({
      start: start + "/" + month + "/" + year,
      end: end + "/" + month + "/" + year,
    });
    start = end + 1;
    end = end + 7;
    end = start === 1 && end === 8 ? 1 : end;
    if (end > numDays) {
      end = numDays;
    }
  }
  return weeks;
};

function GetfirstWeekEnd(month, year) {
  var firstDay = new Date(year + "-" + month + "-" + 1);
  //var first = firstDay;
  //const lastDay = new Date(year, month, 0);
  //const daysInMonth = lastDay.getDate();
  let dayOfWeek = firstDay.getDay();
  var endday = "";
  var startCount = dayOfWeek;
  if (startCount !== 0) {
    endday = new Date(firstDay.setDate(firstDay.getDate() + (7 - dayOfWeek)));
  } else {
    endday = new Date(firstDay.setDate(firstDay.getDate() + 1));
  }
  return endday;
}

// function endFirstWeek(firstDate, firstDay) {
//   if (!firstDay) {
//     return 7 - firstDate.getDay();
//   }
//   if (firstDate.getDay() < firstDay) {
//     return firstDay - firstDate.getDay();
//   } else {
//     return 7 - firstDate.getDay() + firstDay;
//   }
// }

/**
 *
 * @param {*} color
 * @param {*} percent Negative percent for Dark Color, Postive Percent for Light Color
 */
const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

const getDayWeekMonth = (date) => {
  const d = new Date(+date);
  const curDate = d.getDate();
  //const day = d.getDay();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const week = Math.ceil(curDate / 7); //Math.ceil((curDate + 6 - day) / 7);
  // console.log(
  //   "🚀 ~ file: CommonFunctions.js ~ line 129 ~ getDayWeekMonth ~ week",
  //   week
  // );
  return {
    Day: curDate,
    Week: week,
    Month: month,
    Year: year,
  };
};
const getDaysInMonth = (month, year) => {
  const lastDay = new Date(year, month, 0).getDate();
  return Array.apply(null, Array(lastDay)).map((_, indx) => ({
    id: indx + 1,
    name: indx + 1,
  }));
};

const responsiveImage = (imageRef) => {
  return (
    Math.ceil(
      (imageRef.getBoundingClientRect().width / window.innerWidth) * 100
    ) + "vw"
  );
};

export const commonFunctions = {
  getColorArray,
  getDayWeekMonth,
  getWeeksStartAndEndInMonth,
  getDaysInMonth,
  responsiveImage,
  shadeColor,
  validateShipping,
};
