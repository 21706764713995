import { useCallback, useMemo, useState } from "react";
import {
  PasswordInput,
  SaveContextProvider,
  SimpleForm,
  Title,
  useLogout,
  useMutation,
  useNotify,
} from "react-admin";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import Paper from "@material-ui/core/Paper";
import {
  passwordConfirmValidation,
  passwordValidation,
} from "./PasswordValidtaions";
export const ProfileEdit = (props) => {
  //console.log("🚀 ~ file: index.js ~ line 2 ~ ProfileEdit ~ props", props);
  const [saving, setSaving] = useState(false);
  const logout = useLogout();
  const notify = useNotify();
  const [mutate] = useMutation();
  const handleSave = useCallback(
    async (values) => {
      //   console.log(
      //     "🚀 ~ file: index.js ~ line 10 ~ ProfileEdit ~ values",
      //     values
      //   );
      setSaving(true);
      try {
        await mutate(
          { type: "changePassword", payload: values },
          {
            returnPromise: true,
            onSuccess: () => {
              logout();
              notify("Password Changed\nPlease login again", {
                type: "success",
                multiLine: true,
              });
            },
            onFailure: (err) => {
              if (!err.validations) {
                notify(`Error :${err.message ?? err}`, {
                  type: "error",
                  multiLine: true,
                });
              } else {
                notify("Check Validation Errors", {
                  type: "error",
                  multiLine: true,
                });
              }
            },
          }
        );
        setSaving(false);
      } catch (err) {
        setSaving(false);
        console.log("🚀 ~ file: index.js ~ line 49 ~ ProfileEdit ~ err", err);
        if (err.validations) {
          return err.validations;
        }
        //return
      }
    },
    [mutate, logout, notify]
  );
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );
  return (
    <SaveContextProvider value={saveContext}>
      <Title title="Change Password" />
      <Paper>
        <SimpleForm save={handleSave} record={{}} toolbar={<SaveToolBar />}>
          <PasswordInput
            label="Password"
            source="password"
            variant="outlined"
            validate={passwordValidation}
            fullWidth
          />
          <PasswordInput
            label="Confirm Password"
            source="password_confirmation"
            variant="outlined"
            validate={passwordConfirmValidation}
            fullWidth
          />
        </SimpleForm>
      </Paper>
    </SaveContextProvider>
  );
};
