import makeStyles from "@material-ui/core/styles/makeStyles";
export const useSelectStyle = makeStyles({
  select: {
    background: "#6C757D",
    color: "white",
    minWidth: 100,
    paddingLeft: 25,
    paddingTop: 15,
    paddingBottom: 15,
    borderStyle: "none",
    borderWidth: 2,
    borderRadius: 5,
    boxShadow: "0px 5px 8px -3px rgba(0,0,0,0.14)",
    textAlign: "left",
    "&:focus": {
      borderRadius: 5,
      background: "#6C757D",
      borderColor: "black",
    },
    '&[aria-expanded="true"]': {
      background: "#8e9499",
    },
  },
  paper: {
    borderRadius: 5,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    "& li": {
      fontWeight: 500,
      paddingTop: 12,
      paddingBottom: 12,
    },
    "& li:hover": {
      color: "white",
      background: "#6C757D",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#6C757D",
    },
    "& li.Mui-selected:hover": {
      color: "white",
      background: "#6C757D",
    },
  },
  icon: {
    color: "white",
    position: "absolute",
    right: 2,
    userSelect: "none",
    pointerEvents: "none",
  },
});
