import React from "react";
import { MenuItemLink, UserMenu } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
const MyUserMenu = (props) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/profile"
        primaryText="Change Password"
        leftIcon={<SettingsIcon />}
        activeClassName=""
      />
    </UserMenu>
  );
};

export default MyUserMenu;
