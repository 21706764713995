export * from "./ArchitectFields";
export * from "./CategoryFields";
export * from "./CollectionFields";
export * from "./CommonFields";
export * from "./CommonFunctions";
export * from "./CompanyFields";
export * from "./DiscountCouponFields";
export * from "./ItemGroupFields";
export * from "./LineItemFields";
export * from "./OrderFields";
export * from "./OrderStatusConst";
export * from "./PaymentFields";
export * from "./PincodeFields";
export * from "./SwatchFields";
export * from "./UserFields";
export * from "./ReportFields";

export const UNKNOWN_ERROR = "UNKNOWN ERROR";
export const AUTH_KEY = "auth";
export const USER_NAME_KEY = "username";
export const MENU_LOCATIONS = {
  Orders: "orders",
  Coupons: "coupons",
  HomePage: "homepage",
  Products: "products",
  Swatches: "swatches",
  Architects: "architects",
};

export const API_ENDPOINTS = {
  Categories: `${MENU_LOCATIONS.Products}/categories`,
  Companies: `${MENU_LOCATIONS.Coupons}/config`,
  Collections: `${MENU_LOCATIONS.Products}/collections`,
  Coupons: MENU_LOCATIONS.Coupons,
  Items: `${MENU_LOCATIONS.Products}/items`,
  LineItems: "lineitems",
  Orders: MENU_LOCATIONS.Orders,
  Pincodes: "pincodes",
  PinceCodesFieldValidation: "pincodes/validate",
  Products: "products",
  Reports: "reports",
  StateList: "statelist",
  Swatches: MENU_LOCATIONS.Swatches,
  SwatchesGroup: `${MENU_LOCATIONS.Swatches}/group`,
  TopSlides: `${MENU_LOCATIONS.HomePage}/topslides`,
  Users: "users",
  Architects: MENU_LOCATIONS.Architects,
  ItemSizes: `${MENU_LOCATIONS.Products}/sizes`,
  ItemFills: `${MENU_LOCATIONS.Products}/fills`,
  UserProfile: `profile`,
};

export const AUTH_ENDPOINTS = {
  LOGIN: "login",
};
export const apiUrl = `${process.env.REACT_APP_API_URL}`; //"https://adminapi.bombayliving.co.in/api";
// process.env.NODE_ENV === "production"
//   ? "https://adminapi.bombayliving.co.in/api"
//   : "http://bladmin.test:81/api";

export const authUrl = `${apiUrl}/auth`;
export const TabsConstant = {
  Sale: 1,
  Revenue: 2,
};
export const ReportHeight = 300;
const monthData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const Months = monthData.map((month, indx) => ({
  id: indx + 1,
  name: month,
}));

export const ReportBy = {
  Year: "Year",
  Month: "Month",
  Week: "Week",
  Day: "Day",
  All: "All",
  Category: "Category",
  Product: "Product",
};

export const TopSlide_Url_Const = [
  { id: 1, name: "Collection" },
  { id: 2, name: "Category" },
  { id: 3, name: "Product" },
];

export const ImageUploadField = "ImgUpload";

export const DisplayColumnsList = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
];

export const StockType = [
  { id: 1, name: "Manual Stock" },
  { id: 2, name: "Auto Stock" },
];
export const SkipFromMenu = "skipFromMenu";

export const ErrorCode = "errorCode";
export const ErrorDescription = "errorDescription";
