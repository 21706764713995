import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReToolTipForBar from "../../CommonComponents/ReToolTipForBar";
import {
  commonFunctions,
  ReportHeight,
  ReportsFileds,
} from "../../Utils/Constants";

const ReLineChart = ({
  data,
  categories,
  type,
  xaxisDataKey = null,
  xaxislabel = "",
  yaxisDataKey = null,
  yaxislabel = "",
}) => {
  const [barKey, setBarKey] = useState("");
  if (categories.length && categories.length <= 0) return null;
  return (
    <ResponsiveContainer width="100%" height={ReportHeight}>
      <LineChart data={data} margin={{ top: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray={4} />
        <Legend
          verticalAlign="top"
          align="left"
          wrapperStyle={{ paddingBottom: "10px" }}
        />
        <Tooltip
          content={({ payload, label }) => (
            <ReToolTipForBar payload={payload} label={label} barKey={barKey} />
          )}
        />
        {/* <XAxis /> */}
        {xaxisDataKey ? (
          <XAxis dataKey={xaxisDataKey}>
            <Label value={xaxislabel} offset={-5} position="insideBottom" />
          </XAxis>
        ) : (
          <XAxis>
            <Label value={xaxislabel} offset={-5} position="insideBottom" />
          </XAxis>
        )}
        {/* <YAxis /> */}
        {yaxisDataKey ? (
          <YAxis dataKey={yaxisDataKey}>
            <Label value={yaxislabel} offset={-5} position="insideBottom" />
          </YAxis>
        ) : (
          <YAxis>
            <Label value={yaxislabel} offset={-5} position="insideBottom" />
          </YAxis>
        )}
        {categories.map((category) => {
          const key = `${category[ReportsFileds.CategoryID]}_${type}`;
          return (
            <Line
              key={key}
              name={category[ReportsFileds.CategoryDisplayName]}
              type="monotone"
              dataKey={key}
              stroke={commonFunctions.shadeColor(
                category[ReportsFileds.Color],
                -20
              )}
              strokeWidth={2}
              dot={{
                onMouseOver: () => {
                  setBarKey(key);
                },
              }}
              activeDot={{ r: barKey === key ? 10 : 0 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
ReLineChart.propType = {
  data: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  xaxisDataKey: PropTypes.string,
  xaxislabel: PropTypes.string,
  yaxisDataKey: PropTypes.string,
  yaxislabel: PropTypes.string,
};
export default ReLineChart;
