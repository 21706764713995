import React from "react";
import { Filter, List, ReferenceInput, SelectInput } from "react-admin";
import ImageGridList from "../CommonComponents/ImageGridList";
import ImageGridPagination, {
  ImageRowPerPage,
} from "../CommonComponents/ImageGridList/ImageGridPagination";
import ImageListToolbar from "../CommonComponents/ImageListToolbar";
import { API_ENDPOINTS, SwatchFields } from "../Utils/Constants";
const SwatchFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        label="Swatch Type"
        perPage={null}
        reference={API_ENDPOINTS.SwatchesGroup}
        source={SwatchFields.FabricGroupID}
        sort={{ field: SwatchFields.SortOrder, order: "ASC" }}
        variant="outlined"
      >
        <SelectInput optionText={SwatchFields.Name} resettable />
      </ReferenceInput>
    </Filter>
  );
};
const SwatchList = (props) => {
  return (
    <List
      {...props}
      actions={
        <ImageListToolbar
          fields={[
            SwatchFields.SortOrder,
            SwatchFields.Name,
            SwatchFields.Status,
          ]}
        />
      }
      bulkActionButtons={false}
      exporter={false}
      filters={<SwatchFilters />}
      pagination={<ImageGridPagination />}
      sort={{ field: SwatchFields.SortOrder, order: "ASC" }}
      perPage={ImageRowPerPage()}
    >
      <ImageGridList titleSource={SwatchFields.Name} />
    </List>
  );
};

export default SwatchList;
