import React from "react";
import { Datagrid, EmailField, List, TextField } from "react-admin";
import BooleanFieldYN from "../CommonComponents/BooleanFieldYN";
import { UserFields } from "../Utils/Constants";

const UserList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source={UserFields.UserID} label="User" />
        <TextField source={UserFields.FullName} label="Full Name" />
        <TextField source={UserFields.MobileNo} label="Mobile" />
        <EmailField source={UserFields.EmailId} label="Email" />
        <BooleanFieldYN source={UserFields.IsActive} />
      </Datagrid>
    </List>
  );
};

export default UserList;
