import React from "react";
import withWidth from "@material-ui/core/withWidth";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LoadingGrid from "./LoadingGrid";
import ImageGrid from "./ImageGrid";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-2px",
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "space-around",
    overflow: "hidden",
    padding: "5px",
  },
  gridList: {
    width: "100%",
    // margin: 0,
    //width: 500,
    //height: 450,
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
}));

const getColsForWidth = (width) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg" || width === "xl") return 6;
};

const ImageGridList = ({ loading, width, titleSource, ...rest }) => {
  const styles = useStyles();
  return loading ? (
    <LoadingGrid cols={getColsForWidth(width)} styles={styles} />
  ) : (
    <ImageGrid
      cols={getColsForWidth(width)}
      styles={styles}
      titleSource={titleSource}
      {...rest}
    />
  );
};

export default withWidth()(ImageGridList);
