export const LineItemFields = {
  ID: "id",
  OrderID: "OrderID",
  OrderNo: "OrderNo",
  ItemID: "ItemID",
  Item: "Item",
  FabricID: "FabricID",
  Fabric: "Fabric",
  Qty: "Qty",
  Rate: "Rate",
  Amount: "Amount",
  TaxableAmount: "TaxableAmount",
  TaxAmount: "TaxAmount",
  Status: "Status",
  TrackingNo: "TrackingNo",
  TrackingUrl: "TrackingWebsite",
};
