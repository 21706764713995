import CompanyList from "./CompaniesList";
import { API_ENDPOINTS, SkipFromMenu } from "../Utils/Constants";
import RedeemIcon from "@material-ui/icons/Redeem";
const Companies = {
  name: API_ENDPOINTS.Companies,
  list: CompanyList,
  icon: RedeemIcon,
  options: { label: "Rewards", [SkipFromMenu]: true },
};
export default Companies;
