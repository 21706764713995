import React from "react";
import { FunctionField } from "react-admin";

const PercentField = ({ record = {}, source, label }) => {
  return (
    <FunctionField
      record={record}
      source={source}
      label={label}
      render={(record) => `${record[source]}%`}
    />
  );
};

export default PercentField;
