import MaterialTable from "@material-table/core";
import React, { useCallback, useState } from "react";
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import TitleComponent from "../CommonComponents/TitleComponent";

import {
  API_ENDPOINTS,
  commonFunctions,
  LineItemFields,
  OrderFields,
  OrderGridStatusConst,
  OrderStatusConst,
} from "../Utils/Constants";
import OrderExpandedSaveToolBar from "./OrderExpandedSaveToolBar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
const OrderItemsExpanded = (props) => {
  const { id, record } = props;
  const notify = useNotify();
  const [isSaving, setIsSaving] = useState(false);
  const dataProvider = useDataProvider();
  const isLoading = useSelector((state) => state.admin.loading);

  //   console.log(
  //     "🚀 ~ file: OrderItemsExpanded.js ~ line 5 ~ OrderItemsExpanded ~ props",
  //     id,
  //     record
  //   );
  //console.log("Props", props);
  const selectComponent = useCallback(
    (props) => (
      <Select
        value={props.value}
        variant="outlined"
        onChange={(e) => props.onChange(e.target.value)}
      >
        {OrderStatusConst.map((item) => (
          <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    ),
    []
  );
  const validateTrackingNo = useCallback((rowData) => {
    const status = rowData[LineItemFields.Status];
    const trackingNo = rowData[LineItemFields.TrackingNo];
    const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;

    return retVal;
  }, []);
  const validateTrackingUrl = useCallback((rowData) => {
    const status = rowData[LineItemFields.Status];
    const trackingNo = rowData[LineItemFields.TrackingUrl];
    const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;

    return retVal;
  }, []);
  const columns = [
    {
      title: "ID",
      field: LineItemFields.ID,
      hidden: true,
    },
    {
      title: "Item",
      field: LineItemFields.Item,
      editable: "never",
    },
    {
      title: "Fabric",
      field: LineItemFields.Fabric,
      editable: "never",
    },
    {
      title: "Qty",
      field: LineItemFields.Qty,
      editable: "never",
    },
    {
      title: "Status",
      field: LineItemFields.Status,
      lookup: OrderGridStatusConst,
      editComponent: selectComponent,
      // editComponent: (props) => (
      //   <Select
      //     value={props.value}
      //     variant="outlined"
      //     onChange={(e) => props.onChange(e.target.value)}
      //   >
      //     {OrderStatusConst.map((item) => (
      //       <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
      //         {item.name}
      //       </MenuItem>
      //     ))}
      //   </Select>
      // ),
    },
    {
      title: "Tracking",
      field: LineItemFields.TrackingNo,
      validate: validateTrackingNo,
      // validate: (rowData) => {
      //   // console.log(
      //   //   "🚀 ~ file: OrderItemsExpanded.js ~ line 76 ~ OrderItemsExpanded ~ rowData",
      //   //   rowData
      //   // );
      //   const status = rowData[LineItemFields.Status];
      //   const trackingNo = rowData[LineItemFields.TrackingNo];
      //   const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;

      //   return retVal;
      //   // rowData[LineItemFields.TrackingNo].length <= 0
      //   //   ? "TrackingNo Required"
      //   //   : "",
      // },
    },
    {
      title: "TrackingUrl",
      field: LineItemFields.TrackingUrl,
      validate: validateTrackingUrl,
      // validate: (rowData) => {
      //   const status = rowData[LineItemFields.Status];
      //   const trackingNo = rowData[LineItemFields.TrackingUrl];
      //   const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;

      //   return retVal;
      // },
    },
  ];
  const onUpdateBulk = async (changes) => {
    const vals = Object.values(changes);
    if (vals.length > 0) {
      try {
        setIsSaving(true);
        const data = vals.map((ele) => ele.newData);
        let isValid = true;
        isValid = commonFunctions.validateShipping(
          data,
          LineItemFields.Status,
          LineItemFields.TrackingNo
        );
        if (!isValid) {
          throw new Error(
            "Provide trackingno for products with shipped status"
          );
        }
        isValid = true;
        isValid = commonFunctions.validateShipping(
          data,
          LineItemFields.Status,
          LineItemFields.TrackingUrl
        );
        if (!isValid) {
          throw new Error(
            "Provide tracking url for products with shipped status"
          );
        }
        await dataProvider.updateMany(
          `${API_ENDPOINTS.Orders}/${API_ENDPOINTS.LineItems}`,
          {
            ids: id,
            data: data,
          }
        );
        await dataProvider.getOne(API_ENDPOINTS.Orders, {
          id: id,
        });
        setIsSaving(false);
        notify(`Order ${record[OrderFields.OrderID]} Updated`, "info");
        return Promise.resolve();
      } catch (error) {
        setIsSaving(false);
        notify(error.message ? error.message : error, "error");
        return Promise.reject();
      }
    } else return Promise.resolve();
  };
  const onUpdateRow = async (newdata, olddata) => {
    //console.log(newdata, olddata);
    try {
      setIsSaving(true);
      const data = [];
      data.push(newdata);
      let isValid = true;
      isValid = commonFunctions.validateShipping(
        data,
        LineItemFields.Status,
        LineItemFields.TrackingNo
      );
      if (!isValid) {
        throw new Error("Provide trackingno for products with shipped status");
      }
      isValid = true;
      isValid = commonFunctions.validateShipping(
        data,
        LineItemFields.Status,
        LineItemFields.TrackingUrl
      );
      if (!isValid) {
        throw new Error(
          "Provide tracking url for products with shipped status"
        );
      }
      await dataProvider.updateMany(
        `${API_ENDPOINTS.Orders}/${API_ENDPOINTS.LineItems}`,
        {
          ids: id,
          data: data,
        }
      );
      await dataProvider.updateMany(
        `${API_ENDPOINTS.Orders}/${API_ENDPOINTS.LineItems}`,
        {
          ids: id,
          data: data,
        }
      );
      await dataProvider.getOne(API_ENDPOINTS.Orders, {
        id: id,
      });
      setIsSaving(false);
      notify(`Order ${record[OrderFields.OrderID]} Updated`, "info");
      return Promise.resolve();
    } catch (error) {
      setIsSaving(false);
      notify(error.message ? error.message : error, "error");
      return Promise.reject();
    }
    //return Promise.resolve();
  };

  if (!record[OrderFields.Editable]) return null;
  return (
    <div>
      <Edit
        {...props}
        title={<TitleComponent source={OrderFields.OrderID} title="" />}
      >
        <SimpleForm
          toolbar={
            <OrderExpandedSaveToolBar
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              {...props}
            />
          }
        >
          <SelectInput
            label="Status"
            source={OrderFields.Status}
            variant="outlined"
            choices={OrderStatusConst}
          />
          <TextInput source={LineItemFields.TrackingNo} variant="outlined" />
          <TextInput source={LineItemFields.TrackingUrl} variant="outlined" />
        </SimpleForm>
      </Edit>
      <MaterialTable
        columns={columns}
        data={record[OrderFields.LineItems]}
        options={{
          search: false,
          sorting: false,
          pageSize: 5,
          pageSizeOptions: [5, 10],
        }}
        title="Ordered Items"
        editable={{
          onBulkUpdate: record[OrderFields.Editable] ? onUpdateBulk : null,
          onRowUpdate: record[OrderFields.Editable] ? onUpdateRow : null,
        }}
        isLoading={isSaving || isLoading !== 0}
      ></MaterialTable>
    </div>
  );
};

export default OrderItemsExpanded;
