import React from "react";

const ReToolTipForBar = ({ payload, label, barKey }) => {
  return (
    <div>
      <div
        className="recharts-custom-tooltip"
        style={{
          visibility:
            barKey === "" || !payload || payload.length <= 0
              ? "hidden"
              : "visible",
        }}
      >
        <p className="tooltip-label">{label}</p>
        {payload &&
          payload.length &&
          payload.map(({ name, color, value, dataKey }, index) => {
            const textColor = color;
            if (dataKey === barKey) {
              return (
                <p
                  key={index}
                  className="tooltip-items"
                  style={{ color: textColor }}
                >
                  {`${name}: ${value}`}
                </p>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default ReToolTipForBar;
