import { CommonFields } from "./CommonFields";

export const SwatchFields = {
  ...CommonFields,
  CGSTPercent: "CGSTPercent",
  DeliveryDays: "DeliveryDays",
  FabricGroupID: "FabricGroupID",
  HSNCode: "HSNCode",
  image: "image",
  IGSTPercent: "IGSTPercent",
  IsInclusive: "IsInclusive",
  isStockCheck: "isStockCheck",
  MaxOrderQty: "MaxOrderQty",
  MinStock: "MinStock",
  PackingPrice: "PackingPrice",
  ReadyForDeliveryDay: "ReadyForDeliveryDay",
  SellingPrice: "SellingPrice",
  SGSTPercent: "SGSTPercent",
  ShippingPrice: "ShippingPrice",
  srcSet: "srcSet",
  StockQty: "StockQty",
};

export const SwatchGroupFields = {
  ...CommonFields,
};
