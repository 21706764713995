import { API_ENDPOINTS } from "../Utils/Constants";
import CouponCreate from "./CouponCreate";
import CouponEdit from "./CouponEdit";
import CouponsList from "./CouponsList";
import RedeemIcon from "@material-ui/icons/Redeem";
const DiscountCoupons = {
  name: API_ENDPOINTS.Coupons,
  create: CouponCreate,
  list: CouponsList,
  edit: CouponEdit,
  icon: RedeemIcon,
  options: { label: "Coupons" },
};

export default DiscountCoupons;
