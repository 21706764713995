import { API_ENDPOINTS, SkipFromMenu } from "../../Utils/Constants";
import TopSlideCreate from "./TopSlideCreate";
import TopSlideEdit from "./TopSlideEdit";
import TopSlidesList from "./TopSlidesList";

const TopSlides = {
  name: API_ENDPOINTS.TopSlides,
  list: TopSlidesList,
  create: TopSlideCreate,
  edit: TopSlideEdit,
  options: { label: "TopSlides", [SkipFromMenu]: true },
};

export default TopSlides;
