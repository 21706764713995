import React from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from "react-admin";
import { SwatchFields, SwatchGroupFields } from "../../Utils/Constants";

const SwatchGroupList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: SwatchFields.SortOrder, order: "ASC" }}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid>
        <EditButton />
        <TextField source={SwatchGroupFields.Name} />
        <TextField source={SwatchGroupFields.DisplayName} />
        <TextField source={SwatchGroupFields.SortOrder} />
        <FunctionField
          source={SwatchGroupFields.Status}
          render={(rec, src) => (
            <BooleanField
              source={src}
              record={{ ...rec, [src]: rec[src] === 1 }}
              valueLabelTrue="Active"
              valueLabelFalse="InActive"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default SwatchGroupList;
