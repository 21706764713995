import React from "react";
import withWidth from "@material-ui/core/withWidth";
import { Pagination } from "react-admin";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const getColsForWidth = (width) => {
  if (width === "xs") return [4, 8, 12, 96];
  if (width === "sm") return [6, 12, 24, 96];
  if (width === "md") return [8, 16, 32, 96];
  if (width === "lg" || width === "xl") return [12, 24, 48, 96];
};
// const getPerPage = (width) => {
//   if (width === "xs") return 12;
//   if (width === "sm") return 24;
//   if (width === "md") return 32;
//   if (width === "lg") return 48;
// };
export const ImageRowPerPage = () => {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("lg"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  if (isXs) return 8;
  if (isSm) return 12;
  if (isMd) return 16;
  if (isLg || isXl) {
    //console.log("returning 24");
    return 24;
  }
  return 10;
};
//<PaginationActions rowsPerPage={getPerPage(width)} {...props} />
const ImageGridPagination = ({ width, ...props }) => {
  return <Pagination {...props} rowsPerPageOptions={getColsForWidth(width)} />;
};

export default withWidth()(ImageGridPagination);
