export const CategoryFields = {
  ChartColor: "ChartColor",
  DisplayColumn: "DisplayColumn",
  DisplayName: "DisplayName",
  Name: "Name",
  SortOrder: "SortOrder",
  Status: "Status",
  ID: "id",
  image: "image",
  srcSet: "srcSet",
};
