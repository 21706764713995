import React from "react";
import { SelectInput } from "react-admin";
import { useForm } from "react-final-form";
import { TopSlide_Url_Const } from "../../Utils/Constants";
import { TopSlidesFields } from "../../Utils/Constants/TopSlidesFields";

const TopSlideSelectType = ({ record = null, ...rest }) => {
  const { change } = useForm();
  return (
    <SelectInput
      record={record}
      source={TopSlidesFields.Type}
      choices={TopSlide_Url_Const}
      onChange={({ target }) => {
        if (record) {
          change(
            TopSlidesFields.TypeId,
            Number(record[TopSlidesFields.Type]) === Number(target.value)
              ? Number(record[TopSlidesFields.TypeId])
              : 0
          );
        } else {
          change(TopSlidesFields.TypeId, 0);
        }
      }}
      variant="outlined"
      label="Type"
      fullWidth
      {...rest}
    />
  );
};

export default TopSlideSelectType;
