import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  required,
} from "react-admin";

const name = [required(), minLength(1), maxLength(50)];
const diplayName = [...name];
const sortOrder = [required(), number(), minValue(1)];
const displayColumn = [required(), number(), minValue(1), maxValue(2)];
const image = [required()];
export const CollectionValidations = {
  displayColumn,
  diplayName,
  image,
  name,
  sortOrder,
};
