export const ItemGroupFields = {
  ID: "id",
  CategoryID: "CategoryID",
  CollectionID: "CollectionID",
  CGSTPercent: "CGSTPercent",
  Code: "Code",
  CompanyID: "CompanyID",
  DeliveryDays: "DeliveryDays",
  Dimensions: "Dimensions",
  Description: "Description",
  DisplayName: "DisplayName",
  FabricGroupID: "FabricGroupID",
  FillID: "FillID",
  HSN: "HSN",
  HSNCode: "HSNCode",
  IGSTPercent: "IGSTPercent",
  IsInclusive: "IsInclusive",
  MakingDays: "MakingDays",
  ReadyForDeliveryDay: "ReadyForDeliveryDay",
  MaxOrderQty: "MaxOrderQty",
  MinStock: "MinStock",
  Name: "Name",
  ProductType: "ProductType",
  SellingPrice: "SellingPrice",
  SGSTPercent: "SGSTPercent",
  SizeID: "SizeID",
  SortOrder: "SortOrder",
  Specifications: "Specifications",
  StartingPrice: "StartingPrice",
  Status: "Status",
  StockQty: "StockQty",
  Variants: "variants",
};
