import React from "react";
import { MenuItemLink } from "react-admin";
import { useLocation } from "react-router-dom";
import { API_ENDPOINTS, MENU_LOCATIONS } from "../Utils/Constants";
import SubMenu from "./SubMenu";
const ProductsMenu = ({
  handleToggle,
  isOpen,
  sidebarIsOpen,
  name,
  icon,
  onMenuClick,
  classes,
}) => {
  const location = useLocation();
  const loc = location.pathname.split("/", 2);
  return (
    <SubMenu
      handleToggle={handleToggle}
      isOpen={isOpen}
      sidebarIsOpen={sidebarIsOpen}
      name={name}
      icon={icon}
      active={loc.length === 2 && loc[1] === MENU_LOCATIONS.Products}
    >
      <MenuItemLink
        to={`/${API_ENDPOINTS.Categories}`}
        primaryText="Categories"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
      />
      <MenuItemLink
        to={`/${API_ENDPOINTS.Collections}`}
        primaryText="Collections"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
      />
      <MenuItemLink
        to={`/${API_ENDPOINTS.Items}`}
        primaryText="Items"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
      />
    </SubMenu>
  );
};

export default ProductsMenu;
