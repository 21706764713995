import { maxLength, minLength, required } from "react-admin";

const headline = [required(), minLength(2), maxLength(500)];
const type = [required()];
const typeId = [required()];
const buttonLabel = [required(), maxLength(10)];
const image = [required()];
export const TopSlideValidations = {
  buttonLabel,
  headline,
  image,
  type,
  typeId,
};
