import { BooleanField } from "react-admin";

const BooleanFieldYN = ({
  record = {},
  source,
  label,
  sortable,
  condtion = "y",
}) => {
  const changeToBool = { ...record };
  changeToBool[source] =
    `${record[source]}`.toLowerCase() === `${condtion}`.toLowerCase();
  return (
    <BooleanField
      record={changeToBool}
      source={source}
      label={label}
      sortable={sortable}
    />
  );
};
export default BooleanFieldYN;
