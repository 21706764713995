import React from "react";
import { MenuItemLink } from "react-admin";
import { useLocation } from "react-router-dom";
import { API_ENDPOINTS, MENU_LOCATIONS } from "../Utils/Constants";
import SubMenu from "./SubMenu";

const HomePageMenu = ({
  handleToggle,
  isOpen,
  sidebarIsOpen,
  name,
  icon,
  onMenuClick,
  classes,
}) => {
  const location = useLocation();
  const loc = location.pathname.split("/", 2);
  return (
    <SubMenu
      handleToggle={handleToggle}
      isOpen={isOpen}
      sidebarIsOpen={sidebarIsOpen}
      name={name}
      icon={icon}
      active={loc.length === 2 && loc[1] === MENU_LOCATIONS.HomePage}
    >
      <MenuItemLink
        to={`/${API_ENDPOINTS.TopSlides}`}
        primaryText="TopSlides"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
      />
    </SubMenu>
  );
};

export default HomePageMenu;
