import { API_ENDPOINTS, SkipFromMenu } from "../Utils/Constants";
import SwatchCreate from "./SwatchCreate";
import SwatchEdit from "./SwatchEdit";
import SwatchList from "./SwatchList";

const Swatches = {
  name: API_ENDPOINTS.Swatches,
  list: SwatchList,
  create: SwatchCreate,
  edit: SwatchEdit,
  options: { [SkipFromMenu]: true },
};
export default Swatches;
