export const ReportsFileds = {
  CategoryID: "CategoryID",
  CategoryDisplayName: "CategoryDisplayName",
  CollectionID: "CollectionID",
  CollectionDisplayName: "CollectionDisplayName",
  ItemID: "ItemID",
  ItemName: "ItemName",
  Sales: "Sales",
  Revenue: "Revenue",
  Year: "Year",
  Color: "ChartColor",
  TotalOrders: "TotalOrders",
  TotalSales: "TotalSales",
  TotalRevenue: "TotalRevenue",
  TotalUsers: "TotalUsers",
  Categories: "Categories",
  Collections: "Collections",
  Month: "Month",
  Week: "Week",
  Day: "Day",
  Data: "Data",
  Report: "Report",
};
