import React, { useRef, useState } from "react";
const responsiveImage = (imageRef) => {
  var size =
    Math.ceil(
      (imageRef.getBoundingClientRect().width / window.innerWidth) * 100
    ) + "vw";
  console.log(
    "🚀 ~ file: ImageResponsive.js ~ line 10 ~ responsiveImage ~ size",
    size
  );
  return size;
};
const ImageResponsive = ({
  src,
  srcSet = null,
  alt = "",
  maxSize = null,
  ...rest
}) => {
  const imgRef = useRef(null);
  const [sizes, setSizes] = useState("1px");
  return (
    <img
      {...rest}
      ref={imgRef}
      src={src}
      srcSet={srcSet ?? ""}
      sizes={sizes}
      alt={alt}
      onLoad={() => {
        if (imgRef.current && srcSet) {
          if (maxSize) {
            setSizes(maxSize);
          } else setSizes(responsiveImage(imgRef.current));
        }
      }}
    />
  );
};

export default ImageResponsive;
