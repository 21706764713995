import React from "react";
import { CreateButton, ListButton, TopToolbar } from "react-admin";

const EditTopActions = ({ basePath, showCreate = true }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
      {showCreate ? <CreateButton /> : null}
    </TopToolbar>
  );
};

export default EditTopActions;
