import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelectStyle } from "../styles";
const SelectBox = (props) => {
  const {
    value,
    onChange,
    renderValue,
    disableUnderline,
    IconComponent,
    MenuProps,
    children,
    ...rest
  } = props;
  const selectClasses = useSelectStyle();
  const menuProps = {
    classes: {
      paper: selectClasses.paper,
      list: selectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon className={`${props.className} ${selectClasses.icon}`} />
    );
  };
  return (
    <Select
      disableUnderline
      value={value}
      onChange={onChange}
      renderValue={renderValue}
      IconComponent={iconComponent}
      MenuProps={menuProps}
      classes={{ select: selectClasses.select }}
      {...rest}
    >
      {children}
    </Select>
  );
};
SelectBox.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  renderValue: PropTypes.func,
};
export default SelectBox;
