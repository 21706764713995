import React from "react";
import Avatar from "@material-ui/core/Avatar";
const AvatarField = ({ src = "", srcSet = "", className }) => {
  return (
    <Avatar
      src={src}
      srcSet={srcSet}
      style={{ width: 25, height: 25 }}
      className={className}
    />
  );
};

export default AvatarField;
