import { maxLength, minLength, regex, required } from "react-admin";

//Use For Field Validation Problem Calls every time on other fileds also
// const checkIsUniquePincode = async (value, mode) => {
//   const url = `${API_ENDPOINTS.PinceCodesFieldValidation}`;
//   const formData = new FormData();
//   formData.append(PincodeFields.Mode, mode);
//   formData.append(PincodeFields.Pincode, value);
//   const res = await httpClient(url, { method: "POST", body: formData });
//   const { status } = res.json;
//   if (status.ErrorCode === "00") return undefined;
//   else if (status.ErrorCode === "01") {
//     console.log(status.ErrorDescription);
//     if (
//       status.ErrorDescription &&
//       status.ErrorDescription[PincodeFields.Pincode]
//     ) {
//       console.log(status.ErrorDescription[PincodeFields.Pincode], "con");
//       return status.ErrorDescription[PincodeFields.Pincode];
//     } else {
//       return "Invalid Pincode";
//     }
//   } else {
//     return "Some error occured";
//   }
// };

const pinCode = [
  required(),
  regex(/^[1-9]{1}[0-9]{5}$/, "Invalid"),
  //checkIsUniquePincode,
  // async (values) => {
  //   return await checkIsUniquePincode(values, 1);
  // },
];
// const pinCodeEdit = [
//   required(),
//   regex(/^[1-9]{1}[0-9]{5}$/, "Invalid Pincode"),
//   (values) => {
//     checkIsUniquePincode(values, 2);
//   },
// ];
const pinCity = [
  required(),
  minLength(2),
  maxLength(100),
  regex(/^[a-zA-Z]{1}[a-zA-z\s]+$/, "Only letters and spaces allowed"),
];
const pinState = [required()];
export const PincodeValidations = {
  pinCode,
  pinCity,
  pinState,
};
