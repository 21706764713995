export const PaymentFields = {
  ID: "id",
  PaymentID: "PaymentID",
  OrderID: "OrderID",
  PaymentDate: "PaymentDate",
  Status: "Status",
  TrackingID: "TrackingID",
  TransactionDate: "TransactionDate",
  BankRefNo: "BankRefNo",
  FailureMessage: "FailureMessage",
  PaymentMode: "PaymentMode",
  CardName: "CardName",
  TID: "TID",
};
