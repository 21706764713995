import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import {
  commonFunctions,
  ReportHeight,
  ReportsFileds,
} from "../../../Utils/Constants";
import Typography from "@material-ui/core/Typography";
//#region [ Custom Label ]

//#endregion [ Custom Label ]
const SalesPieChart = ({ data, dataKey, reportBy, isCategory = true }) => {
  const pieLabelShape = (props) => {
    //const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      //payload,
      percent,
      value,
    } = props;
    //console.log("🚀 ~ file: index.js ~ line 33 ~ pieLabelShape ~ value", value);
    return (
      <g>
        {/* Not Fitting Long Labels */}
        {/* <text id="demo" x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
          {reportBy === "Category"
            ? payload[ReportsFileds.CategoryDisplayName]
            : payload[ReportsFileds.ItemName]}
        </text> */}
        <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
          {value}
        </text>
        <text x={cx} y={cy} dy={20} textAnchor="middle" fill={fill}>
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 7}
          fill={fill}
        />
      </g>
    );
  };
  //   console.log(
  //     "🚀 ~ file: index.js ~ line 60 ~ SalesPieChart ~ reportBy",
  //     reportBy
  //   );
  //   console.log(
  //     "🚀 ~ file: index.js ~ line 59 ~ SalesPieChart ~ data, categories",
  //     data
  //   );
  const [activeCategory, setActiveCategory] = useState({
    id: 0,
    indx: 0,
    payload: null,
  });
  const [labelText, setLabelText] = useState("");

  useEffect(() => {
    const curData = data[activeCategory.indx];
    // console.log(
    //   "🚀 ~ file: index.js ~ line 89 ~ useEffect ~ curData",
    //   curData,
    //   reportBy,
    //   isCategory
    // );
    if (curData) {
      setLabelText(
        curData[
          reportBy === "Category"
            ? isCategory
              ? ReportsFileds.CategoryDisplayName
              : ReportsFileds.CollectionDisplayName
            : ReportsFileds.ItemName
        ]
      );
    } else {
      setLabelText("");
    }
  }, [activeCategory, data, reportBy, isCategory]);

  if (data && data.length <= 0) {
    return <h1>No Data</h1>;
  }
  return (
    <>
      <ResponsiveContainer width="100%" height={ReportHeight}>
        <PieChart margin={{ top: 50, bottom: 30 }}>
          <Pie
            data={data}
            activeIndex={activeCategory.indx}
            activeShape={pieLabelShape}
            cx="50%"
            cy="50%"
            innerRadius="70%"
            outerRadius="100%"
            dataKey={dataKey}
            label
            labelLine={true}
            fill="black"
            onMouseEnter={(data, indx) => {
              const categoryId = data.payload[ReportsFileds.CategoryID];
              setActiveCategory((prev) => ({
                ...prev,
                id: categoryId,
                indx: indx,
                payload: data.payload,
              }));
              //setLabelText(data.payload[ReportsFileds.CategoryDisplayName]);
            }}
            isAnimationActive={false}
          >
            {reportBy === "Category"
              ? data.map((category) => (
                  <Cell
                    key={category[ReportsFileds.CategoryID]}
                    fill={commonFunctions.shadeColor(
                      category[ReportsFileds.Color],
                      -20
                    )}
                    name={
                      category[
                        isCategory
                          ? ReportsFileds.CategoryDisplayName
                          : ReportsFileds.CollectionDisplayName
                      ]
                    }
                  />
                ))
              : data.map((category) => (
                  <Cell
                    key={category[ReportsFileds.ItemID]}
                    fill={commonFunctions.shadeColor(
                      category[ReportsFileds.Color],
                      -20
                    )}
                    name={category[ReportsFileds.ItemName]}
                  />
                ))}
            {/* {data.map((category) => {
            return reportBy === "Category" ? (
              <Cell
                key={category[ReportsFileds.CategoryID]}
                fill={commonFunctions.shadeColor(
                  category[ReportsFileds.Color],
                  -20
                )}
                name={category[ReportsFileds.CategoryDisplayName]}
              />
            ) : (
              <Cell
                key={category[ReportsFileds.ItemID]}
                fill={commonFunctions.shadeColor(
                  category[ReportsFileds.Color],
                  -20
                )}
                name={category[ReportsFileds.ItemName]}
              />
            );
          })} */}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Typography
        variant="body1"
        style={{ color: "#a5a5a5", fontWeight: "bold" }}
      >
        {labelText}
      </Typography>
    </>
  );
};
SalesPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  reportBy: PropTypes.oneOf(["Category", "Product"]).isRequired,
};
export default SalesPieChart;
