import React from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  SelectField,
  TextField,
} from "react-admin";
import BooleanFieldYN from "../CommonComponents/BooleanFieldYN";
import PercentField from "../CommonComponents/PercentField";
import {
  API_ENDPOINTS,
  CompanyFileds,
  CouponFields,
  CouponTypes,
} from "../Utils/Constants";

const CouponsList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false}>
      <Datagrid rowClick="edit">
        <ReferenceField
          reference={API_ENDPOINTS.Companies}
          source={CouponFields.CompanyID}
          label="Company"
        >
          <TextField source={CompanyFileds.Name} />
        </ReferenceField>
        <TextField source={CouponFields.CouponCode} label="Coupon Code" />
        <TextField
          source={CouponFields.CouponDescription}
          label="Description"
        />
        <SelectField source={CouponFields.CouponType} choices={CouponTypes} />
        <PercentField source={CouponFields.Percentage} label="Percent" />
        <TextField source={CouponFields.TeamName} label="Team Name" />
        <BooleanFieldYN
          source={CouponFields.IsToShow}
          label="ShowToUser"
          condtion="1"
        />
        <BooleanFieldYN source={CouponFields.IsActive} label="IsActive" />
      </Datagrid>
    </List>
  );
};

export default CouponsList;
