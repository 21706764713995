export const OrderFields = {
  ID: "id",
  UserID: "UserID",
  OrderID: "OrderID",
  OrderDate: "OrderDate",
  InvoiceNo: "InvoiceNo",
  InvoiceDate: "InvoiceDate",
  FinalAmount: "FinalAmount",
  ContactPerson: "ContactPerson",
  ContactMobile: "ContactMobile",
  ShippinAddress: "ShippingAddress",
  ShippingAddress1: "ShippingAddress1",
  ShippingAddress2: "ShippingAddress2",
  ShippingCity: "ShippingCity",
  ShippingStateCode: "ShippingStateCode",
  ShippingState: "ShippingState",
  ShippingPincode: "ShippingPincode",
  TotalItems: "TotalItems",
  Status: "Status",
  LineItems: "LineItems",
  PaymentDetails: "PaymentDetails",
  Editable: "Editable",
};
