import React from "react";
import { Datagrid, List, NumberField, TextField } from "react-admin";
import PercentField from "../CommonComponents/PercentField";
import { CompanyFileds } from "../Utils/Constants";
import CompanyExpaneded from "./CompanyExpaneded";

const CompaniesList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false}>
      <Datagrid expand={<CompanyExpaneded />}>
        <TextField source={CompanyFileds.Name} label="Name" />
        <NumberField
          source={CompanyFileds.RewardPoints}
          label="Reward Earning"
        />
        <PercentField
          source={CompanyFileds.RewardRedeemPercent}
          label="Usable Rewards"
        />
      </Datagrid>
    </List>
  );
};

export default CompaniesList;
