import {
  authUrl,
  AUTH_ENDPOINTS,
  AUTH_KEY,
  USER_NAME_KEY,
} from "../Utils/Constants";

const AuthProvider = {
  login: ({ username, password }) => {
    const formData = new FormData();
    formData.append("UserID", username);
    formData.append("Password", password);
    const headers = new Headers();
    headers.append("Accept", "application/json");
    const request = new Request(`${authUrl}/${AUTH_ENDPOINTS.LOGIN}`, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    return fetch(request)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.text();
      })
      .then((token) => {
        const user = {
          id: username,
          fullName: username.toUpperCase(),
        };
        localStorage.setItem(USER_NAME_KEY, JSON.stringify(user));
        localStorage.setItem(AUTH_KEY, token);
      })
      .catch(({ message }) => {
        //console.log("LoginErr", error);
        throw new Error(message ? message : "Network Error");
      });
  },
  checkError: (error) => {
    //console.log("🚀 ~ file: index.js ~ line 28 ~ error", error);
    if (error.status && error.status === 401) {
      localStorage.removeItem(AUTH_KEY);
      return Promise.reject({ message: false });
    }
    return Promise.resolve();
  },
  checkAuth: (params) => {
    //console.log("🚀 ~ file: index.js ~ line 33 ~ params", params);
    return localStorage.getItem(AUTH_KEY)
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem(USER_NAME_KEY);
    return Promise.resolve();
  },
  getIdentity: () => {
    const profile = localStorage.getItem(USER_NAME_KEY);
    if (profile) {
      const data = JSON.parse(profile);
      return Promise.resolve(data);
    }
    return Promise.resolve();
  },
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

export default AuthProvider;
