import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BooleanInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import {
  CollectionFields,
  DisplayColumnsList,
  ImageUploadField,
} from "../Utils/Constants";
import ImageResponsiveComponent from "../CommonComponents/ImageResponsiveComponent";
import { CollectionValidations } from "./CollectionValidation";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "200px",
    [theme.breakpoints.up(599)]: {
      width: "100%",
      maxWidth: "300px",
    },
  },
}));

//className is used in props to remove it from props as its causing issue with input to go full width
const CollectionCommonInputs = ({ isEdit = false, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {!isEdit ? null : (
        <Grid item xs={12}>
          <FormDataConsumer>
            {({ formData }) => {
              const imgPath = formData[CollectionFields.image] ?? null;
              return (
                <ImageResponsiveComponent
                  className={classes.root}
                  src={imgPath}
                  srcset={formData[CollectionFields.srcSet]}
                  alt="No Image"
                  maxSize="100px"
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextInput
          {...rest}
          source={CollectionFields.Name}
          fullWidth
          validate={CollectionValidations.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          {...rest}
          source={CollectionFields.DisplayName}
          fullWidth
          validate={CollectionValidations.diplayName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          {...rest}
          source={CollectionFields.SortOrder}
          fullWidth
          validate={CollectionValidations.sortOrder}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          {...rest}
          source={CollectionFields.DisplayColumn}
          choices={DisplayColumnsList}
          fullWidth
          validate={CollectionValidations.displayColumn}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput
          label="Is Active"
          source={CollectionFields.Status}
          format={(v) => v === 1}
          parse={(v) => (v ? 1 : 0)}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          {...rest}
          label="Category Image"
          source={ImageUploadField}
          multiple={false}
          accept="image/jpeg"
          validate={isEdit ? [] : CollectionValidations.image}
        >
          <ImageField {...rest} />
        </ImageInput>
      </Grid>
    </Grid>
  );
};

export default CollectionCommonInputs;
