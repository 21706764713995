import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import {
  API_ENDPOINTS,
  CommonFields,
  ItemGroupFields,
} from "../Utils/Constants";
import { useBooleanInputStyle } from "../styles";
import {
  categoryValidation,
  companyValidation,
  descrtptionValidation,
  displayNameValidation,
  nameValidation,
  sortOrderValidation,
  specificationValitdation,
  statusValidation,
} from "./ItemValidations";
import { useFormState } from "react-final-form";
import { useEffect } from "react";
import { TabLabels } from "./TabsLabels";
const InfoTab = ({ setErrors }) => {
  const style = useBooleanInputStyle();
  const { errors, submitErrors } = useFormState();
  useEffect(() => {
    const curError = submitErrors ?? errors;
    // console.log(
    //   "🚀 ~ file: InfoTab.js ~ line 32 ~ useEffect ~ curError",
    //   curError,
    //   touched
    // );
    if (
      curError &&
      (curError[ItemGroupFields.Name] ||
        curError[ItemGroupFields.CompanyID] ||
        curError[ItemGroupFields.DisplayName] ||
        curError[ItemGroupFields.Specifications] ||
        curError[ItemGroupFields.CategoryID] ||
        curError[ItemGroupFields.CollectionID] ||
        curError[ItemGroupFields.Status] ||
        curError[ItemGroupFields.SortOrder])
    ) {
      if (setErrors)
        setErrors((prev) => ({ ...prev, [TabLabels.InfoLabel]: true }));
    } else {
      if (setErrors)
        setErrors((prev) => ({ ...prev, [TabLabels.InfoLabel]: false }));
    }
  }, [errors, submitErrors, setErrors]);
  return (
    <>
      <ReferenceInput
        source={ItemGroupFields.CompanyID}
        reference={API_ENDPOINTS.Companies}
        label="Company"
        variant="outlined"
        fullWidth
        allowEmpty={false}
        perPage={null}
        sort={{ field: CommonFields.Name, order: "ASC" }}
        resettable
        validate={companyValidation}
      >
        <SelectInput optionText="Name" />
      </ReferenceInput>
      <TextInput
        source={ItemGroupFields.Name}
        variant="outlined"
        fullWidth
        validate={nameValidation}
      />
      <TextInput
        source={ItemGroupFields.DisplayName}
        variant="outlined"
        fullWidth
        validate={displayNameValidation}
      />
      <TextInput
        source={ItemGroupFields.Description}
        variant="outlined"
        multiline
        fullWidth
        validate={descrtptionValidation}
      />
      <Typography variant="body2">Use * for line break</Typography>
      <TextInput
        source={ItemGroupFields.Specifications}
        variant="outlined"
        multiline
        fullWidth
        validate={specificationValitdation}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ReferenceInput
            source={ItemGroupFields.CategoryID}
            reference={API_ENDPOINTS.Categories}
            label="Category"
            variant="outlined"
            fullWidth
            allowEmpty={false}
            perPage={null}
            sort={{ field: CommonFields.Name, order: "ASC" }}
            resettable
            validate={categoryValidation}
          >
            <SelectInput optionText="Name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source={ItemGroupFields.CollectionID}
            reference={API_ENDPOINTS.Collections}
            label="Collection"
            variant="outlined"
            fullWidth
            allowEmpty={false}
            perPage={null}
            sort={{ field: CommonFields.Name, order: "ASC" }}
            resettable
          >
            <SelectInput optionText="Name" />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <BooleanInput
            source={ItemGroupFields.Status}
            label="Is Active"
            variant="outlined"
            format={(v) => v === 1}
            parse={(v) => (v ? "1" : "0")}
            fullWidth
            classes={{ root: style.root }}
            validate={statusValidation}
          />
        </Grid>
        <Grid item>
          <NumberInput
            source={ItemGroupFields.SortOrder}
            label="Sort Order"
            variant="outlined"
            fullWidth
            validate={sortOrderValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InfoTab;
