import MaterialTable from "@material-table/core";
import React, { useCallback } from "react";
import {
  API_ENDPOINTS,
  LineItemFields,
  OrderFields,
  OrderStatusConst,
  OrderGridStatusConst,
  commonFunctions,
} from "../Utils/Constants";
import { useDataProvider, useNotify } from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
const OrderItems = ({ items = [], isSaving, setIsSaving, record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const isLoading = useSelector((state) => state.admin.loading);

  const selectComponent = useCallback((props) => {
    return (
      <Select
        value={props.value}
        variant="outlined"
        onChange={(e) => props.onChange(e.target.value)}
      >
        {OrderStatusConst.map((item) => (
          <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    );
  }, []);
  const vaildateTrackingNo = useCallback((rowData) => {
    const status = rowData[LineItemFields.Status];
    const trackingNo = rowData[LineItemFields.TrackingNo];
    const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;
    return retVal;
  }, []);
  const vaildateTrackingUrl = useCallback((rowData) => {
    const status = rowData[LineItemFields.Status];
    const trackingNo = rowData[LineItemFields.TrackingUrl];
    const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;
    return retVal;
  }, []);
  const columns = [
    {
      title: "ID",
      field: LineItemFields.ID,
      hidden: true,
    },
    {
      title: "Item",
      field: LineItemFields.Item,
      editable: "never",
    },
    {
      title: "Fabric",
      field: LineItemFields.Fabric,
      editable: "never",
    },
    {
      title: "Qty",
      field: LineItemFields.Qty,
      editable: "never",
    },
    {
      title: "Rate",
      field: LineItemFields.Rate,
      type: "currency",
      currencySetting: { currencyCode: "INR" },
      editable: "never",
    },
    {
      title: "Amount",
      field: LineItemFields.Amount,
      type: "currency",
      currencySetting: { currencyCode: "INR" },
      editable: "never",
    },
    {
      title: "Status",
      field: LineItemFields.Status,
      lookup: OrderGridStatusConst,
      editComponent: selectComponent,
      // editComponent: (props) => (
      //   <Select
      //     value={props.value}
      //     variant="outlined"
      //     onChange={(e) => props.onChange(e.target.value)}
      //   >
      //     {OrderStatusConst.map((item) => (
      //       <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
      //         {item.name}
      //       </MenuItem>
      //     ))}
      //   </Select>
      // ),
    },
    {
      title: "Tracking",
      field: LineItemFields.TrackingNo,
      validate: vaildateTrackingNo,
      // validate: (rowData) => {
      //   const status = rowData[LineItemFields.Status];
      //   const trackingNo = rowData[LineItemFields.TrackingNo];
      //   const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;
      //   return retVal;
      // },
    },
    {
      title: "TrackingUrl",
      field: LineItemFields.TrackingUrl,
      validate: vaildateTrackingUrl,
      // validate: (rowData) => {
      //   const status = rowData[LineItemFields.Status];
      //   const trackingNo = rowData[LineItemFields.TrackingUrl];
      //   const retVal = Number(status) === 11 ? trackingNo.trim() !== "" : true;
      //   return retVal;
      // },
    },
  ];
  const onUpdateBulk = async (changes) => {
    const vals = Object.values(changes);
    if (vals.length > 0) {
      try {
        const data = vals.map((ele) => ele.newData);
        setIsSaving(true);
        let isValid = commonFunctions.validateShipping(
          data,
          LineItemFields.Status,
          LineItemFields.TrackingNo
        );
        if (!isValid) {
          throw new Error(
            "Provide trackingno for products with shipped status"
          );
        }
        isValid = true;
        isValid = commonFunctions.validateShipping(
          data,
          LineItemFields.Status,
          LineItemFields.TrackingUrl
        );
        if (!isValid) {
          throw new Error(
            "Provide tracking url for products with shipped status"
          );
        }
        await dataProvider.updateMany(
          `${API_ENDPOINTS.Orders}/${API_ENDPOINTS.LineItems}`,
          {
            ids: record[OrderFields.ID],
            data: data,
          }
        );
        await dataProvider.getOne(API_ENDPOINTS.Orders, {
          id: record[OrderFields.ID],
        });
        setIsSaving(false);
        notify(`Order ${record[OrderFields.OrderID]} Updated`, "info");
        return Promise.resolve();
      } catch (error) {
        setIsSaving(false);
        notify(error.message ? error.message : error, "error");
        return Promise.reject();
      }
    } else return Promise.resolve();
  };
  const onUpdateRow = async (newdata, olddata) => {
    //console.log(newdata, olddata);

    try {
      const data = [];
      data.push(newdata);
      setIsSaving(true);
      let isValid = commonFunctions.validateShipping(
        data,
        LineItemFields.Status,
        LineItemFields.TrackingNo
      );
      if (!isValid) {
        throw new Error("Provide trackingno for products with shipped status");
      }
      isValid = true;
      isValid = commonFunctions.validateShipping(
        data,
        LineItemFields.Status,
        LineItemFields.TrackingUrl
      );
      if (!isValid) {
        throw new Error(
          "Provide tracking url for products with shipped status"
        );
      }
      await dataProvider.updateMany(
        `${API_ENDPOINTS.Orders}/${API_ENDPOINTS.LineItems}`,
        {
          ids: record[OrderFields.ID],
          data: data,
        }
      );
      await dataProvider.getOne(API_ENDPOINTS.Orders, {
        id: record[OrderFields.ID],
      });
      setIsSaving(false);
      notify(`Order ${record[OrderFields.OrderID]} Updated`, "info");
      return Promise.resolve();
    } catch (error) {
      setIsSaving(false);
      notify(error.message ? error.message : error, "error");
      return Promise.reject();
    }
    //return Promise.resolve();
  };
  return (
    <MaterialTable
      columns={columns}
      data={items}
      options={{
        search: false,
        sorting: false,
        pageSize: 5,
        pageSizeOptions: [5, 10],
        idSynonym: LineItemFields.ID,
      }}
      editable={{
        onBulkUpdate: record[OrderFields.Editable] ? onUpdateBulk : null,
        onRowUpdate: record[OrderFields.Editable] ? onUpdateRow : null,
      }}
      isLoading={isSaving || isLoading !== 0}
      title="Ordered Items"
    />
  );
};

export default OrderItems;
