import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ItemGroupFields } from "../Utils/Constants";
import { NumberInput, SelectInput, TextInput } from "react-admin";
import {
  dimensionValitdation,
  itemCodeValidation,
  nameValidation,
  priceValidation,
  stockValidation,
} from "./ItemValidations";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => {
  // console.log(
  //   "🚀 ~ file: VarriantIttreator.js ~ line 31 ~ useStyles ~ theme",
  //   theme
  // );

  return {
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    focused: {
      backgroundColor: "inherit",
    },
    error: ({ error = null }) => {
      // console.log(
      //   "🚀 ~ file: VarriantIttreator.js ~ line 48 ~ useStyles ~ props",
      //   error,
      //   theme
      // );
      return {
        backgroundColor: error
          ? theme.palette.error.light
          : theme.palette.background.paper,
        color: error ? theme.palette.error.contrastText : "inherit",
      };
    },
  };
});
const VarriantIttreator = (props) => {
  const [isError, setIsError] = useState(false);
  const classes = useStyles({ error: isError });
  // console.log(
  //   "🚀 ~ file: VarriantIttreator.js ~ line 50 ~ VarriantIttreator ~ isError",
  //   isError
  // );
  const handleChange = (source) => (event, isExpanded) => {
    // console.log(
    //   "🚀 ~ file: VarriantIttreator.js ~ line 47 ~ handleChange ~ event,isExpanded",
    //   event,
    //   isExpanded
    // );
    // console.log(
    //   "🚀 ~ file: VarriantIttreator.js ~ line 47 ~ handleChange ~ source",
    //   source
    // );

    if (props.setExpanded != null) {
      props.setExpanded(isExpanded ? source : false);
    }
  };
  const variantIndx = props.source
    ? Number(
        props.source.substring(
          props.source.indexOf("[") + 1,
          props.source.lastIndexOf("]")
        )
      )
    : 0;

  useEffect(() => {
    if (Array.isArray(props.errors) && props.errors[variantIndx]) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [props.errors, variantIndx]);
  return (
    <div className={classes.root}>
      <Accordion
        expanded={props.expanded === props.source}
        onChange={handleChange(props.source)}
      >
        <AccordionSummary
          id={props.record.Code ?? props.source}
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.error }}
        >
          <Typography className={classes.heading}>
            {props.record.Code
              ? `${props.record.Name ?? ""} (${props.record.Code ?? ""})`
              : "New Variant"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput
                source={`${props.source}.${ItemGroupFields.Code}`}
                label="Code"
                variant="outlined"
                fullWidth
                validate={itemCodeValidation(variantIndx)}
              />
            </Grid>
            <Grid item xs={9}>
              <TextInput
                source={`${props.source}.${ItemGroupFields.Name}`}
                label="Name"
                variant="outlined"
                fullWidth
                validate={nameValidation}
              />
            </Grid>

            <Grid item xs={3}>
              {/* <ReferenceInput
                source={`${props.source}.${ItemGroupFields.SizeID}`}
                reference={API_ENDPOINTS.ItemSizes}
                label="Size"
                variant="outlined"
                fullWidth
                allowEmpty={false}
                perPage={null}
                sort={{ field: CommonFields.Name, order: "ASC" }}
                resettable
              >
                <SelectInput optionText="Name" />
              </ReferenceInput> */}
              <SelectInput
                source={`${props.source}.${ItemGroupFields.SizeID}`}
                label="Size"
                choices={props.sizeData}
                optionText="Name"
                loading={props.sizeLoading}
                variant="outlined"
                allowEmpty={false}
                fullWidth
                resettable
              />
            </Grid>

            <Grid item xs={3}>
              {/* <ReferenceInput
                source={`${props.source}.${ItemGroupFields.FillID}`}
                reference={API_ENDPOINTS.ItemFills}
                label="Fill"
                variant="outlined"
                fullWidth
                allowEmpty={false}
                perPage={null}
                sort={{ field: CommonFields.Name, order: "ASC" }}
                resettable
              >
                <SelectInput optionText="Name" />
              </ReferenceInput> */}
              <SelectInput
                source={`${props.source}.${ItemGroupFields.FillID}`}
                choices={props.fillData}
                optionText="Name"
                label="Fill"
                variant="outlined"
                allowEmpty={false}
                loading={props.fillLoading}
                resettable
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              {/* <ReferenceInput
                source={`${props.source}.${ItemGroupFields.FabricGroupID}`}
                reference={API_ENDPOINTS.SwatchesGroup}
                label="Fabric"
                variant="outlined"
                fullWidth
                allowEmpty={false}
                perPage={null}
                sort={{ field: CommonFields.SortOrder, order: "ASC" }}
                resettable
              >
                <SelectInput optionText="Name" />
              </ReferenceInput> */}
              <SelectInput
                source={`${props.source}.${ItemGroupFields.FabricGroupID}`}
                label="Fabric"
                choices={props.fabricData}
                loading={props.fabricLoading}
                optionText="Name"
                variant="outlined"
                allowEmpty={false}
                fullWidth
                resettable
              />
            </Grid>

            <Grid item xs={3}>
              <NumberInput
                source={`${props.source}.${ItemGroupFields.SellingPrice}`}
                label="Selling Price"
                variant="outlined"
                fullWidth
                validate={priceValidation}
              />
            </Grid>
            <Grid item xs={3}>
              <NumberInput
                source={`${props.source}.${ItemGroupFields.StockQty}`}
                label="Stock Qty"
                variant="outlined"
                fullWidth
                validate={stockValidation}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Use * for line break</Typography>
              <TextInput
                source={`${props.source}.${ItemGroupFields.Dimensions}`}
                label="Dimensions"
                variant="outlined"
                multiline={true}
                fullWidth
                validate={dimensionValitdation}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default VarriantIttreator;
