import { API_ENDPOINTS } from "../Utils/Constants";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import PersonIcon from "@material-ui/icons/Person";
const User = {
  name: API_ENDPOINTS.Users,
  list: UserList,
  edit: UserEdit,
  icon: PersonIcon,
  options: { label: "Users" },
};
export default User;
