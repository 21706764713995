import React from "react";
import { Edit, EmailField, SimpleForm, TextField } from "react-admin";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import TitleComponent from "../CommonComponents/TitleComponent";
import { UserFields } from "../Utils/Constants";

const UserEdit = (props) => {
  return (
    <Edit
      {...props}
      title={<TitleComponent title="User : " source={UserFields.FullName} />}
    >
      <SimpleForm toolbar={<SaveToolBar />}>
        <TextField label="UserId" source={UserFields.UserID} />
        <TextField label="FirstName" source={UserFields.FirstName} />
        <TextField label="LastName" source={UserFields.LastName} />
        <EmailField label="Email" source={UserFields.EmailId} />
        <TextField label="Mobile" source={UserFields.MobileNo} />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
