import React from "react";
import { Grid } from "@material-ui/core";
import { BooleanInput, NumberInput, TextInput } from "react-admin";
import { SwatchGroupFields } from "../../Utils/Constants";
import { SwatchValidation } from "../SwatchValidation";

//className is used in props to remove it from props as its causing issue with input to go full width
const SwatchGroupCommonInputs = ({ className, ...props }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextInput
          {...props}
          source={SwatchGroupFields.Name}
          validate={SwatchValidation.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          {...props}
          source={SwatchGroupFields.DisplayName}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          {...props}
          source={SwatchGroupFields.Description}
          validate={SwatchValidation.descrtiption}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          {...props}
          source={SwatchGroupFields.SortOrder}
          validate={SwatchValidation.sortOrder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BooleanInput
          {...props}
          label="Is Active"
          source={SwatchGroupFields.Status}
          format={(v) => v === 1}
          parse={(v) => (v ? 1 : 0)}
        />
      </Grid>
    </Grid>
  );
};

export default SwatchGroupCommonInputs;
