import makeStyles from "@material-ui/core/styles/makeStyles";
export const useBooleanInputStyle = makeStyles({
  root: {
    "& .MuiFormControlLabel-root": {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      marginLeft: "0px",
    },
  },
});
