export const activeMenuBorderColor = "#ff8f2e";
export const theme = {
  palette: {
    primary: {
      main: "#616161",
      light: "#8e8e8e",
      dark: "#373737",
    },
    secondary: {
      main: "#616161",
      light: "#8e8e8e",
      dark: "#373737",
    },
  },
  overrides: {
    RaMenuItemLink: {
      active: {
        borderLeft: `5px solid ${activeMenuBorderColor}`,
      },
      root: {
        borderLeft: "5px solid #FAFAFA", // invisible menu when not active, to avoid scrolling the text when selecting the menu
      },
    },
  },
};
