import React from "react";
import { Create, SimpleForm, useNotify, useRedirect } from "react-admin";
import EditTopActions from "../../CommonComponents/EditTopActions";
import SaveToolBar from "../../CommonComponents/SaveToolBar";
import { API_ENDPOINTS } from "../../Utils/Constants";
import { TopSlidesFields } from "../../Utils/Constants/TopSlidesFields";
import TopSlideCommonInputs from "./TopSlideCommonInputs";

const TopSlideCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    // console.log(
    //   "🚀 ~ file: TopSlideCreate.js ~ line 24 ~ onSuccess ~ record",
    //   data
    // );
    if (data)
      notify(`Banner [ ${data[TopSlidesFields.Headline]}] Created`, "success");
    else notify("Banner Created");
    redirect(`/${API_ENDPOINTS.TopSlides}`);
  };

  return (
    <Create
      {...props}
      title="Create Top Banner"
      actions={<EditTopActions showCreate={false} />}
      onSuccess={onSuccess}
    >
      <SimpleForm
        toolbar={<SaveToolBar />}
        mutationMode="pessimistic"
        initialValues={{
          [TopSlidesFields.LinkTitle]: "Shop Now",
          [TopSlidesFields.Type]: 1,
        }}
        variant="outlined"
      >
        <TopSlideCommonInputs />
      </SimpleForm>
    </Create>
  );
};

export default TopSlideCreate;
