import React, { useCallback, useEffect, useState } from "react";
//import CardContent from "@material-ui/core/CardContent";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import useTheme from "@material-ui/core/styles/useTheme";
import { useDataProvider } from "react-admin";
import { commonFunctions, Months, ReportsFileds } from "../Utils/Constants";

import {
  getTillDataReportSummary,
  //mockData,
  getTotalSumByKey,
  groupByMonth,
  groupByWeek,
  groupByDay,
} from "./DashboardFunctions";
import TillDateSummary from "./TillDateSummary";
import ReportsCard from "./ReportsCard";
import SalesReport from "./SalesReport";
const Dashboard = () => {
  //let currentBar = "";
  //const theme = useTheme();
  const dataProvider = useDataProvider();
  const [tillDateReport, setTillDateReport] = useState({
    [ReportsFileds.TotalOrders]: 0,
    [ReportsFileds.TotalSales]: 0,
    [ReportsFileds.TotalRevenue]: 0,
    [ReportsFileds.TotalUsers]: 0,
    [ReportsFileds.Report]: [],
  });

  //#region [ States ]
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]);
  const [mainData, setMainData] = useState(null);
  const [yearData, setYearData] = useState(null);
  const [monthData, setMonthData] = useState(null);
  const [weekData, setWeekData] = useState(null);
  const [dayData, setDayData] = useState(null);
  const [curDate, setCurDate] = useState({
    Day: "",
    Week: "",
    Month: "",
    Year: "",
  });
  //#endregion [ States ]

  //#region [ getSales ]
  const getSales = useCallback(async () => {
    try {
      const { data: report } = await dataProvider.getList("reports", {
        sort: null,
        pagination: null,
        filter: null,
      });
      //console.log("🚀 ~ file: index.js ~ line 27 ~ getSales ~ report", report);

      if (report.length > 0) {
        const data = report[0];
        const reportData = data[ReportsFileds.Data] ?? []; //mockData;
        const dateStructured = commonFunctions.getDayWeekMonth(new Date());
        //grpByYearCategorySum(data[ReportsFileds.Data]) ?? [],
        setMainData(reportData);

        //#region [ Set Categories ]
        // if (data[ReportsFileds.Categories]) {
        //   const categories = Object.values(data[ReportsFileds.Categories]);
        //   setCategories(categories);
        // }
        setCategories(data[ReportsFileds.Categories] ?? []);
        //#endregion [ Set Categories ]
        //#region  [ Set Collections ]
        // if (data[ReportsFileds.Collections]) {
        //   const collections = Object.values(data[ReportsFileds.Collections]);
        //   setCollections(collections);
        // }
        setCollections(data[ReportsFileds.Collections] ?? []);
        //#endregion [ Set Collections ]
        //#region [Set Dates ]
        const years =
          Object.keys(reportData).map((year) => ({ id: year, name: year })) ??
          [];
        setYears(years);
        setMonths(Months);
        const yearsCategorySum = getTillDataReportSummary(reportData);
        setCurDate((prev) => ({
          ...prev,
          // Day: dateStructured.Day,
          // Week: dateStructured.Week,
          Month: dateStructured.Month,
          Year: dateStructured.Year,
        }));
        //#endregion [ Set Dates ]

        //#region [ Set Till Date ]
        setTillDateReport((prev) => ({
          ...prev,
          [ReportsFileds.TotalOrders]: Number(
            data[ReportsFileds.TotalOrders] ?? 0
          ),
          [ReportsFileds.TotalSales]: Number(
            data[ReportsFileds.TotalSales] ?? 0
          ),
          [ReportsFileds.TotalRevenue]: Number(
            data[ReportsFileds.TotalRevenue] ?? 0
          ),
          [ReportsFileds.TotalUsers]: Number(
            data[ReportsFileds.TotalUsers] ?? 0
          ),
          [ReportsFileds.Report]: yearsCategorySum,
          //grpByYearCategorySum(data[ReportsFileds.Data]) ?? [],
        }));
        //#endregion [ Set Till Date ]
      }
    } catch (e) {
      console.log(e);
    }
  }, [dataProvider]);
  //#endregion [ getSales ]
  //#region [ Effects ]
  useEffect(() => {
    getSales();
  }, [getSales]);

  useEffect(() => {
    if (curDate.Year === "" || curDate.Month === "") return;
    const weeks = commonFunctions
      .getWeeksStartAndEndInMonth(curDate.Month, curDate.Year)
      .map((week, indx) => ({
        id: indx + 1,
        name: `${week.start} - ${week.end}`,
      }));

    //console.log("🚀 ~ file: index.js ~ line 134 ~ useEffect ~ weeks", weeks);
    //const months = commonFunctions.getDaysInMonth(curDate.Month, curDate.Year);
    //console.log("🚀 ~ file: index.js ~ line 118 ~ useEffect ~ months", months);
    setWeeks(weeks);
    setDays(commonFunctions.getDaysInMonth(curDate.Month, curDate.Year));
  }, [curDate.Year, curDate.Month]);
  useEffect(() => {
    if (weeks.length <= 0) return;
    setCurDate((prev) => ({
      ...prev,
      Week: commonFunctions.getDayWeekMonth(new Date()).Week,
    }));
  }, [weeks]);
  useEffect(() => {
    if (days.length <= 0) return;
    const curDay = commonFunctions.getDayWeekMonth(new Date()).Day;
    setCurDate((prev) => ({
      ...prev,
      Day: curDay <= days.length ? curDay : days.length,
    }));
  }, [days]);
  useEffect(() => {
    if (!mainData || curDate.Year === "") return;
    // console.log(
    //   "🚀 ~ file: index.js ~ line 104 ~ useEffect ~ mainData",
    //   sumByCategory(mainData[curDate.Year])
    // );
    //const grpByYear = groupByYear(yearwiseCatrogrySum(mainData));
    const yearData = mainData[curDate.Year] ?? null;
    // console.log(
    //   "🚀 ~ file: index.js ~ line 210 ~ useEffect ~ yearData",
    //   yearData
    // );
    setYearData(yearData ?? []);
  }, [mainData, curDate.Year]);

  useEffect(() => {
    // console.log(
    //   "🚀 ~ file: index.js ~ line 121 ~ useEffect ~ yearData",
    //   yearData
    // );
    if (!yearData || curDate.Month === "") return;
    // const grpMonthData = groupByMonth(yearData)[curDate.Month];
    // console.log(
    //   "🚀 ~ file: index.js ~ line 164 ~ useEffect ~ grpMonthData",
    //   grpMonthData
    // );
    setMonthData(groupByMonth(yearData)[curDate.Month] ?? []);
  }, [yearData, curDate.Month]);

  useEffect(() => {
    if (!monthData || curDate.Week === "") return;
    // const weekData = groupByWeek(monthData)[curDate.Week];
    // console.log(
    //   "🚀 ~ file: index.js ~ line 149 ~ useEffect ~ weekData",
    //   weekData
    // );
    setWeekData(groupByWeek(monthData)[curDate.Week] ?? []);
  }, [monthData, curDate.Week]);
  useEffect(() => {
    if (!monthData || curDate.Day === "") return;
    // const weekData = groupByWeek(monthData)[curDate.Week];
    // console.log(
    //   "🚀 ~ file: index.js ~ line 149 ~ useEffect ~ weekData",
    //   weekData
    // );
    setDayData(groupByDay(monthData)[curDate.Day] ?? []);
  }, [monthData, curDate.Day]);
  //#endregion [ Effects ]

  //#region [ Functions ]

  //#endregion [ Functions]
  return (
    <Box>
      <TillDateSummary
        tillDateReport={tillDateReport}
        categories={categories}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ReportsCard
            title="Yearly Report"
            dropDownData={years}
            value={curDate.Year}
            setValue={(value) => {
              setCurDate((prev) => ({ ...prev, Year: value }));
            }}
            sales={getTotalSumByKey(yearData, ReportsFileds.Sales)}
            revenue={getTotalSumByKey(yearData, ReportsFileds.Revenue)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReportsCard
            title="Monthly Report"
            dropDownData={months}
            value={curDate.Month}
            setValue={(value) => {
              setCurDate((prev) => ({ ...prev, Month: value }));
            }}
            sales={getTotalSumByKey(monthData, ReportsFileds.Sales)}
            revenue={getTotalSumByKey(monthData, ReportsFileds.Revenue)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReportsCard
            title="Weekly Report"
            dropDownData={weeks}
            value={curDate.Week}
            setValue={(value) => {
              setCurDate((prev) => ({ ...prev, Week: value }));
            }}
            sales={getTotalSumByKey(weekData, ReportsFileds.Sales)}
            revenue={getTotalSumByKey(weekData, ReportsFileds.Revenue)}
            renderText="Week"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReportsCard
            title="Daily Report"
            dropDownData={days}
            value={curDate.Day <= days.length ? curDate.Day : days.length}
            setValue={(value) => {
              setCurDate((prev) => ({ ...prev, Day: value }));
            }}
            sales={getTotalSumByKey(dayData, ReportsFileds.Sales)}
            revenue={getTotalSumByKey(dayData, ReportsFileds.Revenue)}
          />
        </Grid>
      </Grid>
      <SalesReport
        yearData={yearData}
        monthData={monthData}
        weekData={weekData}
        dayData={dayData}
        categories={categories}
        collections={collections}
      />
    </Box>
  );
};

export default Dashboard;
