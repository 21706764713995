import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
import BooleanFieldYN from "../CommonComponents/BooleanFieldYN";
import ImageListToolbar from "../CommonComponents/ImageListToolbar";
import {
  API_ENDPOINTS,
  CompanyFileds,
  ItemGroupFields,
} from "../Utils/Constants";

const ItemList = (props) => {
  return (
    <List
      {...props}
      actions={
        <ImageListToolbar
          fields={[
            ItemGroupFields.Name,
            ItemGroupFields.SortOrder,
            ItemGroupFields.Status,
          ]}
        />
      }
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: ItemGroupFields.Name, order: "ASC" }}
      perPage={25}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          reference={API_ENDPOINTS.Companies}
          source={ItemGroupFields.CompanyID}
          label="Company"
          link={false}
        >
          <TextField source={CompanyFileds.Name} />
        </ReferenceField>
        <TextField source={ItemGroupFields.Name} />
        <TextField source={ItemGroupFields.Variants} label="Varriants" />
        <NumberField
          source={ItemGroupFields.StartingPrice}
          options={{ style: "currency", currency: "INR" }}
        />
        <BooleanFieldYN
          source={ItemGroupFields.Status}
          label="Status"
          condtion="1"
        />
      </Datagrid>
    </List>
  );
};

export default ItemList;
