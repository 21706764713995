import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoCard from "../../CommonComponents/InfoCard";
import TabPanel from "../../CommonComponents/TabPanel";
import { ReportsFileds, TabsConstant } from "../../Utils/Constants";
import ReLineChart from "./ReLineChart";
import ReBarChart from "./ReBarChart";

const TillDateSummary = ({ tillDateReport = {}, categories = [] }) => {
  const [currentTab, setCurrentTab] = useState(TabsConstant.Sale);
  const [chartType, setChartType] = useState(1);
  const handleOnChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6} sm={3}>
                <InfoCard
                  title="Total Orders"
                  value={tillDateReport[ReportsFileds.TotalOrders] ?? 0}
                  colorClass="blue"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <InfoCard
                  colorClass="yellow"
                  title="Products Sold"
                  value={tillDateReport[ReportsFileds.TotalSales] ?? 0}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InfoCard
                  colorClass="green"
                  title="Total Revenue"
                  value={`₹ ${tillDateReport[ReportsFileds.TotalRevenue] ?? 0}`}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <InfoCard
                  colorClass="pink"
                  title="Users"
                  value={tillDateReport[ReportsFileds.TotalUsers] ?? 0}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Box textAlign="center" p="5px">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-disabled-label">
                  Chart Type
                </InputLabel>
                <Select
                  value={chartType}
                  label="Chart Type"
                  labelId="demo-simple-select-disabled-label"
                  onChange={(e) => {
                    e.stopPropagation();
                    setChartType(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Line Chart</MenuItem>
                  <MenuItem value={2}>Bar Chart</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Tabs value={currentTab} onChange={handleOnChange} centered>
              <Tab label="SALES" value={TabsConstant.Sale} />
              <Tab label="REVENUE" value={TabsConstant.Revenue} />
            </Tabs>
            <TabPanel value={currentTab} index={TabsConstant.Sale}>
              <Box p="10px">
                {chartType === 1 && (
                  <ReLineChart
                    data={tillDateReport[ReportsFileds.Report]}
                    categories={categories}
                    type={ReportsFileds.Sales}
                    xaxisDataKey={ReportsFileds.Year}
                    xaxislabel="Sale Till Date"
                  />
                )}
                {chartType === 2 && (
                  <ReBarChart
                    data={tillDateReport[ReportsFileds.Report]}
                    categories={categories}
                    type={ReportsFileds.Sales}
                    xaxisDataKey={ReportsFileds.Year}
                    xaxislabel="Sale Till Date"
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={TabsConstant.Revenue}>
              <Box p="10px">
                {chartType === 1 && (
                  <ReLineChart
                    data={tillDateReport[ReportsFileds.Report]}
                    categories={categories}
                    type={ReportsFileds.Revenue}
                    xaxisDataKey={ReportsFileds.Year}
                    xaxislabel="Revenue Till Date"
                  />
                )}
                {chartType === 2 && (
                  <ReBarChart
                    data={tillDateReport[ReportsFileds.Report]}
                    categories={categories}
                    type={ReportsFileds.Revenue}
                    xaxisDataKey={ReportsFileds.Year}
                    xaxislabel="Revenue Till Date"
                  />
                )}
              </Box>
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TillDateSummary;
