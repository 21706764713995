import React, { useEffect } from "react";
import { BooleanInput, NumberInput, SelectInput, TextInput } from "react-admin";
import { ItemGroupFields, StockType } from "../Utils/Constants";
import { Grid } from "@material-ui/core";
import { useBooleanInputStyle } from "../styles";
import {
  cgstSgstValiation,
  deliveryDaysValidatiions,
  hsnCodeValidation,
  hsnValidation,
  igstValidation,
  makingDaysValidation,
  maxOrderValidation,
  minStockValidation,
  readyForDeliveryValidation,
  stockTypeValidation,
} from "./ItemValidations";
import { useFormState } from "react-final-form";
import { TabLabels } from "./TabsLabels";
const StockInfo = ({ setErrors }) => {
  const { errors, submitErrors } = useFormState();
  const booleanInputStyle = useBooleanInputStyle();
  useEffect(() => {
    const curError = submitErrors ?? errors;
    // console.log(
    //   "🚀 ~ file: StockInfo.js ~ line 24 ~ useEffect ~ curError",
    //   curError
    // );
    if (
      curError &&
      (curError[ItemGroupFields.ProductType] ||
        curError[ItemGroupFields.MinStock] ||
        curError[ItemGroupFields.MaxOrderQty] ||
        curError[ItemGroupFields.MakingDays] ||
        curError[ItemGroupFields.ReadyForDeliveryDay] ||
        curError[ItemGroupFields.DeliveryDays] ||
        curError[ItemGroupFields.HSN] ||
        curError[ItemGroupFields.HSNCode] ||
        curError[ItemGroupFields.CGSTPercent] ||
        curError[ItemGroupFields.SGSTPercent] ||
        curError[ItemGroupFields.IGSTPercent] ||
        curError[ItemGroupFields.IsInclusive])
    ) {
      if (setErrors)
        setErrors((prev) => ({ ...prev, [TabLabels.StockInfoLabel]: true }));
    } else {
      if (setErrors)
        setErrors((prev) => ({ ...prev, [TabLabels.StockInfoLabel]: false }));
    }
  }, [errors, submitErrors, setErrors]);
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <SelectInput
            source={ItemGroupFields.ProductType}
            variant="outlined"
            choices={StockType}
            label="Stock Type"
            defaultValue={1}
            allowEmpty={false}
            resettable
            fullWidth
            validate={stockTypeValidation}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <NumberInput
            source={ItemGroupFields.MinStock}
            label="Min Stock"
            variant="outlined"
            fullWidth
            validate={minStockValidation}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <NumberInput
            source={ItemGroupFields.MaxOrderQty}
            label="Max Order"
            variant="outlined"
            fullWidth
            validate={maxOrderValidation}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            source={ItemGroupFields.MakingDays}
            label="Making Days"
            variant="outlined"
            fullWidth
            validate={makingDaysValidation}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            source={ItemGroupFields.ReadyForDeliveryDay}
            label="Ready For Delivery"
            variant="outlined"
            fullWidth
            validate={readyForDeliveryValidation}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            source={ItemGroupFields.DeliveryDays}
            label="Delivery Days"
            variant="outlined"
            fullWidth
            validate={deliveryDaysValidatiions}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source={ItemGroupFields.HSN}
            label="HSN"
            variant="outlined"
            fullWidth
            validate={hsnValidation}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source={ItemGroupFields.HSNCode}
            label="HSN CODE"
            variant="outlined"
            fullWidth
            validate={hsnCodeValidation}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source={ItemGroupFields.CGSTPercent}
            label="CGST"
            variant="outlined"
            fullWidth
            validate={cgstSgstValiation}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source={ItemGroupFields.SGSTPercent}
            label="SGST"
            variant="outlined"
            fullWidth
            validate={cgstSgstValiation}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source={ItemGroupFields.IGSTPercent}
            label="IGST"
            variant="outlined"
            fullWidth
            validate={igstValidation}
          />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput
            source={ItemGroupFields.IsInclusive}
            classes={{ root: booleanInputStyle.root }}
            label="Is Inclusive"
            variant="outlined"
            format={(v) => v === 1}
            parse={(v) => (v ? "1" : "0")}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StockInfo;
