import React from "react";
import { SaveButton, Toolbar, useDataProvider, useNotify } from "react-admin";
import { useFormState } from "react-final-form";
import {
  commonFunctions,
  LineItemFields,
  OrderFields,
} from "../Utils/Constants";

const OrderExpandedSaveToolBar = (props) => {
  //   console.log(
  //     "🚀 ~ file: OrderExpandedSaveToolBar.js ~ line 6 ~ OrderExpandedSaveToolBar ~ props",
  //     props
  //   );
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { isSaving, setIsSaving, ...restProps } = props;
  // console.log(
  //   "🚀 ~ file: OrderExpandedSaveToolBar.js ~ line 14 ~ OrderExpandedSaveToolBar ~ restProps",
  //   restProps
  // );
  const formState = useFormState();
  const updateOrder = async () => {
    try {
      setIsSaving(true);
      console.log("values", { ...formState.values });
      let isValid = commonFunctions.validateShipping(
        null,
        OrderFields.Status,
        LineItemFields.TrackingNo,
        { ...formState.values }
      );
      if (!isValid) {
        throw new Error("Provide trackingno for products with shipped status");
      }
      isValid = commonFunctions.validateShipping(
        null,
        OrderFields.Status,
        LineItemFields.TrackingUrl,
        { ...formState.values }
      );
      if (!isValid) {
        throw new Error(
          "Provide tracking url for products with shipped status"
        );
      }
      await dataProvider.update(restProps.resource, {
        id: restProps.id,
        data: { ...formState.values },
      });
      notify(`Order ${restProps.record[OrderFields.OrderID]} Updated`, "info");
    } catch (error) {
      notify(error.message ? error.message : error, "error");
    }
    setIsSaving(false);
  };
  return (
    <Toolbar
      {...restProps}
      saving={isSaving}
      handleSubmitWithRedirect={updateOrder}
    >
      <SaveButton
        disabled={!formState.dirty || !restProps.record[OrderFields.Editable]}
      />
    </Toolbar>
  );
};

export default OrderExpandedSaveToolBar;
