export const UserFields = {
  ID: "id",
  UserID: "UserID",
  FirstName: "FirstName",
  LastName: "LastName",
  FullName: "FullName",
  EmailId: "EmailId",
  MobileNo: "MobileNo",
  IsActive: "IsActive",
  IsLocked: "IsLocked",
};
