import React from "react";
import classes from "./styles.module.scss";
const InfoCard = ({ title, noSubtitle = null, value, colorClass = "" }) => {
  return (
    <div className={`${classes.infoCard} ${colorClass}`}>
      <div>
        <h2 className={classes.value}>{value}</h2>
        <div></div>
      </div>
      <div>
        <h5 className={classes.title}>{title}</h5>
        {noSubtitle ? null : <p className={classes.tillDate}>Till Date</p>}
      </div>
    </div>
  );
};

export default InfoCard;
