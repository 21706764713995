import React from "react";
import {
  FormDataConsumer,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { API_ENDPOINTS, ImageUploadField } from "../../Utils/Constants";
import { TopSlidesFields } from "../../Utils/Constants/TopSlidesFields";
import TopSlideSelectType from "./TopSlideSelectType";
import { TopSlideValidations } from "./TopSlideValidation";

const TopSlideCommonInputs = (props) => {
  return (
    <>
      <TextInput
        {...props}
        source={TopSlidesFields.Headline}
        fullWidth
        validate={TopSlideValidations.headline}
        style={{ width: "100%" }}
      />
      <br />
      <TextInput
        {...props}
        style={{ width: "100%" }}
        source={TopSlidesFields.TagLine}
        fullWidth
      />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TopSlideSelectType
            {...props}
            style={{ width: "100%" }}
            validate={TopSlideValidations.type}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const type = formData[TopSlidesFields.Type];
              return (
                <ReferenceInput
                  {...props}
                  style={{ width: "100%" }}
                  key={type}
                  reference={
                    Number(type) === 1
                      ? API_ENDPOINTS.Collections
                      : Number(type) === 2
                      ? API_ENDPOINTS.Categories
                      : API_ENDPOINTS.Products
                  }
                  source={TopSlidesFields.TypeId}
                  emptyValue={0}
                  allowEmpty={true}
                  label={
                    Number(type) === 1
                      ? "Select Collection"
                      : Number(type) === 2
                      ? "Select Category"
                      : "Select Product"
                  }
                  fullWidth
                  validate={TopSlideValidations.typeId}
                >
                  <SelectInput
                    {...props}
                    emptyValue={0}
                    emptyText="Select One"
                    optionText="Name"
                  />
                </ReferenceInput>
              );
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
      <br />
      <TextInput
        {...props}
        style={{ width: "100%" }}
        label="Button Label"
        source={TopSlidesFields.LinkTitle}
        validate={TopSlideValidations.buttonLabel}
        fullWidth
      />
      <br />
      <ImageInput
        {...props}
        label="Banner Image"
        source={ImageUploadField}
        multiple={false}
        accept="image/jpeg"
        validate={props.isEdit ? [] : TopSlideValidations.image}
      >
        <ImageField {...props} />
      </ImageInput>
    </>
  );
};

export default TopSlideCommonInputs;
