import React from "react";
import { useLocation } from "react-router-dom";
import { MenuItemLink } from "react-admin";
import couponsMenus from "../DiscountCoupons/couponsMenus";
import { MENU_LOCATIONS } from "../Utils/Constants";
import SubMenu from "./SubMenu";

const CouponsMenu = ({
  handleToggle,
  isOpen,
  sidebarIsOpen,
  name,
  icon,
  onMenuClick,
  classes,
}) => {
  const location = useLocation();
  const loc = location.pathname.split("/", 2);
  return (
    <SubMenu
      handleToggle={handleToggle}
      isOpen={isOpen}
      sidebarIsOpen={sidebarIsOpen}
      name={name}
      icon={icon}
      active={loc.length === 2 && loc[1] === MENU_LOCATIONS.Coupons}
      // active={
      //   location.pathname === `/${API_ENDPOINTS.Coupons}` ||
      //   location.pathname === `/${API_ENDPOINTS.Coupons}/create` ||
      //   location.pathname === `/${API_ENDPOINTS.Companies}`
      // }
    >
      {Object.keys(couponsMenus).map((item) => {
        const curItem = couponsMenus[item];
        if (!curItem) return null;
        return (
          <MenuItemLink
            key={item}
            to={curItem}
            primaryText={curItem.label}
            onClick={onMenuClick}
            sidebarIsOpen={isOpen}
            activeClassName={classes.active}
            exact
          />
        );
      })}
    </SubMenu>
  );
};

export default CouponsMenu;
