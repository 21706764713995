import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import {
  addFormData,
  arrayValidation,
  getArrayValidationErrors,
} from "./commonFunctions";
import httpClient from "./HttpClient";

const createData = async (resource, params) => {
  try {
    const url = `${resource}`;
    //console.log("🚀 ~ file: index.js ~ line 100 ~ create: ~ url", url);
    const paramData = { ...params.data };
    // const formData = new FormData();
    // Object.keys(paramData).forEach((key) => {
    //   if (paramData[key] !== null) formData.append(key, paramData[key]);
    // });
    const { formData, arrayError } = addFormData(paramData);
    const {
      json: { status = null, data = {} },
    } = await httpClient(url, {
      method: "POST",
      body: formData,
    });
    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({ data: data });
      } else if (status[ErrorCode] === "01") {
        const errors = {
          validations: null,
        };
        const keys = Object.keys(status[ErrorDescription]);
        console.log(
          "🚀 ~ file: index.js ~ line 120 ~ create: ~ keys",
          keys,
          arrayError
        );
        if (keys.length > 0) {
          errors.validations = {};
          keys.forEach((key) => {
            if (!arrayValidation(key, arrayError, status)) {
              errors.validations[key] = status[ErrorDescription][key][0];
            }
            // console.log(
            //   "array",
            //   RegExp("^[a-zA-z]+.([\\d)]+).[a-zA-Z]+$", "g").test(key),
            //   key
            // );
            // const isArrayValidation = RegExp(
            //   "^[a-zA-z]+.([\\d)]+).[a-zA-Z]+$",
            //   "g"
            // ).test(key); //  key.match(/.([\d)]+)\.[a-zA-Z]+/);
            // if (isArrayValidation) {
            //   const checkArrayValidation = key.split(".");
            //   // console.log(
            //   //   "🚀 ~ file: createData.js ~ line 41 ~ keys.forEach ~ checkArrayValidation",
            //   //   checkArrayValidation
            //   // );
            //   if (
            //     checkArrayValidation &&
            //     Array.isArray(checkArrayValidation) &&
            //     checkArrayValidation.length >= 3
            //   ) {
            //     console.log(
            //       "🚀 ~ file: createData.js ~ line 40 ~ keys.forEach ~ checkArrayValidation",
            //       checkArrayValidation
            //     );
            //     const getArrayErrors = [...arrayError[checkArrayValidation[0]]];
            //     console.log(
            //       "🚀 ~ file: createData.js ~ line 62 ~ keys.forEach ~ getArrayErrors",
            //       getArrayErrors
            //     );
            //     if (
            //       getArrayErrors &&
            //       Array.isArray(getArrayErrors) &&
            //       getArrayErrors.length > checkArrayValidation[1]
            //     ) {
            //       getArrayErrors[checkArrayValidation[1]] = {
            //         ...getArrayErrors[checkArrayValidation[1]],
            //         [checkArrayValidation[2]]: status[ErrorDescription][key][0],
            //       };
            //     }
            //     console.log(
            //       "🚀 ~ file: createData.js ~ line 72 ~ keys.forEach ~ getArrayErrors",
            //       getArrayErrors
            //     );
            //     arrayError[checkArrayValidation[0]] = [...getArrayErrors];
            //   }
            // } else {
            //   errors.validations[key] = status[ErrorDescription][key][0];
            // }
          });
          getArrayValidationErrors();
          // const arrayErrKeys = Object.keys(arrayError);
          // console.log(
          //   "🚀 ~ file: createData.js ~ line 88 ~ createData ~ arrayErrKeys",
          //   arrayErrKeys
          // );
          // arrayErrKeys.forEach((key) => {
          //   console.log(
          //     "🚀 ~ file: createData.js ~ line 89 ~ arrayErrKeys.forEach ~ key",
          //     arrayError[key]
          //   );
          //   if (arrayError[key].filter((e) => e !== null).length > 0)
          //     errors.validations[key] = [...arrayError[key]];
          // });
          console.log("allerrors", errors.validations, arrayError);
          return Promise.reject(errors);
        } else return Promise.reject(status[ErrorDescription]);
      } else {
        //console.log("Error", status.ErrorDescription);
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch ({ message }) {
    //console.log("DataProvider Error", err);
    return Promise.reject(message);
  }
};
export default createData;
