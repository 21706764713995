export const CouponFields = {
  ID: "id",
  EntryDate: "EntryDate",
  CouponType: "CouponType",
  CouponCode: "CouponCode",
  CompanyID: "CompanyID",
  CouponDescription: "CouponDescription",
  FromDate: "FromDate",
  ToDate: "ToDate",
  Days: "Days",
  Limit: "Limit",
  Percentage: "Percentage",
  IsActive: "IsActive",
  IsToShow: "IsToShow",
  TeamName: "TeamName",
};

export const CouponTypes = [
  { id: "USER_REGISTRATION", name: "USER REGISTRATION" },
  { id: "MARKETING", name: "MARKETING" },
  { id: "EVENTS", name: "EVENTS" },
];

export const CouponTypesGrid = {
  USER_REGISTRATION: "USER REGISTRATION",
  MARKETING: "MARKETING",
  EVENTS: "EVENTS",
};
export const CouponTypesGridValues = {
  USER_REGISTRATION: "USER_REGISTRATION",
  MARKETING: "MARKETING",
  EVENTS: "EVENTS",
};

export const GetCouponTypes = () => {
  return CouponTypes.map((ele) => ele.id);
};
