import {
  API_ENDPOINTS,
  ErrorCode,
  ErrorDescription,
  UNKNOWN_ERROR,
} from "../Utils/Constants";
import { addFormData } from "./commonFunctions";
import httpClient from "./HttpClient";

export const changePassword = async (params) => {
  const url = API_ENDPOINTS.UserProfile;
  const { formData } = addFormData(params);
  const {
    json: { status = null, data = {} },
  } = await httpClient(url, {
    method: "POST",
    body: formData,
  });
  if (status && status[ErrorCode] && status[ErrorDescription]) {
    if (status[ErrorCode] === "00") {
      return Promise.resolve({ data: data });
    } else if (status[ErrorCode] === "01") {
      const errors = {
        validations: null,
      };
      const keys = Object.keys(status[ErrorDescription]);
      if (keys.length > 0) {
        errors.validations = {};
        keys.forEach((key) => {
          errors.validations[key] = status[ErrorDescription][key][0];
        });
        return Promise.reject(errors);
      }
      return Promise.reject(status[ErrorDescription]);
    } else {
      return Promise.reject(status[ErrorDescription]);
    }
  } else {
    return Promise.reject(UNKNOWN_ERROR);
  }
  //await new Promise((resolve) => setTimeout(resolve, 3000));
  //return Promise.resolve({ data: { id: "sasa" } });
};
