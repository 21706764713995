//import { UNKNOWN_ERROR } from "../Utils/Constants";
import httpClient from "./HttpClient";
const getManyData = async (resource, params) => {
  try {
    //console.log("params", params, resource);
    // console.log("params", JSON.stringify(params));
    let url = `${resource}`;
    // const { json } = await httpClient(url, {
    //   method: "POST",
    //   body: JSON.stringify(params)
    // });
    const { json } = await httpClient(url);
    //console.log("json", json);
    return Promise.resolve({ data: json.data ? json.data : json });
  } catch ({ message }) {
    return Promise.reject(message);
  }
};
export default getManyData;
