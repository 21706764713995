import React from "react";

const TitleComponent = ({ record, source = "title", title = "" }) => {
  return (
    <span>
      {title} {record ? record[source] : ""}
    </span>
  );
};

export default TitleComponent;
