import { apiUrl, AUTH_KEY } from "../Utils/Constants";
import { fetchUtils } from "react-admin";
const httpClient = (url, options = {}) => {
  if (!options.headers) options.headers = new Headers();

  const token = localStorage.getItem(AUTH_KEY);
  options.headers.append("Accept", "application/json");
  options.headers.append("Authorization", `Bearer ${token}`);
  const nUrl = `${apiUrl}/${url}`;
  return fetchUtils.fetchJson(nUrl, options);
};

export default httpClient;
