import { maxLength, minLength, number, required } from "react-admin";
import { SwatchFields } from "../Utils/Constants";
const checkIgst = (value, allValues) => {
  if (
    value !==
    allValues[SwatchFields.CGSTPercent] + allValues[SwatchFields.SGSTPercent]
  ) {
    return "Igst shouldbe equal to Cgst + Sgst";
  }
  return undefined;
};
const name = [required(), maxLength(150)];
const displayName = [...name];
const descrtiption = [required(), maxLength(500)];
const sortOrder = [required(), number()];
const sellingPrice = [required(), number()];
const hsnCode = [required(), number(), minLength(8), maxLength(8)];
const gst = [required(), number()];
const igst = [required(), number(), checkIgst];
const image = [required()];
const fabricType = [required()];
export const SwatchValidation = {
  descrtiption,
  displayName,
  fabricType,
  gst,
  hsnCode,
  igst,
  image,
  name,
  sellingPrice,
  sortOrder,
};
