import React from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import { API_ENDPOINTS, ItemGroupFields } from "../Utils/Constants";

import TitleComponent from "../CommonComponents/TitleComponent";
import EditTopActions from "../CommonComponents/EditTopActions";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import InfoTab from "./InfoTab";
import StockInfo from "./StockInfo";
import ImagesTab from "./ImagesTab";
import VariantsTab from "./VariantsTab";
import { TabLabels } from "./TabsLabels";
import { updateRecord } from "../Utils/CreateUpdateRecord";
import { onFailure, onSuccess } from "./ItemFunctions";
//import { useSelector } from "react-redux";
//import BackdropLoader from "../CommonComponents/BackdropLoader";
// const useStyles = makeStyles((theme) => ({
//   line: {
//     borderBottom: "none",
//     alignItems: "start",
//     marginBottom: theme.spacing(1),
//   },
// }));
const ItemEdit = (props) => {
  //const { loading = 0 } = useSelector((state) => state.admin);
  //console.log("🚀 ~ file: ItemEdit.js ~ line 15 ~ ItemEdit ~ props", props);
  //const [variantExpanded, setVariantExpanded] = useState(false);
  //const classes = useStyles();
  const notify = useNotify();
  const [mutate] = useMutation();
  const refresh = useRefresh();
  const saveRecord = async (values) => {
    const variants = [...values[ItemGroupFields.Variants]];
    const newVariants = variants.map((e) => JSON.stringify(e));
    values[ItemGroupFields.Variants] = [...newVariants];
    return await updateRecord(
      mutate,
      values,
      API_ENDPOINTS.Items,
      (retData) => {
        onSuccess(retData, notify, "Item Updated");
        refresh();
      },
      (error) => {
        onFailure(error, notify);
      }
    );
  };
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      title={<TitleComponent source={ItemGroupFields.Name} title="Edit - " />}
      actions={<EditTopActions />}
    >
      <TabbedForm save={saveRecord} toolbar={<SaveToolBar />} redirect={false}>
        <FormTab label={TabLabels.InfoLabel}>
          <InfoTab />
        </FormTab>
        <FormTab label={TabLabels.StockInfoLabel}>
          <StockInfo />
        </FormTab>
        <FormTab label={TabLabels.ImagesTabLabel}>
          <ImagesTab />
        </FormTab>
        <FormTab label={TabLabels.VariantsTabLabel}>
          <VariantsTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ItemEdit;
