import React, { useState } from "react";
import {
  Edit,
  FormWithRedirect,
  TextField,
  Labeled,
  DateField,
  ReferenceField,
  SelectInput,
  SaveButton,
  useDataProvider,
  useNotify,
  TextInput,
} from "react-admin";
import TitleComponent from "../CommonComponents/TitleComponent";
import {
  API_ENDPOINTS,
  commonFunctions,
  LineItemFields,
  OrderFields,
  OrderStatusConst,
  PaymentFields,
  UserFields,
} from "../Utils/Constants";
import Box from "@material-ui/core/Box";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import OrderItems from "./OrderItems";

const OrderEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const Spacer = () => <Box m={1}>&nbsp;</Box>;
  const [isSaving, setIsSaving] = useState(false);
  const updateOrder = async (values, record) => {
    setIsSaving(true);
    try {
      //console.log("values", values, record);
      let isValid = commonFunctions.validateShipping(
        null,
        OrderFields.Status,
        LineItemFields.TrackingNo,
        values
      );
      if (!isValid) {
        throw new Error("Provide trackingno for products with shipped status");
      }
      isValid = commonFunctions.validateShipping(
        null,
        OrderFields.Status,
        LineItemFields.TrackingUrl,
        values
      );
      if (!isValid) {
        throw new Error(
          "Provide tracking url for products with shipped status"
        );
      }
      await dataProvider.update(API_ENDPOINTS.Orders, {
        id: record[OrderFields.ID],
        data: values,
      });
      notify(`Order ${record[OrderFields.OrderID]} Updated`, "info");
    } catch (error) {
      notify(`Error : ${error.message ? error.message : error}`, "error");
    }
    setIsSaving(false);
  };
  return (
    <Edit
      {...props}
      title={<TitleComponent source={OrderFields.OrderID} title="Order : " />}
    >
      <FormWithRedirect
        {...props}
        redirect={false}
        render={(formProps) => {
          //console.log("formprops", formProps.form.getState().dirty);
          //console.log(formProps.record);
          return (
            <Box width="100%">
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid container item sm={4} lg={4}>
                      <Grid item lg={12}>
                        <Typography variant="h6" gutterBottom>
                          Order
                        </Typography>
                      </Grid>
                      <Grid item lg={12} xs={12} sm={12}>
                        <Labeled label="Order No">
                          <TextField
                            source={OrderFields.OrderID}
                            record={formProps.record}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item lg={12} xs={12} sm={12}>
                        <Labeled label="Order Date">
                          <DateField
                            source={OrderFields.OrderDate}
                            record={formProps.record}
                            locales="fr-FR"
                          />
                        </Labeled>
                      </Grid>
                      <Grid item lg={12} xs={12} sm={12}>
                        <Labeled label="Invoice No">
                          <TextField
                            source={OrderFields.InvoiceNo}
                            record={formProps.record}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item lg={12} xs={12} sm={12}>
                        <Labeled label="Invoice Date">
                          <DateField
                            source={OrderFields.InvoiceDate}
                            record={formProps.record}
                            locales="fr-FR"
                          />
                        </Labeled>
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <SelectInput
                          source={OrderFields.Status}
                          variant="outlined"
                          choices={OrderStatusConst}
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <TextInput
                          source={LineItemFields.TrackingNo}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <TextInput
                          source={LineItemFields.TrackingUrl}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <SaveButton
                          label="Update"
                          disabled={
                            !formProps.form.getState().dirty ||
                            !formProps.record[OrderFields.Editable]
                          }
                          handleSubmitWithRedirect={() => {
                            updateOrder(
                              formProps.form.getState().values,
                              formProps.record
                            );
                          }}
                          saving={isSaving}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item sm={4} lg={4}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Customer
                        </Typography>
                        <ReferenceField
                          source={OrderFields.UserID}
                          reference={API_ENDPOINTS.Users}
                          basePath={`/${API_ENDPOINTS.Users}`}
                          record={formProps.record}
                        >
                          <TextField
                            source={UserFields.FullName}
                            resource={API_ENDPOINTS.Users}
                            component="h1"
                            style={{ fontSize: "1.5rem" }}
                          />
                        </ReferenceField>
                        <Typography
                          variant="h6"
                          style={{
                            borderTop: "1px solid black",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          Shipping Address
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ContactPerson]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ContactMobile]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingAddress1]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingAddress2]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingCity]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingState]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingPincode]}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            borderTop: "1px solid black",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          Billing Address
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ContactPerson]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ContactMobile]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingAddress1]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingAddress2]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingCity]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingState]}
                        </Typography>
                        <Typography>
                          {formProps.record[OrderFields.ShippingPincode]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item sm={4} lg={4}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Payment Details
                        </Typography>
                        <Labeled label="ID" fullWidth>
                          <TextField
                            source={PaymentFields.PaymentID}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Date" fullWidth>
                          <DateField
                            source={PaymentFields.PaymentDate}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            locales="fr-FR"
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Status" fullWidth>
                          <TextField
                            source={PaymentFields.Status}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Tracking Id" fullWidth>
                          <TextField
                            source={PaymentFields.TrackingID}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Trasaction Date" fullWidth>
                          <TextField
                            source={PaymentFields.TransactionDate}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="BankRef" fullWidth>
                          <TextField
                            source={PaymentFields.BankRefNo}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Mode" fullWidth>
                          <TextField
                            source={PaymentFields.PaymentMode}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="CardName" fullWidth>
                          <TextField
                            source={PaymentFields.CardName}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                        <Labeled label="Failure Message" fullWidth>
                          <TextField
                            source={PaymentFields.FailureMessage}
                            record={
                              formProps.record[OrderFields.PaymentDetails]
                            }
                            fullWidth
                          />
                        </Labeled>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer />
                  <Box>
                    <OrderItems
                      items={formProps.record[OrderFields.LineItems]}
                      record={formProps.record}
                      isSaving={isSaving}
                      setIsSaving={setIsSaving}
                    />
                  </Box>
                  <Spacer />
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      borderTop: "1px solid",
                      borderBottom: "1px solid",
                    }}
                  >
                    Totals
                  </Typography>
                  {formProps.record["CompanyTotals"].map((company) => {
                    return (
                      <Box key={company.CompanyID}>
                        <Typography
                          style={{
                            borderBottom: "1px solid",
                            textAlign: "center",
                          }}
                          variant="h6"
                          gutterBottom
                        >
                          {company.CompanyName}
                        </Typography>
                        <Grid container>
                          <Grid item xs>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "right" }}
                            >
                              TaxableAmount :
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "left", marginLeft: "5px" }}
                            >
                              {company.TaxableAmount}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "right" }}
                            >
                              TaxAmount :
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "left", marginLeft: "5px" }}
                            >
                              {company.TaxAmount}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "right" }}
                            >
                              Total :
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              style={{ textAlign: "left", marginLeft: "5px" }}
                            >
                              {company.Total}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      borderTop: "1px solid",
                      borderBottom: "1px solid",
                    }}
                  >
                    Final Amount
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textAlign: "right",
                      borderBottom: "1px solid",
                    }}
                  >
                    {formProps.record[OrderFields.FinalAmount].toLocaleString(
                      "en-IN",
                      {
                        style: "currency",
                        currency: "INR",
                      }
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          );
        }}
      />
    </Edit>
  );
};

export default OrderEdit;
