import { ReportsFileds } from "../Utils/Constants";

export const mockData = {
  2021: [
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-01-16 23:03:24.290",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-01-16 23:09:04.170",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "112.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-01-19 12:12:29.973",
      Year: "2021",
      Month: "1",
      Week: "4",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-07 12:47:15.713",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "3.00",
      Revenue: "315.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-07 13:19:34.577",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 17:37:43.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 20:28:07.660",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 20:35:06.270",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 20:42:30.350",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 21:01:23.683",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "427.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2021-01-16 22:38:29.883",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "54000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2021-02-20 20:42:30.350",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-16 21:22:49.563",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "9000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-16 23:00:53.777",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-16 23:03:24.290",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "137500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-21 12:52:33.160",
      Year: "2021",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-21 13:43:13.717",
      Year: "2021",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "348000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-01-27 11:06:44.653",
      Year: "2021",
      Month: "1",
      Week: "5",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-02-17 12:48:52.287",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2021-02-07 12:47:15.713",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2021-02-20 20:35:06.270",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2021-02-20 21:01:23.683",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "56000.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "50500.00",
    },
    {
      CategoryID: "96",
      CategoryDisplayName: "CONSOLE & STORAGES",
      InvoiceDate: "2021-02-20 20:42:30.350",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "86000.00",
    },
    {
      CategoryID: "98",
      CategoryDisplayName: "BEDS",
      InvoiceDate: "2021-02-20 20:28:07.660",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#C7D9DA",
      Sales: "1.00",
      Revenue: "61000.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2021-01-21 13:23:33.830",
      Year: "2021",
      Month: "1",
      Week: "4",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2021-02-01 13:51:27.427",
      Year: "2021",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2021-02-01 13:56:53.757",
      Year: "2021",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "121",
      CategoryDisplayName: "BEDSIDE TABLES",
      InvoiceDate: "2021-02-07 12:47:15.713",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#B9D4D2",
      Sales: "1.00",
      Revenue: "18500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2021-02-20 20:28:07.660",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "68500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "37500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2021-01-21 13:09:50.450",
      Year: "2021",
      Month: "1",
      Week: "4",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "23500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2021-02-20 17:37:43.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "28500.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-01-16 23:03:24.290",
      Year: "2021",
      Month: "1",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-08 11:38:32.330",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-08 11:47:26.170",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-08 15:15:59.830",
      Year: "2021",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-17 12:48:52.287",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-20 17:37:43.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2300.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-20 20:28:07.660",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2650.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-20 20:35:06.270",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-20 20:42:30.350",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2021-02-20 21:18:40.517",
      Year: "2021",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "2.00",
      Revenue: "3770.00",
    },
  ],
  2020: [
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-01-16 23:03:24.290",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "5.00",
      Revenue: "1210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-01-16 23:09:04.170",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "112.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-01-19 12:12:29.973",
      Year: "2020",
      Month: "1",
      Week: "4",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-07 12:47:15.713",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "3.00",
      Revenue: "315.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-07 13:19:34.577",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 17:37:43.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 20:28:07.660",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 20:35:06.270",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 20:42:30.350",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 21:01:23.683",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "427.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2020-01-16 22:38:29.883",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "54000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2020-02-20 20:42:30.350",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-16 21:22:49.563",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "69000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-16 23:00:53.777",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-16 23:03:24.290",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "137500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-21 12:52:33.160",
      Year: "2020",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-21 13:43:13.717",
      Year: "2020",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "348000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-01-27 11:06:44.653",
      Year: "2020",
      Month: "1",
      Week: "5",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-02-17 12:48:52.287",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2020-02-07 12:47:15.713",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2020-02-20 20:35:06.270",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2020-02-20 21:01:23.683",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "56000.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "50500.00",
    },
    {
      CategoryID: "96",
      CategoryDisplayName: "CONSOLE & STORAGES",
      InvoiceDate: "2020-02-20 20:42:30.350",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "86000.00",
    },
    {
      CategoryID: "98",
      CategoryDisplayName: "BEDS",
      InvoiceDate: "2020-02-20 20:28:07.660",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#C7D9DA",
      Sales: "1.00",
      Revenue: "61000.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2020-01-21 13:23:33.830",
      Year: "2020",
      Month: "1",
      Week: "4",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2020-02-01 13:51:27.427",
      Year: "2020",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2020-02-01 13:56:53.757",
      Year: "2020",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "121",
      CategoryDisplayName: "BEDSIDE TABLES",
      InvoiceDate: "2020-02-07 12:47:15.713",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#B9D4D2",
      Sales: "1.00",
      Revenue: "18500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2020-02-20 20:28:07.660",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "68500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "37500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2020-01-21 13:09:50.450",
      Year: "2020",
      Month: "1",
      Week: "4",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "23500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2020-02-20 17:37:43.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "28500.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-01-16 23:03:24.290",
      Year: "2020",
      Month: "1",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-08 11:38:32.330",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-08 11:47:26.170",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-08 15:15:59.830",
      Year: "2020",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-17 12:48:52.287",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-20 17:37:43.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2300.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-20 20:28:07.660",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2650.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-20 20:35:06.270",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-20 20:42:30.350",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2020-02-20 21:18:40.517",
      Year: "2020",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "4.00",
      Revenue: "13770.00",
    },
  ],
  2019: [
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-01-16 23:03:24.290",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "5.00",
      Revenue: "1210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-01-16 23:09:04.170",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "112.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-01-19 12:12:29.973",
      Year: "2019",
      Month: "1",
      Week: "4",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-07 12:47:15.713",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "3.00",
      Revenue: "315.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-07 13:19:34.577",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#A4A4A4",
      Sales: "1.00",
      Revenue: "105.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 17:37:43.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 20:28:07.660",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 20:35:06.270",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "4.00",
      Revenue: "637.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 20:42:30.350",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 21:01:23.683",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "210.00",
    },
    {
      CategoryID: "0",
      CategoryDisplayName: "Swatches",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#A4A4A4",
      Sales: "2.00",
      Revenue: "427.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2019-01-16 22:38:29.883",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "54000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2019-02-20 20:42:30.350",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34000.00",
    },
    {
      CategoryID: "46",
      CategoryDisplayName: "ARMCHAIRS",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D6E6E1",
      Sales: "1.00",
      Revenue: "34500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-16 21:22:49.563",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "9000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-16 23:00:53.777",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-16 23:03:24.290",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "137500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-21 12:52:33.160",
      Year: "2019",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-21 13:43:13.717",
      Year: "2019",
      Month: "1",
      Week: "4",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "348000.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-01-27 11:06:44.653",
      Year: "2019",
      Month: "1",
      Week: "5",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-02-17 12:48:52.287",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "48",
      CategoryDisplayName: "SOFAS",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "31500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2019-02-07 12:47:15.713",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2019-02-20 20:35:06.270",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "1.00",
      Revenue: "16500.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2019-02-20 21:01:23.683",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "56000.00",
    },
    {
      CategoryID: "94",
      CategoryDisplayName: "DINING CHAIRS",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCC9CA",
      Sales: "2.00",
      Revenue: "50500.00",
    },
    {
      CategoryID: "96",
      CategoryDisplayName: "CONSOLE & STORAGES",
      InvoiceDate: "2019-02-20 20:42:30.350",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#FCE1E8",
      Sales: "1.00",
      Revenue: "86000.00",
    },
    {
      CategoryID: "98",
      CategoryDisplayName: "BEDS",
      InvoiceDate: "2019-02-20 20:28:07.660",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#C7D9DA",
      Sales: "1.00",
      Revenue: "61000.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2019-01-21 13:23:33.830",
      Year: "2019",
      Month: "1",
      Week: "4",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "59500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2019-02-01 13:51:27.427",
      Year: "2019",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "120",
      CategoryDisplayName: "DINING TABLES",
      InvoiceDate: "2019-02-01 13:56:53.757",
      Year: "2019",
      Month: "2",
      Week: "1",
      ChartColor: "#F9D4D7",
      Sales: "1.00",
      Revenue: "151500.00",
    },
    {
      CategoryID: "121",
      CategoryDisplayName: "BEDSIDE TABLES",
      InvoiceDate: "2019-02-07 12:47:15.713",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#B9D4D2",
      Sales: "1.00",
      Revenue: "18500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2019-02-20 20:28:07.660",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "68500.00",
    },
    {
      CategoryID: "122",
      CategoryDisplayName: "COFFEE & SIDE TABLES",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#CBC3C6",
      Sales: "1.00",
      Revenue: "37500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2019-01-21 13:09:50.450",
      Year: "2019",
      Month: "1",
      Week: "4",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "23500.00",
    },
    {
      CategoryID: "123",
      CategoryDisplayName: "OTTOMAN",
      InvoiceDate: "2019-02-20 17:37:43.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D6CCCF",
      Sales: "1.00",
      Revenue: "28500.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-01-16 23:03:24.290",
      Year: "2019",
      Month: "1",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-08 11:38:32.330",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-08 11:47:26.170",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "1120.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-08 15:15:59.830",
      Year: "2019",
      Month: "2",
      Week: "2",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-17 12:48:52.287",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2060.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-20 17:37:43.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2300.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-20 20:28:07.660",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2650.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-20 20:35:06.270",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-20 20:42:30.350",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "1.00",
      Revenue: "2130.00",
    },
    {
      CategoryID: "124",
      CategoryDisplayName: "CUSHIONS",
      InvoiceDate: "2019-02-20 21:18:40.517",
      Year: "2019",
      Month: "2",
      Week: "3",
      ChartColor: "#D9B8BC",
      Sales: "4.00",
      Revenue: "13770.00",
    },
  ],
};
export const sumByCategory = (data = []) => {
  const grpBySum = [];
  data.reduce((acc, val) => {
    const id = val[ReportsFileds.CategoryID];
    if (!acc[id]) {
      acc[id] = {
        ...val,
        [ReportsFileds.Sales]: 0,
        [ReportsFileds.Revenue]: 0,
      };
      grpBySum.push(acc[id]);
    }
    acc[id][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
    acc[id][ReportsFileds.Revenue] += Number(val[ReportsFileds.Revenue]) ?? 0;
    return acc;
  }, {});
  return grpBySum;
};
export const sumbByCollectionCategory = (data = [], CartegoryID = null) => {
  console.log(
    "🚀 ~ file: DashboardFunctions.js ~ line 1548 ~ sumbByCollectionCategory ~ CartegoryID",
    CartegoryID
  );
  const grpBySum = [];
  //if (!CartegoryID) return grpBySum;
  data.reduce((acc, val) => {
    const catId = Number(val[ReportsFileds.CategoryID]);
    const collId = Number(val[ReportsFileds.CollectionID]);
    if (catId === Number(CartegoryID)) {
      if (!acc[collId]) {
        acc[collId] = {
          ...val,
          [ReportsFileds.Sales]: 0,
          [ReportsFileds.Revenue]: 0,
        };
        grpBySum.push(acc[collId]);
      }
      acc[collId][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
      acc[collId][ReportsFileds.Revenue] +=
        Number(val[ReportsFileds.Revenue]) ?? 0;
      return acc;
    } else {
      return acc;
    }
  }, {});
  return grpBySum;
};
export const sumByCollection = (data = []) => {
  const grpBySum = [];
  data.reduce((acc, val) => {
    const id = val[ReportsFileds.CollectionID];
    if (!acc[id]) {
      acc[id] = {
        ...val,
        [ReportsFileds.Sales]: 0,
        [ReportsFileds.Revenue]: 0,
      };
      grpBySum.push(acc[id]);
    }
    acc[id][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
    acc[id][ReportsFileds.Revenue] += Number(val[ReportsFileds.Revenue]) ?? 0;
    return acc;
  }, {});
  return grpBySum;
};

export const sumByProduct = (data = [], CategoryID = null) => {
  // console.log(
  //   "🚀 ~ file: DashboardFunctions.js ~ line 1548 ~ sumByProduct ~ CategoryID",
  //   CategoryID
  // );
  const grpBySum = [];
  //if (!CategoryID) return grpBySum;
  data.reduce((acc, val) => {
    const catID = val[ReportsFileds.CategoryID];
    const itemId = val[ReportsFileds.ItemID];
    if (Number(catID) === Number(CategoryID)) {
      if (!acc[itemId]) {
        acc[itemId] = {
          ...val,
          [ReportsFileds.Sales]: 0,
          [ReportsFileds.Revenue]: 0,
        };
        grpBySum.push(acc[itemId]);
      }
      acc[itemId][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
      acc[itemId][ReportsFileds.Revenue] +=
        Number(val[ReportsFileds.Revenue]) ?? 0;
      return acc;
    } else {
      return acc;
    }
  }, {});
  return grpBySum;
};

export const sumByProductColleciton = (data = [], CollectionID = null) => {
  const grpBySum = [];
  data.reduce((acc, val) => {
    const catID = val[ReportsFileds.CollectionID];
    const itemId = val[ReportsFileds.ItemID];
    if (Number(catID) === Number(CollectionID)) {
      if (!acc[itemId]) {
        acc[itemId] = {
          ...val,
          [ReportsFileds.Sales]: 0,
          [ReportsFileds.Revenue]: 0,
        };
        grpBySum.push(acc[itemId]);
      }
      acc[itemId][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
      acc[itemId][ReportsFileds.Revenue] +=
        Number(val[ReportsFileds.Revenue]) ?? 0;
      return acc;
    } else {
      return acc;
    }
  }, {});
  return grpBySum;
};
export const sumByProductCollecitonCategory = (
  data = [],
  CollectionID = null,
  CategoryID
) => {
  const grpBySum = [];
  data.reduce((acc, val) => {
    const catID = val[ReportsFileds.CategoryID];
    const collId = val[ReportsFileds.CollectionID];
    const itemId = val[ReportsFileds.ItemID];
    if (
      Number(collId) === Number(CollectionID) &&
      Number(catID) === Number(CategoryID)
    ) {
      if (!acc[itemId]) {
        acc[itemId] = {
          ...val,
          [ReportsFileds.Sales]: 0,
          [ReportsFileds.Revenue]: 0,
        };
        grpBySum.push(acc[itemId]);
      }
      acc[itemId][ReportsFileds.Sales] += Number(val[ReportsFileds.Sales]) ?? 0;
      acc[itemId][ReportsFileds.Revenue] +=
        Number(val[ReportsFileds.Revenue]) ?? 0;
      return acc;
    } else {
      return acc;
    }
  }, {});
  return grpBySum;
};
export const getTotalSumByKey = (data, key = null) => {
  // console.log(
  //   "🚀 ~ file: DashboardFunctions.js ~ line 1549 ~ getTotalSumByKey ~ data",
  //   data
  // );
  if (!data) return 0;
  if (data && data.length && data.length <= 0 && !key) return 0;
  return data.reduce((acc, value) => {
    return (acc += Number(value[key]) ?? 0);
  }, 0);
};

export const yearwiseCatrogrySum = (data = {}) => {
  // console.log(
  //   "🚀 ~ file: DashboardFunctions.js ~ line 1530 ~ yearwiseCatrogrySum ~ data",
  //   data
  // );
  const groupByCategory = [];
  Object.keys(data).forEach((year) => {
    const curYear = data[year] ?? [];
    curYear.reduce((acc, val) => {
      if (!acc[val[ReportsFileds.CategoryID]]) {
        acc[val[ReportsFileds.CategoryID]] = {
          [ReportsFileds.Year]: val[ReportsFileds.Year],
          [ReportsFileds.CategoryID]: val[ReportsFileds.CategoryID],
          [ReportsFileds.Sales]: 0,
          [ReportsFileds.Revenue]: 0,
        };
        groupByCategory.push(acc[val[ReportsFileds.CategoryID]]);
      }
      acc[val[ReportsFileds.CategoryID]][ReportsFileds.Sales] += Number(
        val[ReportsFileds.Sales]
      );
      acc[val[ReportsFileds.CategoryID]][ReportsFileds.Revenue] += Number(
        val[ReportsFileds.Revenue]
      );
      return acc;
    }, {});
  });
  return groupByCategory;
};

export const groupByYear = (data = []) => {
  return data.reduce((acc, val) => {
    const year = val[ReportsFileds.Year];
    if (year in acc) {
      return { ...acc, [year]: acc[year].concat(val) };
    }
    return { ...acc, [year]: [val] };
  }, {});
};

export const groupByMonth = (data = []) => {
  return data.reduce((acc, val) => {
    const month = val[ReportsFileds.Month];
    if (month in acc) {
      return { ...acc, [month]: acc[month].concat(val) };
    }
    return { ...acc, [month]: [val] };
  }, {});
};

export const groupByWeek = (data = []) => {
  return data.reduce((acc, val) => {
    const week = val[ReportsFileds.Week];
    if (week in acc) {
      return { ...acc, [week]: acc[week].concat(val) };
    }
    return { ...acc, [week]: [val] };
  }, {});
};

export const groupByDay = (data = []) => {
  return data.reduce((acc, val) => {
    const day = val[ReportsFileds.Day];
    if (day in acc) {
      return { ...acc, [day]: acc[day].concat(val) };
    }
    return { ...acc, [day]: [val] };
  }, {});
};

export const getTillDataReportSummary = (data = {}) => {
  const retData = [];
  const groupByCategory = yearwiseCatrogrySum(data);
  // console.log(
  //   "🚀 ~ file: DashboardFunctions.js ~ line 1563 ~ getTillDataReportSummary ~ groupByCategory",
  //   groupByCategory
  // );
  const grpByYear = groupByYear(groupByCategory);
  // console.log(
  //   "🚀 ~ file: DashboardFunctions.js ~ line 1586 ~ grpByYear ~ grpByYear",
  //   grpByYear
  // );
  Object.keys(grpByYear).forEach((year) => {
    const yearData = grpByYear[year] ?? [];
    const obj = {
      [ReportsFileds.Year]: year,
    };
    yearData.forEach((ele) => {
      obj[`${ele[ReportsFileds.CategoryID]}_${ReportsFileds.Sales}`] = Number(
        ele[ReportsFileds.Sales]
      );
      obj[`${ele[ReportsFileds.CategoryID]}_${ReportsFileds.Revenue}`] = Number(
        ele[ReportsFileds.Revenue]
      );
    });
    retData.push(obj);
  });
  return retData;
};
