import { API_ENDPOINTS } from "../Utils/Constants";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import OrderEdit from "./OrderEdit";
import OrderList from "./OrderList";

const Orders = {
  name: API_ENDPOINTS.Orders,
  list: OrderList,
  edit: OrderEdit,
  icon: ShoppingCartIcon,
  options: { label: "Orders", hasSubMenu: true },
};

export default Orders;
