import React from "react";
import { List } from "react-admin";
import ImageGridList from "../CommonComponents/ImageGridList";
import ImageGridPagination, {
  ImageRowPerPage,
} from "../CommonComponents/ImageGridList/ImageGridPagination";
import ImageListToolbar from "../CommonComponents/ImageListToolbar";
import { CategoryFields } from "../Utils/Constants";

const CategoryList = (props) => {
  return (
    <List
      {...props}
      actions={
        <ImageListToolbar
          fields={[
            CategoryFields.SortOrder,
            CategoryFields.Name,
            CategoryFields.Status,
          ]}
        />
      }
      bulkActionButtons={false}
      exporter={false}
      perPage={ImageRowPerPage()}
      pagination={<ImageGridPagination />}
      sort={{ field: CategoryFields.SortOrder, order: "ASC" }}
    >
      <ImageGridList titleSource={CategoryFields.Name} />
    </List>
  );
};

export default CategoryList;
