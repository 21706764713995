import {
  //  choices,
  maxLength,
  minLength,
  minValue,
  regex,
  required,
} from "react-admin";
import { ItemGroupFields, StockType } from "../Utils/Constants";
const envDev = process.env && process.env.NODE_ENV === "development";

const readyForDeliveryValidate = (value, allValues) => {
  const makingDays = allValues[ItemGroupFields.MakingDays] ?? 0;
  if (value <= makingDays) {
    return "Must be greater then Making Days";
  }
};

const deliveryDaysValidate = (value, allValues) => {
  const readyForShippingDays =
    allValues[ItemGroupFields.ReadyForDeliveryDay] ?? 1;
  if (value <= readyForShippingDays) {
    return "Must be greater then Ready For Delivery";
  }
  return undefined;
};

const matchHSN = (value, allValues) => {
  const hsn = allValues[ItemGroupFields.HSN] ?? null;

  if (hsn) {
    const pattern = `^${hsn}`;
    // console.log(
    //   "🚀 ~ file: ItemValidations.js ~ line 24 ~ matchHSN ~ hsn",
    //   hsn,
    //   pattern,
    //   value,
    //   RegExp(pattern, "g").test(value)
    // );
    if (!RegExp(pattern, "g").test(value)) {
      return "Staring digits must match HSN";
    }
  }
  return undefined;
};

const igstValidate = (value, allValues) => {
  const cgst = Number(allValues[ItemGroupFields.CGSTPercent] ?? 0);
  const sgst = Number(allValues[ItemGroupFields.SGSTPercent] ?? 0);
  const igst = Number(value ?? 0);
  if (igst !== cgst + sgst) {
    return "Must be total of CGST + SGST";
  }
  return undefined;
};
const choiceValidation = (value, _) => {
  const choices = StockType.map(({ id }) => id);
  // console.log(
  //   "🚀 ~ file: ItemValidations.js ~ line 58 ~ choiceValidations ~ choices",
  //   value,
  //   choices
  // );
  if (!choices.includes(value)) {
    return "Invalid stock type choose from list";
  }
  return undefined;
};
const checkCode = (value, allValues, indx) => {
  // console.log(
  //   "🚀 ~ file: ItemValidations.js ~ line 149 ~ checkCode ~ value,allValues",
  //   value,
  //   allValues,
  //   indx
  // );
  if (
    allValues &&
    allValues[ItemGroupFields.Variants] &&
    Array.isArray(allValues[ItemGroupFields.Variants])
  ) {
    const filteredValues = [];
    allValues[ItemGroupFields.Variants].forEach((e, i) => {
      // console.log(
      //   "🚀 ~ file: ItemValidations.js ~ line 82 ~ checkCode ~ e, i",
      //   e,
      //   i
      // );
      if (e && e[ItemGroupFields.Code] && e[ItemGroupFields.Code] === value) {
        return filteredValues.push(i + 1);
      }
    });
    // console.log(
    //   "🚀 ~ file: ItemValidations.js ~ line 83 ~ checkCode ~ filteredValues",
    //   filteredValues
    // );
    if (filteredValues.length > 1)
      return `Code cannot be same for variant no : ${filteredValues.join(
        ", "
      )}`;
  }
  return undefined;
};
export const companyValidation = [required()];
export const nameValidation = [required(), minLength(2), maxLength(150)];
export const displayNameValidation = [required(), minLength(2), maxLength(255)];
export const descrtptionValidation = [
  required(),
  minLength(envDev ? 2 : 100),
  maxLength(5000),
];
export const specificationValitdation = [
  required(),
  minLength(envDev ? 2 : 100),
  maxLength(5000),
];
export const categoryValidation = [required()];
export const statusValidation = [required()];
export const sortOrderValidation = [required(), minValue(1)];
export const stockTypeValidation = [
  required(),
  choiceValidation,
  // choices(
  //   StockType.map(({ id }) => `${id}`),
  //   "Invalid stock type choose from list"
  // ),
];
export const minStockValidation = [
  required(),
  minValue(0),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
];
export const maxOrderValidation = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
  minValue(0),
];
export const makingDaysValidation = [
  required(),
  minValue(-1),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
];
export const readyForDeliveryValidation = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
  minValue(1),
  readyForDeliveryValidate,
];
export const deliveryDaysValidatiions = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
  deliveryDaysValidate,
];
export const hsnValidation = [
  required(),
  regex(/^\d+$/, "Must be number only"),
  minLength(4, "Must be 4 digits at least"),
  maxLength(8, "Must be 8 digits or less"),
];
export const hsnCodeValidation = [
  required(),
  regex(/^\d+$/, "Must be number only"),
  minLength(8, "Must be 8 digits"),
  maxLength(8, "Must be 8 digits"),
  matchHSN,
];
export const cgstSgstValiation = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
];
export const igstValidation = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
  igstValidate,
];
export const itemCodeValidation = (props) => [
  required(),
  minLength(1),
  maxLength(50),
  (values, allValues) => checkCode(values, allValues, props),
];
export const priceValidation = [
  required(),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
];
export const stockValidation = [
  required(),
  minValue(0),
  regex(/^\d[\d.]+$/, "Must be numbers only"),
];

export const dimensionValitdation = [
  required(),
  minLength(envDev ? 2 : 10),
  maxLength(5000),
];
const imageValidation = (value, allValues) => {
  const prevImages = allValues["image"] ?? [];
  const images = value ?? [];
  // console.log(
  //   "🚀 ~ file: ItemValidations.js ~ line 117 ~ imageValidation ~ values,allValues",
  //   value,
  //   allValues
  // );
  if (prevImages.length <= 0 && images.length <= 0) {
    return "Required";
  }
  return undefined;
};
export const imageValidator = [imageValidation];

export const variantsValidator = [required("Atleast 1 varrian is required")];
