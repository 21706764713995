import { HttpError } from "react-admin";
import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import httpClient from "./HttpClient";

const getListData = async (resource, params) => {
  //console.log("🚀 ~ file: index.js ~ line 6 ~ getList: ~ params", params);
  try {
    const { sort = {}, pagination = {}, filter = {} } = params;
    let url = `${resource}?`;

    if (pagination && pagination.page && pagination.perPage) {
      url = url + `page=${pagination.page}&perpage=${pagination.perPage}&`;
    }
    if (sort && sort.field && sort.order) {
      url = url + `sort=["${sort.field}","${sort.order}"]`;
    }
    const filterKeys = filter ? Object.keys(filter) : [];
    let filters = "";
    filterKeys.forEach((ele) => {
      filters = filters + `&${ele}=${filter[ele]}`;
    });
    //console.log(`${url}${filters}`);
    if (filters.length > 0) url = url + filters;
    const {
      json: { data = [], total = 0, status = null },
    } = await httpClient(url);
    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({
          data: data,
          total: total,
        });
      } else if (status[ErrorCode] === "-01") {
        return Promise.reject(new HttpError(status[ErrorDescription], 401));
      } else {
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch (err) {
    console.log("🚀 ~ file: getListData.js ~ line 42 ~ getListData ~ err", err);
    // console.log(
    //   "🚀 ~ file: getListData.js ~ line 42 ~ getListData ~ err",
    //   err.status ? err.status : err
    // );
    if (err.status === 401) return Promise.reject(err);
    else if (err.message) return Promise.reject(err.message);
    else return Promise.reject(UNKNOWN_ERROR);
  }
};
export default getListData;
