import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import httpClient from "./HttpClient";
const updateManyData = async (resource, params) => {
  try {
    //console.log(resource, params);
    const reqData = {
      data: params.data,
    };
    let url = `${resource}/${params.ids}`;
    const {
      json: { status = null, data = [] },
    } = await httpClient(url, {
      method: "POST",
      body: JSON.stringify(reqData),
    });
    //console.log("JSON", json);
    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({ data: data });
      } else {
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch ({ message }) {
    return Promise.reject(message);
  }
};
export default updateManyData;
