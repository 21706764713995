import { API_ENDPOINTS, SkipFromMenu } from "../Utils/Constants";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import CategoryList from "./CategoryList";

const Categories = {
  name: API_ENDPOINTS.Categories,
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  options: { label: "Categories", [SkipFromMenu]: true },
};

export default Categories;
