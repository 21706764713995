import React from "react";
import { ListButton, TopToolbar } from "react-admin";

const PincodeTopActions = ({ basePath }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
    </TopToolbar>
  );
};

export default PincodeTopActions;
