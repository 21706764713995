export const createRecord = async (
  mutate,
  values,
  resource,
  onSuccess,
  onFailure
) => {
  try {
    await mutate(
      {
        type: "create",
        resource: resource,
        payload: { data: values },
      },
      {
        returnPromise: true,
        onSuccess: onSuccess,
        onFailure: onFailure,
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: CreateUpdateRecord.js ~ line 22 ~ error", error);
    if (error?.validations) {
      return error?.validations;
    }
    return Promise.reject(error);
  }
};

export const updateRecord = async (
  mutate,
  values,
  resource,
  onSuccess,
  onFailure
) => {
  try {
    await mutate(
      {
        type: "update",
        resource: resource,
        payload: { data: values, id: values?.id },
      },
      {
        returnPromise: true,
        onSuccess: onSuccess,
        onFailure: onFailure,
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: CreateUpdateRecord.js ~ line 51 ~ error", error);
    if (error?.validations) {
      return error?.validations;
    }
    return Promise.reject(error);
  }
};
