import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useRecordContext } from "react-admin";
import AvatarField from "../CommonComponents/AvatarField";
import { ArchitectFields } from "../Utils/Constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));
const ArchitectTitleComponent = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  return (
    <div className={classes.root}>
      <AvatarField
        src={record[ArchitectFields.image]}
        srcSet={record[ArchitectFields.srcSet]}
        className={classes.avatar}
      />
      {record[ArchitectFields.Title]}
    </div>
  );
};

ArchitectTitleComponent.propTypes = {
  label: PropTypes.string,
};
ArchitectTitleComponent.defaultProps = { label: "Title" };
export default ArchitectTitleComponent;
