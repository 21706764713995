import React, { useEffect, useState } from "react";
import {
  ImageInput,
  ImageField,
  FormDataConsumer,
  useRefresh,
  fetchStart,
  fetchEnd,
  useNotify,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageResponsive from "./ImageResponsive";
import AlertDialog from "../CommonComponents/AlertDialog";
import { useDispatch } from "react-redux";
import { imageValidator } from "./ItemValidations";
import { useFormState } from "react-final-form";
import { TabLabels } from "./TabsLabels";
import {
  API_ENDPOINTS,
  ErrorCode,
  ErrorDescription,
  UNKNOWN_ERROR,
} from "../Utils/Constants";
import httpClient from "../DataProvider/HttpClient";
const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "1.25em",
      fontWeight: "bold",
    },
    "& .previews": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  removeButton: {
    position: "relative",
    "& .MuiIconButton-root": {
      position: "absolute",
      right: 0,
    },
  },
});
const ImagesTab = ({ setErrors, ...props }) => {
  //console.log("🚀 ~ file: ImagesTab.js ~ line 30 ~ ImagesTab ~ props", props);
  const classes = useStyles();
  const { errors, submitErrors } = useFormState();
  useEffect(() => {
    const curError = submitErrors ?? errors;

    if (curError && curError["images"]) {
      if (setErrors)
        setErrors((prev) => ({ ...prev, [TabLabels.ImagesTabLabel]: true }));
    } else {
      if (setErrors) {
        setErrors((prev) => ({ ...prev, [TabLabels.ImagesTabLabel]: false }));
      }
    }
  }, [errors, submitErrors, setErrors]);
  const [selectedImage, setSelecedImage] = useState({
    productId: props.record.id,
    imageId: 0,
  });

  const [showDialog, setShowDialog] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  //const { loading = 0 } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const onDelete = async ({
    selectedImage: { productId, imageId } = { productId: null, imageId: null },
  }) => {
    console.log(
      "🚀 ~ file: ImagesTab.js ~ line 49 ~ onDelete ~ props",
      productId,
      imageId
      //loading
    );
    setShowDialog(false);
    if (productId && imageId) {
      dispatch(fetchStart());
      const url = `${API_ENDPOINTS.Items}/${productId}/${imageId}`;
      console.log("🚀 ~ file: ImagesTab.js ~ line 78 ~ onDelete ~ url", url);
      const {
        json: { status = null },
      } = await httpClient(url, { method: "DELETE" });
      if (status && status[ErrorCode] && status[ErrorDescription]) {
        if (status[ErrorCode] === "00") {
          notify("Image deleted", { type: "success" });
          refresh();
        } else {
          notify(status[ErrorDescription], { type: "error" });
        }
      } else {
        notify(UNKNOWN_ERROR, "error");
      }

      dispatch(fetchEnd());
      //setIsLoading(true);
      //if (loading === 0) setIsLoading(false);
      // setTimeout(() => {
      //   //refresh();
      //   dispatch(fetchEnd());
      //   //setIsLoading(false);
      // }, 3000);
    }
  };
  return (
    <>
      <FormDataConsumer>
        {({ formData }) => {
          // console.log(
          //   "🚀 ~ file: ImagesTab.js ~ line 34 ~ ImagesTab ~ formData",
          //   formData
          // );
          if (!formData.image) return null;
          return (
            <>
              <Typography variant="subtitle2" style={{ marginBottom: "1rem" }}>
                Uploded Images
              </Typography>
              <Grid
                container
                spacing={1}
                alignItems="center"
                //justifyContent="center"
              >
                {formData.image.map((url, indx) => {
                  const srcScet =
                    formData.srcSet &&
                    Array.isArray(formData.srcSet) &&
                    formData.srcSet.length > indx
                      ? formData.srcSet[indx]
                      : null;
                  return (
                    <Grid item key={indx} lg={3} sm={6}>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            width: "100%",
                            textAlign: "right",
                            background: "rgba(0,0,0,0.75)",
                          }}
                        >
                          <IconButton
                            size="small"
                            style={{ color: "white" }}
                            onClick={() => {
                              var imageId = url.match(/\/[0-9]+\//g);
                              if (imageId.length > 0) {
                                imageId = imageId[0].substring(
                                  1,
                                  imageId[0].length - 1
                                );
                              }
                              console.log(
                                "🚀 ~ file: ImagesTab.js ~ line 91 ~ ImagesTab ~ imageId",
                                formData.id,
                                imageId
                              );
                              setSelecedImage((prev) => ({
                                ...prev,
                                //productId: formData.id,
                                imageId: imageId,
                              }));
                              setShowDialog(true);
                              // console.log(
                              //   "ImageID",
                              //   imageId,
                              //   "ProductId",
                              //   productId
                              // );
                              //deleteImage(productId, imageId);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <ImageResponsive
                          style={{
                            //maxHeight: "10rem",
                            //maxWidth: "10rem",
                            width: "100%",
                            height: "auto",
                            aspectRatio: "4:3",
                            //objectFit: "cover",
                          }}
                          alt="indx"
                          src={url}
                          maxSize="100%"
                          //sizes="100px"
                          srcSet={srcScet}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        }}
      </FormDataConsumer>
      <ImageInput
        source="images"
        label="Upload New Images"
        classes={{
          root: classes.root,
          preview: classes.preview,
          removeButton: classes.removeButton,
        }}
        multiple={true}
        accept=".png,.jpeg,.jpg"
        validate={imageValidator}
      >
        <ImageField />
      </ImageInput>
      <AlertDialog
        isOpen={showDialog}
        title="Delete Image"
        content="Are you sure you want to delete image ? <br /> <span style='color:red'>This cannot be reversed</span>"
        okTitle="Yes"
        onOkClick={onDelete}
        cancelTItle="No"
        showCancel={true}
        onCancelClick={() => {
          setShowDialog(false);
        }}
        selectedImage={selectedImage}
      />
    </>
  );
};

export default ImagesTab;
