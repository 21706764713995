import React, { useCallback } from "react";
import {
  Create,
  SimpleForm,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import EditTopActions from "../CommonComponents/EditTopActions";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import { API_ENDPOINTS, SwatchFields } from "../Utils/Constants";
import SwatchCommonInput from "./SwatchCommonInput";

const SwatchCreate = (props) => {
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirect = useRedirect();

  const saveRecord = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "create",
            resource: API_ENDPOINTS.Swatches,
            payload: { data: values },
          },
          {
            returnPromise: true,
            onSuccess: ({ data }) => {
              if (data) {
                notify(
                  `Swatch [ ${data[SwatchFields.Name]} ] Created`,
                  "success"
                );
              } else {
                notify("Swatch Created", "success");
              }
              redirect(`/${API_ENDPOINTS.Swatches}`);
            },
            onFailure: (err) => {
              if (!err.validations) {
                notify(`Error: ${err.message ? err.message : err}`, "error");
              }
            },
          }
        );
      } catch (error) {
        if (error && error.validations) {
          return error.validations;
        } else {
          return Promise.reject(error);
        }
      }
    },
    [mutate, notify, redirect]
  );
  return (
    <Create
      {...props}
      title="Create Swatch"
      actions={<EditTopActions showCreate={false} />}
    >
      <SimpleForm
        redirect={false}
        save={saveRecord}
        toolbar={<SaveToolBar />}
        variant="outlined"
        initialValues={{
          [SwatchFields.IsInclusive]: 1,
          [SwatchFields.isStockCheck]: 0,
          [SwatchFields.Status]: 0,
        }}
      >
        <SwatchCommonInput />
      </SimpleForm>
    </Create>
  );
};

export default SwatchCreate;
