import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import {
  API_ENDPOINTS,
  OrderFields,
  OrderStatusConst,
  OrderStatusConstAll,
  UserFields,
} from "../Utils/Constants";

import OrderItemsExpanded from "./OrderItemsExpanded";

const OrderFilters = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Order Status"
      source={OrderFields.Status}
      choices={OrderStatusConstAll}
      allowEmpty={false}
      variant="outlined"
      alwaysOn
    />
    <ReferenceInput
      source={OrderFields.UserID}
      reference={API_ENDPOINTS.Users}
      label="User"
      variant="outlined"
    >
      <AutocompleteInput
        optionText={(choice) => (choice.UserID ? `${choice.UserID}` : "")}
        allowEmpty={false}
      />
    </ReferenceInput>
    <TextInput
      label="Order No"
      source={OrderFields.OrderID}
      variant="outlined"
    />
    <TextInput
      label="Mobile"
      source={OrderFields.ContactMobile}
      variant="outlined"
    />
  </Filter>
);
const OrderList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<OrderFilters />}
      filterDefaultValues={{ [OrderFields.Status]: "-1" }}
    >
      <Datagrid
        rowClick={(id, basepath, record) => {
          //console.log("Datagrid", record);
          return record[OrderFields.Editable] ? "edit" : null;
        }}
        expand={<OrderItemsExpanded />}
      >
        <ReferenceField
          reference={API_ENDPOINTS.Users}
          source={OrderFields.UserID}
          label="User"
          link={false}
        >
          <TextField source={UserFields.UserID} />
        </ReferenceField>
        <TextField source={OrderFields.OrderID} label="Order No" />
        <DateField
          source={OrderFields.OrderDate}
          locales="fr-FR"
          label="Date"
        />
        <TextField source={OrderFields.ContactPerson} label="Contact" />
        <TextField source={OrderFields.ContactMobile} label="Mobile" />
        <TextField source={OrderFields.ShippinAddress} label="Address" />
        <NumberField source={OrderFields.TotalItems} label="Items" />
        <NumberField
          source={OrderFields.FinalAmount}
          options={{ style: "currency", currency: "INR" }}
        />
        <SelectField source={OrderFields.Status} choices={OrderStatusConst} />
      </Datagrid>
    </List>
  );
};

export default OrderList;
