import React, { useState } from "react";
import {
  Create,
  FormTab,
  TabbedForm,
  useGetList,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
//import BackdropLoader from "../CommonComponents/BackdropLoader";
import EditTopActions from "../CommonComponents/EditTopActions";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import ImagesTab from "./ImagesTab";
import InfoTab from "./InfoTab";
import StockInfo from "./StockInfo";
import VariantsTab from "./VariantsTab";
//import { useSelector } from "react-redux";
import { TabLabels } from "./TabsLabels";
import { API_ENDPOINTS, ItemGroupFields } from "../Utils/Constants";
import { createRecord } from "../Utils/CreateUpdateRecord";
import { useEffect } from "react";
import { onFailure, onSuccess } from "./ItemFunctions";

const ItemCreate = (props) => {
  //const { loading = 0 } = useSelector((state) => state.admin);
  const notify = useNotify();
  const redierct = useRedirect();
  const [mutate] = useMutation();
  const [maxSort, setMaxSort] = useState(null);
  const { data, loaded } = useGetList(
    API_ENDPOINTS.Items,
    { page: 1, perPage: 1 },
    { field: ItemGroupFields.SortOrder, order: "DESC" }
  );
  useEffect(() => {
    if (loaded && data) {
      // console.log(
      //   "🚀 ~ file: ItemCreate.js ~ line 31 ~ ItemCreate ~ data",
      //   data
      // );
      const dataArray = Object.values(data);
      if (dataArray.length > 0) {
        // console.log(
        //   "🚀 ~ file: ItemCreate.js ~ line 43 ~ useEffect ~ dataArray",
        //   dataArray[0][ItemGroupFields.SortOrder]
        // );
        if (dataArray[0][ItemGroupFields.SortOrder]) {
          setMaxSort(Number(dataArray[0][ItemGroupFields.SortOrder]) + 1);
        }
      }
    }
  }, [data, loaded]);
  // const onSuccess = ({ data }) => {
  //   notify("Item Created ", { type: "success" });
  // };
  // const onFailure = (error) => {
  //   console.log(
  //     "🚀 ~ file: ItemCreate.js ~ line 32 ~ onFailure ~ error",
  //     error
  //   );
  //   if (!error?.validations)
  //     notify(`Error :${error.message ?? error}`, { type: "error" });
  //   else {
  //     const validation = { ...error.validations };
  //     console.log(
  //       "🚀 ~ file: ItemCreate.js ~ line 67 ~ onFailure ~ validation",
  //       validation
  //     );
  //     let imgErrMsg = null;
  //     if (validation) {
  //       const keys = Object.keys(validation).filter((e) =>
  //         new RegExp("^images.([\\d)]+)$", "g").test(e)
  //       );
  //       keys.forEach((e, i) => {
  //         if (imgErrMsg === null) {
  //           imgErrMsg = `Image [${i + 1}] : ${validation[e]}`;
  //         } else {
  //           imgErrMsg += `\nImage [${i + 1}] : ${validation[e]}`;
  //         }
  //       });
  //       console.log(
  //         "🚀 ~ file: ItemCreate.js ~ line 82 ~ onFailure ~ keys",
  //         imgErrMsg
  //       );
  //     }
  //     if (imgErrMsg) {
  //       notify(imgErrMsg, { type: "error", multiLine: true });
  //     } else {
  //       notify("Check validaion errors", { type: "error", multiLine: true });
  //     }
  //   }
  // };
  const saveRecord = async (values) => {
    const variants = [...values[ItemGroupFields.Variants]];
    const newVariants = variants.map((e) => JSON.stringify(e));
    values[ItemGroupFields.Variants] = [...newVariants];

    return await createRecord(
      mutate,
      values,
      API_ENDPOINTS.Items,
      (retData) => {
        onSuccess(retData, notify, "Item Created");
        redierct(`/${API_ENDPOINTS.Items}`);
      },
      (error) => {
        onFailure(error, notify);
      }
    );
  };
  const [errors, setErrors] = useState({
    [TabLabels.InfoLabel]: false,
    [TabLabels.StockInfoLabel]: false,
  });
  const errorStyle = (field) => ({
    color: errors[field] ? "red" : "inherit",
  });
  return (
    <Create
      {...props}
      title="Create Item"
      actions={<EditTopActions showCreate={false} />}
    >
      <TabbedForm
        save={saveRecord}
        toolbar={<SaveToolBar />}
        initialValues={{
          [ItemGroupFields.Status]: "1",
          [ItemGroupFields.IsInclusive]: "1",
          [ItemGroupFields.ProductType]: 1,
          [ItemGroupFields.SortOrder]: maxSort ?? 1,
        }}
      >
        <FormTab
          label={TabLabels.InfoLabel}
          style={errorStyle(TabLabels.InfoLabel)}
        >
          <InfoTab setErrors={setErrors} {...props} />
        </FormTab>
        <FormTab
          label={TabLabels.StockInfoLabel}
          style={errorStyle(TabLabels.StockInfoLabel)}
        >
          <StockInfo setErrors={setErrors} />
        </FormTab>
        <FormTab
          label={TabLabels.ImagesTabLabel}
          style={errorStyle(TabLabels.ImagesTabLabel)}
        >
          <ImagesTab setErrors={setErrors} />
        </FormTab>
        <FormTab
          label={TabLabels.VariantsTabLabel}
          style={errorStyle(TabLabels.VariantsTabLabel)}
        >
          <VariantsTab setErrors={setErrors} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ItemCreate;
