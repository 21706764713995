import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import httpClient from "./HttpClient";
const getOneData = async (resource, params) => {
  try {
    const url = `${resource}/${params.id}`;

    httpClient(url).then(({ json }) => ({ data: json.data }));
    const {
      json: { status = null, data = {} },
    } = await httpClient(url);

    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({ data: data });
      } else {
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch ({ message }) {
    return Promise.reject(message);
  }
};

export default getOneData;
