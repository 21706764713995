import React from "react";
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useNotify,
  useRefresh,
} from "react-admin";

import EditTopActions from "../../CommonComponents/EditTopActions";
import SaveToolBar from "../../CommonComponents/SaveToolBar";
import TitleComponent from "../../CommonComponents/TitleComponent";
import { TopSlidesFields } from "../../Utils/Constants/TopSlidesFields";
import TopSlideCommonInputs from "./TopSlideCommonInputs";
import { makeStyles } from "@material-ui/core/styles";
import ImageResponsiveComponent from "../../CommonComponents/ImageResponsiveComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "200px",
    [theme.breakpoints.up(599)]: {
      width: "100%",
      maxWidth: "300px",
    },
  },
}));
const TopSlideEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = ({ data }) => {
    if (data)
      notify(`Banner [ ${data[TopSlidesFields.Headline]}] Updated`, "success");
    else notify("Banner Updated");

    refresh();
  };
  return (
    <Edit
      {...props}
      title={
        <TitleComponent source={TopSlidesFields.Headline} title="Edit - " />
      }
      actions={<EditTopActions />}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
    >
      <SimpleForm
        redirect={false}
        toolbar={
          <SaveToolBar
            showDelete={true}
            deleteTitleSource={TopSlidesFields.Headline}
          />
        }
        variant="outlined"
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const imgPath =
              formData[TopSlidesFields.Image].length > 0
                ? formData[TopSlidesFields.Image][0]
                : null;
            return (
              <ImageResponsiveComponent
                className={classes.root}
                src={imgPath}
                srcSet={formData[TopSlidesFields.SrcSet]}
                alt=""
              />
            );
          }}
        </FormDataConsumer>
        <TopSlideCommonInputs />
      </SimpleForm>
    </Edit>
  );
};

export default TopSlideEdit;
