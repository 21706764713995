import React, { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import TitleComponent from "../CommonComponents/TitleComponent";
import { API_ENDPOINTS, PincodeFields } from "../Utils/Constants";
import PincodeTopActions from "./PincodeTopActions";
import { PincodeValidations } from "./PincodeValidations";

const PincodeEdit = (props) => {
  const dataprovider = useDataProvider();
  const [mutate] = useMutation();
  const refresh = useRefresh();
  const notify = useNotify();

  const [stateList, setStateList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      try {
        const res = await dataprovider.getList(API_ENDPOINTS.StateList, {});

        setStateList(res.data);
      } catch (error) {
        notify("Error In Getting States", "error");
      }

      setLoading(false);
    };
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const saveRecord = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: API_ENDPOINTS.Pincodes,
            payload: { data: values, id: values.id },
          },
          {
            returnPromise: true,
            onSuccess: ({ data }) => {
              if (data) {
                notify("Pincode Updated", "success");
                refresh();
              }
            },
            onFailure: (err) => {
              if (!err.validations)
                notify(`Error: ${err.message ? err.message : err}`, "error");
            },
          }
        );
      } catch (error) {
        if (error && error.validations) {
          return error.validations;
        } else {
          return Promise.reject(error);
        }
      }
    },
    [mutate, refresh, notify]
  );
  return (
    <Edit
      {...props}
      title={
        <TitleComponent source={PincodeFields.Pincode} title="Pincode - " />
      }
      actions={<PincodeTopActions />}
    >
      <SimpleForm
        toolbar={<SaveToolBar />}
        redirect={false}
        save={saveRecord}
        mutationMode="pessimistic"
      >
        <TextInput
          source={PincodeFields.Pincode}
          variant="outlined"
          validate={PincodeValidations.pinCode}
        />

        <SelectInput
          choices={stateList}
          source={PincodeFields.StateCode}
          optionText={PincodeFields.State}
          optionValue={PincodeFields.StateCode}
          variant="outlined"
          validate={PincodeValidations.pinCode}
          disabled={isLoading}
        />

        <TextInput
          source={PincodeFields.City}
          variant="outlined"
          validate={PincodeValidations.pinCity}
        />
      </SimpleForm>
    </Edit>
  );
};

export default PincodeEdit;
