import React from "react";
import { List } from "react-admin";
import ImageListToolbar from "../CommonComponents/ImageListToolbar";
import { CollectionFields } from "../Utils/Constants/CollectionFields";
import ImageGridList from "../CommonComponents/ImageGridList";
import ImageGridPagination, {
  ImageRowPerPage,
} from "../CommonComponents/ImageGridList/ImageGridPagination";
const CollectionList = (props) => {
  return (
    <List
      {...props}
      actions={
        <ImageListToolbar
          fields={[
            CollectionFields.SortOrder,
            CollectionFields.Name,
            CollectionFields.Status,
          ]}
        />
      }
      bulkActionButtons={false}
      exporter={false}
      perPage={ImageRowPerPage()}
      pagination={<ImageGridPagination />}
      sort={{ field: CollectionFields.SortOrder, order: "ASC" }}
    >
      <ImageGridList titleSource={CollectionFields.Name} />
    </List>
  );
};

export default CollectionList;
