import React, { useState } from "react";
import { DashboardMenuItem, getResources, MenuItemLink } from "react-admin";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import DefaultIcon from "@material-ui/icons/ViewList";
import SubMenu from "./SubMenu";
import ordersMenu from "../Orders/ordersMenu";
import { makeStyles } from "@material-ui/core/styles";
import {
  API_ENDPOINTS,
  MENU_LOCATIONS,
  SkipFromMenu,
} from "../Utils/Constants";
import { useLocation } from "react-router-dom";
import CouponsMenu from "./CouponsMenu";
import HomePageMenu from "./HomePageMenu";
import HomeIcon from "@material-ui/icons/Home";
import StoreIcon from "@material-ui/icons/Store";
import { useEffect } from "react";
import ProductsMenu from "./ProductsMenu";
import SwacthesMenu from "./SwacthesMenu";
//import ApartmentIcon from "@material-ui/icons/Apartment";
//import ArchitectsMenu from "./ArchitectsMenu";
const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    borderLeft: "5px solid #ff8f2e",
  },
}));

const Menu = (props) => {
  const classes = useStyles();
  const { onMenuClick } = props;
  const location = useLocation();
  const loc = location.pathname.split("/", 2);
  //console.log("🚀 ~ file: index.js ~ line 4 ~ Menu ~ props", props);
  const resources = useSelector(getResources);
  //console.log("🚀 ~ file: index.js ~ line 33 ~ Menu ~ resources", resources);
  const isOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const [openState, setOpenState] = useState({
    ordersMenu: false,
    couponsMenu: false,
    homeMenu: false,
  });
  const [subMenuActiveState, setSubMenuActiveState] = useState({
    orders: null,
  });
  const handleToggle = (menu) => {
    setOpenState((prev) => ({ [menu]: !prev[menu] }));
  };
  useEffect(() => {
    //console.log("🚀 ~ file: index.js ~ line 28 ~ Menu ~ loc", loc);
    handleToggle(loc[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} />
      <Divider />
      {resources.map((res) => {
        // console.log(
        //   "🚀 ~ file: index.js ~ line 58 ~ {resources.map ~ res",
        //   res
        // );

        if (!res.hasList) return null;
        if (res.options && res.options[SkipFromMenu]) return null;
        if (res.name === API_ENDPOINTS.Coupons) {
          return (
            <CouponsMenu
              key={res.name}
              handleToggle={() => handleToggle(MENU_LOCATIONS.Coupons)}
              isOpen={openState[MENU_LOCATIONS.Coupons]}
              sidebarIsOpen={isOpen}
              name={
                res.options && res.options.label ? res.options.label : res.name
              }
              icon={res.icon ? <res.icon /> : <DefaultIcon />}
              onMenuClick={onMenuClick}
              classes={classes}
            />
          );
        }
        if (res.name === API_ENDPOINTS.Orders) {
          return (
            <SubMenu
              key={res.name}
              handleToggle={() => handleToggle(MENU_LOCATIONS.Orders)}
              isOpen={openState[MENU_LOCATIONS.Orders]}
              sidebarIsOpen={isOpen}
              name={
                res.options && res.options.label ? res.options.label : res.name
              }
              icon={res.icon ? <res.icon /> : <DefaultIcon />}
              active={location.pathname === `/${API_ENDPOINTS.Orders}`}
            >
              {Object.keys(ordersMenu).map((item) => {
                const curItem = ordersMenu[item];
                if (!curItem) return null;
                return (
                  // <MenuItemLink />
                  <MenuItemLink
                    key={item}
                    to={{ ...curItem }}
                    primaryText={curItem.label}
                    onClick={() => {
                      setSubMenuActiveState((prev) => ({ orders: item }));
                      if (onMenuClick) onMenuClick();
                    }}
                    sidebarIsOpen={isOpen}
                    activeClassName={
                      subMenuActiveState.orders === item ? classes.active : null
                    }
                  />
                );
              })}
            </SubMenu>
          );
        }
        return (
          <MenuItemLink
            key={res.name}
            to={`/${res.name}`}
            primaryText={(res.options && res.options.label) || res.name}
            leftIcon={res.icon ? <res.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpen}
            activeClassName={classes.active}
          />
        );
      })}
      <Divider />
      <HomePageMenu
        handleToggle={() => {
          handleToggle(MENU_LOCATIONS.HomePage);
        }}
        isOpen={openState[MENU_LOCATIONS.HomePage]}
        sidebarIsOpen={isOpen}
        name="HomePage"
        icon={<HomeIcon />}
        classes={classes}
        onMenuClick={onMenuClick}
      />
      <Divider />
      <ProductsMenu
        handleToggle={() => {
          handleToggle(MENU_LOCATIONS.Products);
        }}
        isOpen={openState[MENU_LOCATIONS.Products]}
        sidebarIsOpen={isOpen}
        name="Products"
        icon={<StoreIcon />}
        classes={classes}
        onMenuClick={onMenuClick}
      />
      <Divider />
      <SwacthesMenu
        handleToggle={() => {
          handleToggle(MENU_LOCATIONS.Swatches);
        }}
        isOpen={openState[MENU_LOCATIONS.Swatches]}
        sidebarIsOpen={isOpen}
        name="Swatches"
        icon={<StoreIcon />}
        classes={classes}
        onMenuClick={onMenuClick}
      />
      {/* <Divider />
      <ArchitectsMenu
        handleToggle={() => {
          handleToggle(MENU_LOCATIONS.Architects);
        }}
        isOpen={openState[MENU_LOCATIONS.Architects]}
        sidebarIsOpen={isOpen}
        name="Architects"
        icon={<ApartmentIcon />}
        classes={classes}
        onMenuClick={onMenuClick}
      /> */}
    </div>
  );
};

export default withRouter(Menu);
