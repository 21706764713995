import React, { useCallback } from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import SaveToolBar from "../CommonComponents/SaveToolBar";
import TitleComponent from "../CommonComponents/TitleComponent";
import {
  API_ENDPOINTS,
  CompanyFileds,
  CouponFields,
  CouponTypes,
  CouponTypesGridValues,
} from "../Utils/Constants";
import { CouponValidations } from "./CouponValidation";

const CouponEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  // const onSuccess = ({ data }) => {
  //   //console.log("🚀 ~ file: CouponEdit.js ~ line 9 ~ onSucces ~ data", data);
  //   if (data) {
  //     notify(
  //       `Disocunt Coupon ${data[CouponFields.CouponCode]} Updated`,
  //       "info"
  //     );
  //     refresh();
  //   }
  // };
  // const onError = (err) => {
  //   notify(`Error: ${err.message ? err.message : err}`, "error");
  // };
  const [mutate] = useMutation();
  const saveRecord = useCallback(
    async (values) => {
      console.log("🚀 ~ file: CouponEdit.js ~ line 46 ~ values", values);
      try {
        //Replace null with "" starts
        // Object.keys(values).forEach((key) => {
        //   if (values[key] == null) values[key] = "";
        // });
        //Replace null with "" ends
        await mutate(
          {
            type: "update",
            resource: API_ENDPOINTS.Coupons,
            payload: { data: values, id: values.id },
          },
          {
            returnPromise: true,
            onSuccess: ({ data }) => {
              //console.log("🚀 ~ file: CouponEdit.js ~ line 9 ~ onSucces ~ data", data);
              if (data) {
                notify(
                  `Disocunt Coupon ${data[CouponFields.CouponCode]} Updated`,
                  "info"
                );
                refresh();
              }
            },
            onFailure: (err) => {
              //console.log("🚀 ~ file: CouponEdit.js ~ line 67 ~ err", err);
              if (!err.validations)
                notify(`Error: ${err.message ? err.message : err}`, "error");
            },
          }
        );
      } catch (error) {
        console.log(
          "🚀 ~ file: CouponEdit.js ~ line 108 ~ saveRecord ~ error",
          error
        );
        if (error && error.validations) {
          return error.validations;
        } else {
          //notify(error, "error");
          return Promise.reject(error);
        }
        //return error;
      }
    },
    [mutate, notify, refresh]
  );

  return (
    <Edit
      title={<TitleComponent source={CouponFields.CouponCode} title="" />}
      //onSuccess={onSuccess}
      undoable={false}
      //onFailure={onError}
      {...props}
    >
      <SimpleForm toolbar={<SaveToolBar />} redirect={false} save={saveRecord}>
        <ReferenceInput
          source={CouponFields.CompanyID}
          reference={API_ENDPOINTS.Companies}
          variant="outlined"
          label="Select Company"
        >
          <SelectInput
            optionText={CompanyFileds.Name}
            variant="outlined"
            validate={CouponValidations.couponCompany}
          />
        </ReferenceInput>
        <TextInput
          source={CouponFields.CouponCode}
          variant="outlined"
          validate={CouponValidations.couponCode}
        />
        <TextInput
          source={CouponFields.CouponDescription}
          variant="outlined"
          validate={CouponValidations.couponDescrtiption}
          multiline
          fullWidth
        />
        <NumberInput
          source={CouponFields.Percentage}
          variant="outlined"
          validate={CouponValidations.couponPercentage}
        />

        <SelectInput
          source={CouponFields.CouponType}
          choices={CouponTypes}
          variant="outlined"
          validate={CouponValidations.couponType}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            // console.log(
            //   "🚀 ~ file: CouponEdit.js ~ line 63 ~ CouponEdit ~ formData",
            //   formData[CouponFields.CouponType],
            //   isUser
            // );
            if (!formData[CouponFields.CouponType]) return null;
            if (
              CouponTypesGridValues.USER_REGISTRATION ===
              formData[CouponFields.CouponType]
            ) {
              return (
                <NumberInput
                  source={CouponFields.Days}
                  variant="outlined"
                  validate={CouponValidations.couponDays}
                />
              );
            } else {
              return (
                <>
                  {formData[CouponFields.CouponType] ===
                    CouponTypesGridValues.MARKETING && (
                    <>
                      <TextInput
                        label="Team Name"
                        source={CouponFields.TeamName}
                        variant="outlined"
                        validate={CouponValidations.couponTeamName}
                        fullWidth
                      />
                      <br />
                    </>
                  )}
                  <DateInput
                    source={CouponFields.FromDate}
                    variant="outlined"
                    validate={CouponValidations.couponFromDate}
                  />
                  <br />
                  <DateInput
                    source={CouponFields.ToDate}
                    variant="outlined"
                    validate={CouponValidations.couponToDate}
                  />
                  <br />
                  <NumberInput
                    source={CouponFields.Limit}
                    variant="outlined"
                    validate={CouponValidations.couponLimit}
                  />
                </>
              );
            }
          }}
        </FormDataConsumer>
        <BooleanInput
          source={CouponFields.IsToShow}
          label="Show To User"
          format={(v) => v === "1"}
          parse={(v) => (v ? "1" : "0")}
          defaultValue="0"
        />
        <BooleanInput
          source={CouponFields.IsActive}
          format={(v) => v === "Y"}
          parse={(v) => (v ? "Y" : "N")}
          label="Is Active"
          defaultValue="Y"
        />
      </SimpleForm>
    </Edit>
  );
};

export default CouponEdit;
