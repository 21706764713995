export const OrderStatusConstAll = [
  { id: "-1", name: "All" },
  { id: "1", name: "CART" },
  { id: "2", name: "ORDER GENRATED" },
  { id: "3", name: "PAYMENT PENDING" },
  { id: "4", name: "PAYMENT FAILED" },
  { id: "5", name: "PAYMENT CANCELLED" },
  { id: "6", name: "ORDER PLACED" },
  { id: "7", name: "ORDER CANCELLED" },
  { id: "8", name: "PAYMENT REFUNDED" },
  { id: "9", name: "IN MAKING" },
  { id: "10", name: "READY FOR DELIVERY" },
  { id: "11", name: "SHIPPED" },
  { id: "12", name: "DELIVERED" },
];
export const OrderStatusConst = [
  { id: "1", name: "CART", disabled: true },
  { id: "2", name: "ORDER GENRATED", disabled: true },
  { id: "3", name: "PAYMENT PENDING", disabled: true },
  { id: "4", name: "PAYMENT FAILED", disabled: true },
  { id: "5", name: "PAYMENT CANCELLED", disabled: true },
  { id: "6", name: "ORDER PLACED" },
  { id: "7", name: "ORDER CANCELLED", disabled: true },
  { id: "8", name: "PAYMENT REFUNDED", disabled: true },
  { id: "9", name: "IN MAKING" },
  { id: "10", name: "READY FOR DELIVERY" },
  { id: "11", name: "SHIPPED" },
  { id: "12", name: "DELIVERED" },
];
export const OrderGridStatusConst = {
  1: "CART",
  2: "ORDER GENRATED",
  3: "PAYMENT PENDING",
  4: "PAYMENT FAILED",
  5: "PAYMENT CANCELLED",
  6: "ORDER PLACED",
  7: "ORDER CANCELLED",
  8: "PAYMENT REFUNDED",
  9: "IN MAKING",
  10: "READY FOR DELIVERY",
  11: "SHIPPED",
  12: "DELIVERED",
};
export const OrderStatusConstantsByName = {
  ALL: -1,
  CART: 1,
  ORDER_GENRATED: 2,
  PAYMENT_PENDING: 3,
  PAYMENT_FAILED: 4,
  PAYMENT_CANCELLED: 5,
  ORDER_PLACED: 6,
  ORDER_CANCELLED: 7,
  PAYMENT_REFUNDED: 8,
  IN_MAKING: 9,
  READY_FOR_DELIVERY: 10,
  SHIPPED: 11,
  DELIVERED: 12,
};
