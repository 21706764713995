import React from "react";
import { MenuItemLink } from "react-admin";
import { useLocation } from "react-router-dom";
import { API_ENDPOINTS } from "../Utils/Constants";
import SubMenu from "./SubMenu";
const SwacthesMenu = ({
  handleToggle,
  isOpen,
  sidebarIsOpen,
  name,
  icon,
  onMenuClick,
  classes,
}) => {
  const location = useLocation();
  // const loc = location.pathname.split("/", 2);
  // console.log(
  //   "🚀 ~ file: SwacthesMenu.js ~ line 17 ~ loc",
  //   loc,
  //   location.pathname,
  //   RegExp(`/${MENU_LOCATIONS.Swatches}$`).test(location.pathname)
  // );
  return (
    <SubMenu
      handleToggle={handleToggle}
      isOpen={isOpen}
      sidebarIsOpen={sidebarIsOpen}
      name={name}
      icon={icon}
      active={location.pathname.includes(API_ENDPOINTS.Swatches)} //{loc.length === 2 && loc[1] === MENU_LOCATIONS.Swatches}
    >
      <MenuItemLink
        to={`/${API_ENDPOINTS.SwatchesGroup}`}
        primaryText="Types"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
        isActive={(_) => {
          //console.log("🚀 ~ file: SwacthesMenu.js ~ line 47 ~ p", p);

          return (
            RegExp(`/${API_ENDPOINTS.SwatchesGroup}$`).test(
              location.pathname
            ) ||
            RegExp(`/${API_ENDPOINTS.SwatchesGroup}/create$`).test(
              location.pathname
            ) ||
            RegExp(`/${API_ENDPOINTS.SwatchesGroup}/[0-9]+$`).test(
              location.pathname
            )
          );
        }}
      />
      <MenuItemLink
        to={`/${API_ENDPOINTS.Swatches}`}
        primaryText="Swatches"
        onClick={onMenuClick}
        sidebarIsOpen={sidebarIsOpen}
        activeClassName={classes.active}
        isActive={(_) => {
          //console.log("🚀 ~ file: SwacthesMenu.js ~ line 47 ~ p", p);

          return (
            RegExp(`/${API_ENDPOINTS.Swatches}$`).test(location.pathname) ||
            RegExp(`/${API_ENDPOINTS.Swatches}/create$`).test(
              location.pathname
            ) ||
            RegExp(`/${API_ENDPOINTS.Swatches}/[0-9]+$`).test(location.pathname)
          );
        }}
      />
    </SubMenu>
  );
};

export default SwacthesMenu;
