import React from "react";
import {
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  useNotify,
  useRedirect,
  useLoading,
} from "react-admin";
import { useFormState } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
});

const SaveToolBar = (props) => {
  // console.log(
  //   "🚀 ~ file: SaveToolBar.js ~ line 5 ~ SaveToolBar ~ props",
  //   props
  // );
  const isLoading = useLoading();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const formState = useFormState();
  // console.log(
  //   "🚀 ~ file: SaveToolBar.js ~ line 43 ~ SaveToolBar ~ formState.dirty",
  //   formState.dirty
  // );
  const { showDelete = false, deleteTitleSource = null, ...rest } = props;
  const onDeleteSuccess = () => {
    notify(
      `Item [ ${
        deleteTitleSource ? rest.record[deleteTitleSource] : ""
      } ] Deleted`,
      "success"
    );

    redirect(rest.basePath);
    //setTimeout()
  };
  return (
    <Toolbar {...rest} classes={{ toolbar: classes.toolbar }}>
      <SaveButton disabled={!formState.dirty || isLoading} />
      {showDelete && (
        <DeleteWithConfirmButton
          confirmTitle={`Delete ${
            deleteTitleSource ? rest.record[deleteTitleSource] : "Item"
          } ?`}
          onSuccess={onDeleteSuccess}
        />
      )}
    </Toolbar>
  );
};

export default SaveToolBar;
