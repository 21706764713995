import { API_ENDPOINTS, SkipFromMenu } from "../Utils/Constants";
import CollectionCreate from "./CollectionCreate";
import CollectionEdit from "./CollectionEdit";
import CollectionList from "./CollectionList";

const Collections = {
  name: API_ENDPOINTS.Collections,
  list: CollectionList,
  create: CollectionCreate,
  edit: CollectionEdit,
  options: { label: "Collections", [SkipFromMenu]: true },
};

export default Collections;
