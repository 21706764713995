import { CreateButton, SortButton, TopToolbar } from "react-admin";
import React from "react";

const ImageListToolbar = ({ hasCreate = false, fields = [], ...rest }) => {
  // console.log(
  //   "🚀 ~ file: ImageListToolbar.js ~ line 5 ~ ImageListToolbar ~ hasCreate = false, fields = [], ...rest",
  //   hasCreate,
  //   fields,
  //   rest
  // );
  return (
    <TopToolbar>
      <SortButton fields={fields} />
      {hasCreate ? <CreateButton /> : null}
    </TopToolbar>
  );
};

export default ImageListToolbar;
