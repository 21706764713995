import { API_ENDPOINTS, SkipFromMenu } from "../Utils/Constants";
import ItemCreate from "./ItemCreate";
import ItemEdit from "./ItemEdit";
import ItemList from "./ItemList";
const Items = {
  name: API_ENDPOINTS.Items,
  list: ItemList,
  create: ItemCreate,
  edit: ItemEdit,
  options: { label: "Items", [SkipFromMenu]: true },
};
export default Items;
