import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/styles/makeStyles";
import SelectBox from "../../CommonComponents/SelectBox";
const useTypographyStyle = makeStyles({
  root: {
    color: "#a5a5a5",
  },
});
const ReportsCard = ({
  title = "",
  dropDownData = null,
  value = "",
  setValue,
  sales,
  revenue,
  renderText = null,
}) => {
  //console.log("🚀 ~ file: index.js ~ line 23 ~ dropDownData", dropDownData);
  const typegraphyClasses = useTypographyStyle();
  return (
    <Card>
      <Box m="10px" p="10px" display="flex">
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                color="textPrimary"
                classes={{ root: typegraphyClasses.root }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <SelectBox
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                renderValue={(v) => {
                  return renderText ? `${renderText} ${v}` : v;
                }}
              >
                {!dropDownData && <MenuItem value=""></MenuItem>}

                {dropDownData &&
                  dropDownData.map((data) => (
                    <MenuItem value={data["id"]} key={data["id"]}>
                      {data["name"]}
                    </MenuItem>
                  ))}

                {/* <MenuItem value={1}>Sketch</MenuItem>
              <MenuItem value={2}>Photoshop</MenuItem>
              <MenuItem value={3}>Framer</MenuItem> */}
              </SelectBox>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                variant="h5"
                className="yellow"
                style={{ fontWeight: 700 }}
              >
                {sales} Products Sold
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                className="green"
                style={{ fontWeight: 700 }}
              >
                ₹ {revenue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ReportsCard;
