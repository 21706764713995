import React, { useState } from "react";
import { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  useDataProvider,
  useNotify,
  EditButton,
  // TopToolbar,
  // Button,
  // useRedirect,
} from "react-admin";
import { API_ENDPOINTS, PincodeFields } from "../Utils/Constants";
//import IconEvent from "@material-ui/icons/Event";
const PincodeFilters = (props) => {
  const { statesList, isLoading, ...rest } = props;
  return (
    <Filter {...rest}>
      <TextInput
        label="Pincode"
        source={PincodeFields.Pincode}
        variant="outlined"
      />
      {/* <ReferenceInput
        source={PincodeFields.StateCode}
        reference={API_ENDPOINTS.StateList}
        variant="outlined"
        label="State"
        sort={{ field: PincodeFields.State, order: "ASC" }}
        allowEmpty={false}
        perPage={null}
      >
        
      </ReferenceInput> */}
      <SelectInput
        label="State"
        source={PincodeFields.StateCode}
        choices={statesList}
        optionText={PincodeFields.State}
        optionValue={PincodeFields.StateCode}
        variant="outlined"
        disabled={isLoading}
        resettable
      />
      <TextInput label="City" source={PincodeFields.City} variant="outlined" />
    </Filter>
  );
};
// const PincodeActions = (props) => {
//   const redirect = useRedirect();
//   return (
//     <TopToolbar>
//       {cloneElement(props.filters, { context: "button" })}
//       <Button
//         onClick={() => {
//           redirect("/pincodes/imp");
//         }}
//       >
//         <IconEvent />
//       </Button>
//     </TopToolbar>
//   );
// };
const PincodesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const dataprovider = useDataProvider();
  const notify = useNotify();
  useEffect(() => {
    const getSatates = async () => {
      // console.log(
      //   "🚀 ~ file: PincodesList.js ~ line 28 ~ getSatates ~ statesList",
      //   statesList,
      //   isLoading
      // );

      try {
        setIsLoading(true);
        const res = await dataprovider.getList(API_ENDPOINTS.StateList, {});
        // console.log(
        //   "🚀 ~ file: PincodesList.js ~ line 29 ~ getSatates ~ res",
        //   res
        // );
        setStatesList(res.data);
      } catch (error) {
        // console.log(
        //   "🚀 ~ file: PincodesList.js ~ line 90 ~ getSatates ~ error",
        //   error
        // );
        notify("Error In Getting States", "error");
      }
      setIsLoading(false);
    };
    getSatates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      filters={<PincodeFilters isLoading={isLoading} statesList={statesList} />}
      //actions={<PincodeActions />}
    >
      <Datagrid>
        <EditButton />
        <TextField source={PincodeFields.Pincode} label="Pincode" />
        <TextField source={PincodeFields.City} label="City" />
        <TextField source={PincodeFields.State} label="State" />
      </Datagrid>
    </List>
  );
};

export default PincodesList;
