import {
  API_ENDPOINTS,
  OrderFields,
  OrderStatusConstantsByName,
} from "../Utils/Constants";

const ordersMenu = {
  all: {
    label: "All",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.ALL,
    })}`,
  },
  placed: {
    label: "Placed",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.ORDER_PLACED,
    })}`,
  },
  in_making: {
    label: "In Making",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.IN_MAKING,
    })}`,
  },
  ready: {
    label: "Ready For Delivery",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.READY_FOR_DELIVERY,
    })}`,
  },
  payment_pending: {
    label: "Payment Pending",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.PAYMENT_PENDING,
    })}`,
  },
  payment_cancelled: {
    label: "Payment Cancelled",
    pathname: `/${API_ENDPOINTS.Orders}`,
    search: `sort=${OrderFields.OrderDate}&order=ASC&filter=${JSON.stringify({
      Status: OrderStatusConstantsByName.PAYMENT_CANCELLED,
    })}`,
  },
};
export default ordersMenu;
