import React, { useState } from "react";
import { Edit, NumberInput, SimpleForm } from "react-admin";
import ExpandedToolbar from "../CommonComponents/ExpandedToolbar";
import TitleComponent from "../CommonComponents/TitleComponent";
import { CompanyFileds } from "../Utils/Constants";

const CompanyExpaneded = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  return (
    <Edit
      {...props}
      title={<TitleComponent source={CompanyFileds.Name} title="" />}
    >
      <SimpleForm
        toolbar={
          <ExpandedToolbar
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            Title="Company"
            TitleSource={CompanyFileds.Name}
            {...props}
          />
        }
      >
        <NumberInput
          source={CompanyFileds.RewardPoints}
          label="Reward Point"
          variant="outlined"
        />
        <NumberInput
          source={CompanyFileds.RewardRedeemPercent}
          label="Usable Rewards %"
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};

export default CompanyExpaneded;
