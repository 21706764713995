import React from "react";
import { Grid } from "@material-ui/core";
import {
  BooleanInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import {
  CategoryFields,
  DisplayColumnsList,
  ImageUploadField,
} from "../Utils/Constants";
import ImageResponsiveComponent from "../CommonComponents/ImageResponsiveComponent";
import { makeStyles } from "@material-ui/core/styles";
import ColorInput from "../CommonComponents/ColorInput";
import { CatergoryValidations } from "./CategoryValidations";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "200px",
    [theme.breakpoints.up(599)]: {
      width: "100%",
      maxWidth: "300px",
    },
  },
}));

//className is used in props to remove it from props as its causing issue with input to go full width
const CategoryCommonInputs = ({ isEdit = false, className, ...rest }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        {!isEdit ? null : (
          <Grid item xs={12}>
            <FormDataConsumer>
              {({ formData }) => {
                const imgPath =
                  formData[CategoryFields.image] &&
                  formData[CategoryFields.image].length > 0
                    ? formData[CategoryFields.image]
                    : null;

                return (
                  <ImageResponsiveComponent
                    className={classes.root}
                    src={imgPath}
                    srcset={formData[CategoryFields.srcSet]}
                    alt="No Image"
                    maxSize="100px"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextInput
            {...rest}
            source={CategoryFields.Name}
            validate={CatergoryValidations.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            {...rest}
            source={CategoryFields.DisplayName}
            validate={CatergoryValidations.diplayName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput
            {...rest}
            source={CategoryFields.SortOrder}
            validate={CatergoryValidations.sortOrder}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            {...rest}
            source={CategoryFields.DisplayColumn}
            choices={DisplayColumnsList}
            validate={CatergoryValidations.displayColumn}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ColorInput
            {...rest}
            source={CategoryFields.ChartColor}
            validate={CatergoryValidations.chartColor}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanInput
            label="Is Active"
            source={CategoryFields.Status}
            format={(v) => v === 1}
            parse={(v) => (v ? 1 : 0)}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            {...rest}
            label="Category Image"
            source={ImageUploadField}
            multiple={false}
            accept="image/jpeg"
            validate={isEdit ? [] : CatergoryValidations.image}
          >
            <ImageField {...rest} />
          </ImageInput>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryCommonInputs;
