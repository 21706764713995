import React from "react";
import {
  ImageList,
  ImageListItem,
  //GridListTile,
  ImageListItemBar as GridListTileBar,
  //GridListTileBar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { linkToRecord } from "react-admin";
import ImageResponsiveComponent from "../../CommonComponents/ImageResponsiveComponent";
const ImageGrid = ({ cols, ids = [], data, basePath, styles, titleSource }) => {
  return (
    <div className={styles.root}>
      <ImageList rowHeight={180} cols={cols} className={styles.gridList}>
        {ids.map((id) => {
          const curData = data[id];
          const imgPath = curData.image.length > 0 ? curData.image[0] : null;
          const title = curData[titleSource] ?? "";
          // console.log(
          //   "🚀 ~ file: GridList.js ~ line 78 ~ {ids.map ~ imgPath",
          //   imgPath
          // );
          return (
            <ImageListItem
              component={Link}
              key={id}
              to={linkToRecord(basePath, curData.id)}
            >
              imgPath ?
              <ImageResponsiveComponent
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={imgPath}
                srcSet={curData.srcSet}
                maxSize="100px"
              />
              : <div className={styles.placeholder} />
              <GridListTileBar className={styles.tileBar} title={title} />
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
};

export default ImageGrid;
