import { API_ENDPOINTS } from "../Utils/Constants";
import PincodeCreate from "./PincodeCreate";
import PincodeEdit from "./PincodeEdit";
import PincodesList from "./PincodesList";
import AddLocationIcon from "@material-ui/icons/AddLocation";
const Pincodes = {
  name: API_ENDPOINTS.Pincodes,
  list: PincodesList,
  create: PincodeCreate,
  edit: PincodeEdit,
  icon: AddLocationIcon,
  options: { label: "Pincodes" },
};

export default Pincodes;
