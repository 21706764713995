import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import {
  addFormData,
  arrayValidation,
  getArrayValidationErrors,
} from "./commonFunctions";
import httpClient from "./HttpClient";

const updateData = async (resource, params) => {
  try {
    let url = `${resource}/${params.id}`;
    const paramData = { ...params.data };
    // console.log(
    //   "🚀 ~ file: updateData.js ~ line 9 ~ updateData ~ ...params.data",
    //   params.data
    // );

    const { formData, arrayError } = addFormData(paramData);
    const {
      json: { status = null, data = {} },
    } = await httpClient(url, {
      method: "POST",
      body: formData,
    });
    //console.log("data", status, data);
    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({ data: data });
      } else if (status[ErrorCode] === "01") {
        const errors = {
          validations: null,
        };
        const keys = Object.keys(status[ErrorDescription]);
        //console.log("🚀 ~ file: index.js ~ line 173 ~ update: ~ keys", keys);
        if (keys.length > 0) {
          errors.validations = {};
          keys.forEach((key) => {
            if (!arrayValidation(key, arrayError, status)) {
              errors.validations[key] = status[ErrorDescription][key][0];
            }
          });
          getArrayValidationErrors(arrayError, errors);
          console.log(
            "🚀 ~ file: updateData.js ~ line 39 ~ updateData ~ arrayError,errors",
            arrayError,
            errors
          );
          return Promise.reject(errors);
        } else return Promise.reject(status[ErrorDescription]);
      } else {
        console.log("Error", status[ErrorDescription]);
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch ({ message }) {
    //console.log("DataProvider Error", err);
    return Promise.reject(message);
  }
};
export default updateData;
