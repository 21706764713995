import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import dataProvider from "./DataProvider/DataProvider";
import authProvider from "./AdminProvider/AuthProvider";
import Companies from "./Companies/Companies";
import DiscountCoupons from "./DiscountCoupons/DiscountCoupons";
import Dashboard from "./Dashboard/Dashboard";
import Orders from "./Orders/Orders";
import User from "./User/User";
import Pincodes from "./Pincodes/Pincodes";
import { API_ENDPOINTS } from "./Utils/Constants";
import Menu from "./Menu/Menu";
import { theme } from "./theme";
import TopSlides from "./HomePage/TopSlides/TopSlides";
import Categories from "./Categories/Categories";
import Collections from "./Collections/Collections";
import SwatchGroup from "./Swatches/Group/SwatchGroup";
import Swatches from "./Swatches/Swatches";
import Architects from "./Architects/Architects";
import Items from "./Items/Items";
import MyLayout from "./MyLayout";
import { Route } from "react-router-dom";
import { ProfileEdit } from "./Profile";
const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});
const App = () => {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      menu={Menu}
      title="BomabyLiving Admin Panel"
      i18nProvider={i18nProvider}
      theme={theme}
      layout={MyLayout}
      customRoutes={[
        <Route key="password" path="/profile" render={() => <ProfileEdit />} />,
      ]}
    >
      <Resource {...Orders} />
      <Resource {...User} />
      <Resource {...Companies} />
      <Resource {...DiscountCoupons} />
      <Resource {...Pincodes} />
      <Resource {...TopSlides} />
      <Resource {...Categories} />
      <Resource {...Collections} />
      <Resource {...Items} />
      <Resource {...SwatchGroup} />
      <Resource {...Swatches} />
      <Resource {...Architects} />
      <Resource name={API_ENDPOINTS.StateList} />
      <Resource name={API_ENDPOINTS.Products} />
      <Resource name={API_ENDPOINTS.ItemFills} />
      <Resource name={API_ENDPOINTS.ItemSizes} />
    </Admin>
  );
};

export default App;
