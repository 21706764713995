import { ErrorCode, ErrorDescription, UNKNOWN_ERROR } from "../Utils/Constants";
import httpClient from "./HttpClient";
const deleteData = async (resource, params) => {
  try {
    const url = `${resource}/${params.id}`;
    const {
      json: { status = null, data = {} },
    } = await httpClient(url, {
      method: "delete",
    });
    if (status && status[ErrorCode] && status[ErrorDescription]) {
      if (status[ErrorCode] === "00") {
        return Promise.resolve({ data: data });
      } else {
        return Promise.reject(status[ErrorDescription]);
      }
    } else {
      return Promise.reject(UNKNOWN_ERROR);
    }
  } catch ({ message }) {
    //console.log("DataProvider Error", err);
    return Promise.reject(message);
  }
};
export default deleteData;
