import React from "react";
import PropTypes from "prop-types";
import { SaveButton, Toolbar, useDataProvider, useNotify } from "react-admin";
import { useFormState } from "react-final-form";

const ExpandedToolbar = (props) => {
  // console.log(
  //   "🚀 ~ file: ExpandedToolbar.js ~ line 4 ~ ExpandedToolbar ~ props",
  //   props
  // );
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const formState = useFormState();
  const { isSaving, setIsSaving, Title, TitleSource, ...restProps } = props;
  const updateRecord = async () => {
    setIsSaving(true);
    try {
      await dataProvider.update(restProps.resource, {
        id: restProps.id,
        data: { ...formState.values },
      });
      if (TitleSource)
        notify(`${Title} ${restProps.record[TitleSource]} Updated`, "info");
      else notify(`${Title} Updated`, "info");
    } catch (error) {
      notify(error.message ? error.message : error, "error");
    }
    setIsSaving(false);
  };

  return (
    <Toolbar
      {...restProps}
      saving={isSaving}
      handleSubmitWithRedirect={updateRecord}
    >
      <SaveButton disabled={!formState.dirty} />
    </Toolbar>
  );
};
ExpandedToolbar.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  Title: PropTypes.string.isRequired,
  TitleSource: PropTypes.string,
};
export default ExpandedToolbar;
