export const onSuccess = ({ data }, notify, message) => {
  notify(message, { type: "success", multiLine: true });
};
export const onFailure = (error, notify) => {
  // console.log(
  //   "🚀 ~ file: ItemCreate.js ~ line 32 ~ onFailure ~ error",
  //   error
  // );
  if (!error?.validations)
    notify(`Error :${error.message ?? error}`, { type: "error" });
  else {
    const validation = { ...error.validations };
    //   console.log(
    //     "🚀 ~ file: ItemCreate.js ~ line 67 ~ onFailure ~ validation",
    //     validation
    //   );
    let imgErrMsg = null;
    if (validation) {
      const keys = Object.keys(validation).filter((e) =>
        new RegExp("^images.([\\d)]+)$", "g").test(e)
      );
      keys.forEach((e, i) => {
        if (imgErrMsg === null) {
          imgErrMsg = `Image [${i + 1}] : ${validation[e]}`;
        } else {
          imgErrMsg += `\nImage [${i + 1}] : ${validation[e]}`;
        }
      });
      // console.log(
      //   "🚀 ~ file: ItemCreate.js ~ line 82 ~ onFailure ~ keys",
      //   imgErrMsg
      // );
    }
    if (imgErrMsg) {
      notify(imgErrMsg, { type: "error", multiLine: true });
    } else {
      notify("Check validaion errors", { type: "error", multiLine: true });
    }
  }
};
