import React from "react";
import { List } from "react-admin";
import ImageGridList from "../../CommonComponents/ImageGridList";
import { TopSlidesFields } from "../../Utils/Constants/TopSlidesFields";
const TopSlidesList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false}>
      {/* <GridList /> */}
      <ImageGridList titleSource={TopSlidesFields.Headline} />
    </List>
  );
};

export default TopSlidesList;
