import React from "react";
import {
  ImageList as MuiGridList,
  ImageListItem as GridListTile,
} from "@material-ui/core";
const LoadingGrid = ({ cols, styles }) => {
  const dummyArray = (fn) => Array.from({ length: 10 }, (_, key) => fn(key));
  return (
    <div className={styles.root}>
      <MuiGridList rowHeight={180} cols={cols} className={styles.gridList}>
        {" "}
        {dummyArray((key) => (
          <GridListTile key={key}>
            <div className={styles.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

export default LoadingGrid;
