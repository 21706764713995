import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles({
  color: {
    backgroundColor: "red",
    color: "white",
  },
});
const AlertDialog = ({
  title,
  content,
  isOpen = false,
  okTitle,
  cancelTItle,
  showCancel = false,
  onOkClick,
  onCancelClick,
  onClose,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText > */}

        <p dangerouslySetInnerHTML={{ __html: content }}></p>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onOkClick(rest);
          }}
        >
          {okTitle}
        </Button>
        {showCancel && (
          <Button
            onClick={onCancelClick}
            classes={{ root: classes.color }}
            variant="contained"
          >
            {cancelTItle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AlertDialog;
