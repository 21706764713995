import React from "react";
import PropTypes from "prop-types";
const TabPanel = (props) => {
  const { children, value, index, ...rest } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...rest}>
      {value === index && children ? children : null}
    </div>
  );
};
TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
