import { API_ENDPOINTS, SkipFromMenu } from "../../Utils/Constants";
import SwatchGroupCreate from "./SwatchGroupCreate";
import SwatchGroupEdit from "./SwatchGroupEdit";
import SwatchGroupList from "./SwatchGroupList";

const SwatchGroup = {
  name: API_ENDPOINTS.SwatchesGroup,
  list: SwatchGroupList,
  create: SwatchGroupCreate,
  edit: SwatchGroupEdit,
  options: { [SkipFromMenu]: true },
};

export default SwatchGroup;
