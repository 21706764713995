export const TopSlidesFields = {
  Image: "image",
  SrcSet: "srcSet",
  Headline: "Headline",
  TagLine: "TagLine",
  LinkTitle: "LinkTitle",
  Type: "Type",
  TypeId: "TypeId",
  Url: "Url",
  ImgUpload: "ImgUpload",
};
