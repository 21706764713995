import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BooleanInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import {
  API_ENDPOINTS,
  ImageUploadField,
  SwatchFields,
} from "../Utils/Constants";
import ImageResponsiveComponent from "../CommonComponents/ImageResponsiveComponent";
import { SwatchValidation } from "./SwatchValidation";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "200px",
    [theme.breakpoints.up(599)]: {
      width: "100%",
      maxWidth: "300px",
    },
  },
}));

const SwatchCommonInput = ({ isEdit, className, ...props }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {isEdit && (
        <Grid item xs={12}>
          <FormDataConsumer>
            {({ formData }) => {
              const imgPath =
                formData[SwatchFields.image] &&
                formData[SwatchFields.image].length > 0
                  ? formData[SwatchFields.image]
                  : "";
              return (
                <ImageResponsiveComponent
                  className={classes.root}
                  src={imgPath}
                  srcset={formData[SwatchFields.srcSet]}
                  maxSize="100px"
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
      )}
      <Grid item xs={12}>
        <ReferenceInput
          {...props}
          allowEmpty={false}
          fullWidth
          label="Swatch Type"
          perPage={null}
          reference={API_ENDPOINTS.SwatchesGroup}
          sort={{ field: SwatchFields.SortOrder, order: "ASC" }}
          source={SwatchFields.FabricGroupID}
          validate={SwatchValidation.fabricType}
        >
          <SelectInput optionText={SwatchFields.Name} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          {...props}
          source={SwatchFields.Name}
          validate={SwatchValidation.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          {...props}
          source={SwatchFields.DisplayName}
          validate={SwatchValidation.displayName}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          {...props}
          source={SwatchFields.Description}
          validate={SwatchValidation.descrtiption}
          fullWidth
        />
      </Grid>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.SellingPrice}
            lable="Price"
            validate={SwatchValidation.sellingPrice}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.ShippingPrice}
            lable="Shipping"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.PackingPrice}
            lable="Packging"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item xs={6}>
          <NumberInput
            {...props}
            source={SwatchFields.DeliveryDays}
            lable="Delivery Days"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            {...props}
            source={SwatchFields.ReadyForDeliveryDay}
            lable="Delivery Days"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1}>
        <Grid item xs={8}>
          <NumberInput
            {...props}
            source={SwatchFields.HSNCode}
            label="HSN"
            validate={SwatchValidation.hsnCode}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanInput
            {...props}
            label="Inclusive Gst"
            source={SwatchFields.IsInclusive}
            format={(v) => v === 1}
            parse={(v) => (v ? 1 : 0)}
            // style={{ marginLeft: "10px" }}
          />
        </Grid>
      </Grid>
      <Grid item spacing={1} container xs={12} sm={6}>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.CGSTPercent}
            label="CGST"
            validate={SwatchValidation.gst}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.SGSTPercent}
            label="SGST"
            validate={SwatchValidation.gst}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            {...props}
            source={SwatchFields.IGSTPercent}
            label="IGST"
            validate={SwatchValidation.igst}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item xs={8}>
          <NumberInput
            {...props}
            source={SwatchFields.StockQty}
            label="Stock"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanInput
            {...props}
            source={SwatchFields.isStockCheck}
            label="Check Stock"
            format={(v) => v === 1}
            parse={(v) => (v ? 1 : 0)}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item xs={6}>
          <NumberInput
            {...props}
            source={SwatchFields.MinStock}
            label="Minimum Stock"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            {...props}
            source={SwatchFields.MaxOrderQty}
            label="Max Order Qty"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BooleanInput
          {...props}
          label="Is Active"
          source={SwatchFields.Status}
          format={(v) => v === 1}
          parse={(v) => (v ? 1 : 0)}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          {...props}
          label="Swatch Image"
          source={ImageUploadField}
          multiple={false}
          accept="image/jpeg"
          validate={isEdit ? [] : SwatchValidation.image}
        >
          <ImageField {...props} />
        </ImageInput>
      </Grid>
    </Grid>
  );
};

export default SwatchCommonInput;
